import React from 'react'
import { parseTextForBoldAndStrikethrough } from '../util/gen_util'

const FormattedText = ({ text }) => {

function parseTextForBoldAndStrikethrough(text) {
  const boldPattern = /\@\@(.*?)\@\@/g
  const strikethroughBoldPattern = /\[\[(.*?)\]\]/g

  const parts = []
  let lastIndex = 0

  text.replace(boldPattern, (match, p1, offset) => {
    if (offset > lastIndex) {
      parts.push({ text: text.slice(lastIndex, offset), type: 'normal' })
    }
    parts.push({ text: p1, type: 'bold' })
    lastIndex = offset + match.length
  })

  text.replace(strikethroughBoldPattern, (match, p1, offset) => {
    if (offset > lastIndex) {
      parts.push({ text: text.slice(lastIndex, offset), type: 'normal' })
    }
    parts.push({ text: p1, type: 'strikethroughBold' })
    lastIndex = offset + match.length
  })

  if (lastIndex < text.length) {
    parts.push({ text: text.slice(lastIndex), type: 'normal' })
  }

  return parts
}

  const formattedParts = parseTextForBoldAndStrikethrough(text)

  return (
    <div>
      {formattedParts.map((part, index) => {
        if (part.type === 'bold') {
          return <span className="font-bold underline" key={index}>{part.text}</span>
        }
        if (part.type === 'strikethroughBold') {
          return <span key={index} className="font-bold line-through">{part.text}</span>
        }
        return <span key={index}>{part.text}</span>
      })}
    </div>
  )
}

function parseText(text) {
  const boldPattern = /\*\*(.*?)\*\*/g
  const strikethroughBoldPattern = /\[(.*?)\]/g

  const parts = []
  let lastIndex = 0
  let match

  while ((match = boldPattern.exec(text)) !== null) {
    if (match.index > lastIndex) {
      parts.push({ text: text.slice(lastIndex, match.index), type: 'normal' })
    }
    parts.push({ text: match[1], type: 'bold' })
    lastIndex = match.index + match[0].length
  }

  text = text.slice(lastIndex)
  lastIndex = 0

  while ((match = strikethroughBoldPattern.exec(text)) !== null) {
    if (match.index > lastIndex) {
      parts.push({ text: text.slice(lastIndex, match.index), type: 'normal' })
    }
    parts.push({ text: match[1], type: 'strikethroughBold' })
    lastIndex = match.index + match[0].length
  }

  if (lastIndex < text.length) {
    parts.push({ text: text.slice(lastIndex), type: 'normal' })
  }

  return parts
}

export default FormattedText
