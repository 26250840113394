import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { fetchUsage } from '../api'
import UsageGraph from '../chat_room/UsageGraph'
import PricingModelDesc from '../info/PricingModelDesc'
import RedX from '../component/RedX'
import ThemeContext from '../context/ThemeContext'

const MODEL_COSTS = {
  gpt_4: 0.5,
  gpt_4_turbo: 1.0,
}


const UsageWindow = ({ username, closeUsageWindow }) => {
  const [usage, setUsage] = useState([])
  const [showGraph, setShowGraph] = useState(false)
  const [showFullScreenGraph, setShowFullScreenGraph] = useState(false)
  const [projections, setProjections] = useState(null)
  const [showPricingInfo, setShowPricingInfo] = useState(false)
  const { theme, setTheme } = useContext(ThemeContext)

  useEffect(() => {
    const fetchUserUsage = async () => {
      try {
        const fetchUsageResponse = await fetchUsage()
        if (fetchUsageResponse && fetchUsageResponse["usage"] === "unknown"){
          return
        }
        if (fetchUsageResponse && fetchUsageResponse["usage"]){
          const usage = fetchUsageResponse["usage"]
          setProjections(calculateProjections(usage, MODEL_COSTS))
          setUsage(usage)
        }
      } catch (error) {
        console.error("Failed to load settings:", error)
      }
    }
    fetchUserUsage()
  }, [])

  const calculateProjections = (usage, MODEL_COSTS) => {
    const today = moment()
    const daysInMonth = today.daysInMonth()
    const currentDay = today.date()

    const currentMonthUsage = usage[usage.length - 1]

    const dailyAverageGpt4 = parseInt(currentMonthUsage.gpt_4, 10) / currentDay
    const dailyAverageGpt4Turbo = parseInt(currentMonthUsage.gpt_4_turbo, 10) / currentDay

    const projectedGpt4 = Math.round(dailyAverageGpt4 * daysInMonth)
    const projectedGpt4Turbo = Math.round(dailyAverageGpt4Turbo * daysInMonth)

    const projectedCost = Math.round(((projectedGpt4 * MODEL_COSTS.gpt_4) + (projectedGpt4Turbo * MODEL_COSTS.gpt_4_turbo)) * 100) / 100

    return { projectedGpt4, projectedGpt4Turbo, projectedCost }
  }

  const formatUnits = (count) => `${count} ${count === "1" ? "unit" : "units"}`
  return (
    <div className={`relative z-10 shadow-md rounded-lg p-4 mt-6 overflow-y-auto max-h-[calc(100vh-80px)] ${theme === 'dark' ? 'text-white bg-gray-900 border-gray-200 border' : 'bg-white '}`}>
      <div className="flex-inline absolute right-2 hover:bg-red-200 rounded-full p-2 cursor-pointer" title="Close Usage Window" onClick={closeUsageWindow}><RedX /></div>
      <h2 className="text-lg font-semibold border-b pb-2 mb-4">Usage</h2>
      <div className="flex-grow mt-4 md:mt-0 md:ml-4 items-center text-center">
        {!showPricingInfo && (<button
            onClick={() => {setShowPricingInfo(true)}}
            className="inline-flex items-center px-4 mt-2 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Show Pricing Info
          </button>)}
        {showPricingInfo && (<div className="relative text-left pl-2 overflow-y-auto max-h-[calc(100vh-80px)] max-w-[350px] max-w-[calc(100vh-80px)]"><button
            onClick={() => {setShowPricingInfo(false)}}
            className="inline-flex items-center px-4 mt-2 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Hide Pricing Info
          </button><PricingModelDesc /></div>)}
        {!showGraph && (
         <div className="mx-auto mt-5 overflow-y-auto">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className={`px-5 py-3 border-b-2 text-left text-xs font-semibold uppercase tracking-wider ${theme === 'dark' ? 'border-gray-600 bg-gray-800 text-gray-300' : 'border-gray-200 bg-gray-100 text-gray-700 '}`}>
                  Time Period
                </th>
                <th className={`px-5 py-3 border-b-2 text-left text-xs font-semibold uppercase tracking-wider ${theme === 'dark' ? 'border-gray-600 bg-gray-800 text-gray-300' : 'border-gray-200 bg-gray-100 text-gray-700 '}`}>
                 GPT-4 Usage
                </th>
                <th className={`px-5 py-3 border-b-2 text-left text-xs font-semibold uppercase tracking-wider ${theme === 'dark' ? 'border-gray-600 bg-gray-800 text-gray-300' : 'border-gray-200 bg-gray-100 text-gray-700 '}`}>
                  GPT-4 Turbo Usage
                </th>
                <th className={`px-5 py-3 border-b-2 text-left text-xs font-semibold uppercase tracking-wider ${theme === 'dark' ? 'border-gray-600 bg-gray-800 text-gray-300' : 'border-gray-200 bg-gray-100 text-gray-700 '}`}>
                  Cost ($)
                </th>
              </tr>
            </thead>
            <tbody>
              {usage.map((item, index) => (
                <tr key={index}>
                  <td className={`px-5 py-3 border-b-2 text-sm ${theme === 'dark' ? 'border-gray-600 bg-gray-800 text-gray-300' : 'border-gray-200 bg-gray-100 text-gray-700 '}`}>
                    {item.time_period}
                  </td>
                  <td className={`px-5 py-3 border-b-2 text-sm ${theme === 'dark' ? 'border-gray-600 bg-gray-800 text-gray-300' : 'border-gray-200 bg-gray-100 text-gray-700 '}`}>
                    {formatUnits(item.gpt_4)}
                  </td>
                  <td className={`px-5 py-3 border-b-2 text-sm ${theme === 'dark' ? 'border-gray-600 bg-gray-800 text-gray-300' : 'border-gray-200 bg-gray-100 text-gray-700 '}`}>
                    {formatUnits(item.gpt_4_turbo)}
                  </td>
                  <td className={`px-5 py-3 border-b-2 text-sm ${theme === 'dark' ? 'border-gray-600 bg-gray-800 text-gray-300' : 'border-gray-200 bg-gray-100 text-gray-700 '}`}>
                    ${parseFloat(item.gpt_4) * MODEL_COSTS.gpt_4 + parseFloat(item.gpt_4_turbo) * MODEL_COSTS.gpt_4_turbo}
                  </td>
                </tr>
              ))}
              {projections != null && (
                <tr>
                  <td className={`px-5 border-b hover:font-bold text-sm ${theme === 'dark' ? 'border-gray-200 bg-gray-700 text-gray-400 hover:text-white' : 'border-gray-200 bg-white text-gray-400 hover:text-black'}`}>
                    Cur. Month Projection
                  </td>
                  <td className={`px-5 border-b hover:font-bold text-sm ${theme === 'dark' ? 'border-gray-200 bg-gray-700 text-gray-400 hover:text-white' : 'border-gray-200 bg-white text-gray-400 hover:text-black'}`}>
                    {formatUnits(projections.projectedGpt4)}
                  </td>
                 <td className={`px-5 border-b hover:font-bold text-sm ${theme === 'dark' ? 'border-gray-200 bg-gray-700 text-gray-400 hover:text-white' : 'border-gray-200 bg-white text-gray-400 hover:text-black'}`}>
                    {formatUnits(projections.projectedGpt4Turbo)}
                  </td>
                <td className={`px-5 border-b hover:font-bold text-sm ${theme === 'dark' ? 'border-gray-200 bg-gray-700 text-gray-400 hover:text-white' : 'border-gray-200 bg-white text-gray-400 hover:text-black'}`}>
                   ${projections.projectedCost}
                </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        )}
        {showGraph && !showFullScreenGraph && (
        <div className="mt-2 flex justify-center text-center max-w-[calc(100vw-258px)]">
          <div className="flex-col min-w-[500px] min-h-[250px] max-h-[calc(100vh-366px)]">
            <div className={`text-lg font-bold ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700 '}`}>Model Usage and Cost Over Time (with Projections)</div>
            <UsageGraph usage={usage} projections={projections} MODEL_COSTS={MODEL_COSTS} isFullScreen={false} />
            <button
              onClick={() => {setShowFullScreenGraph(true)}}
              className="inline-flex items-center px-4 mt-2 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Show Full Screen
            </button>
          </div>
        </div>)}
        {showGraph && showFullScreenGraph && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative items-center justify-center h-[calc(80vh)] w-[calc(80vw)] min-h-screen p-10">
              <div className={`w-full h-auto p-6 rounded-lg shadow ${theme === 'dark' ? 'bg-gray-900 rounded text-white bg-gray-900 border-gray-200 border':''}`}>
                <UsageGraph usage={usage} projections={projections} MODEL_COSTS={MODEL_COSTS} isFullScreen={true} />
                <button
                  onClick={() => {setShowFullScreenGraph(false)}}
                  className="inline-flex items-center px-4 mt-2 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Close Full Screen
                </button>
              </div>
            </div>
          </div>
          )}
        <div className="mt-6 w-full text-center">
          {!showGraph && (<button
            onClick={() => {setShowGraph(true)}}
            className="inline-flex items-center px-4 mt-2 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Show Graph
          </button>)}
          {showGraph && (<button
            onClick={() => {setShowGraph(false)}}
            className="inline-flex items-center px-4 mt-2 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Hide Graph
          </button>)}
        </div>
      </div>
    </div>
  )
}

export default UsageWindow
