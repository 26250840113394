import React, { useRef, useEffect, useState } from 'react'
import { addDrawingComponent, removeDrawingComponent } from '../../api'
import Spinner from '../../component/Spinner'
import RedX from '../../component/RedX'
import { FaSync } from 'react-icons/fa'

function DrawingSummary({ docNumber, docType, summaries, handleScrollToDrawing, genSummaries, showExtractDrawingInfoButton, extractDrawingInfoStatus, addComponent, componentLoading, setDrawingInfo }) {
  return (
    <div className="flex flex-col space-y-4 p-4">
      {summaries && summaries.length > 0 && summaries.map((summary, index) => (
        <DetailsComponent key={index} docNumber={docNumber} docType={docType}
        summary={summary} handleScrollToDrawing={handleScrollToDrawing}
        addComponent={addComponent} componentLoading={componentLoading} setDrawingInfo={setDrawingInfo} />
      ))}
      {(!summaries || summaries.length == 0) && showExtractDrawingInfoButton && (
        <div className="p-2 m-2">
         <div className="p-1 w-fit cursor-pointer rounded border-1 border-black hover:bg-primary hover:text-white" onClick={genSummaries}>
          Extract Drawing Info
          </div>
        </div>
      )}
      {extractDrawingInfoStatus && extractDrawingInfoStatus !== "" && (
        <div className="p-2 m-2">
          {extractDrawingInfoStatus}
        </div>
      )}
      {extractDrawingInfoStatus && extractDrawingInfoStatus.toLowerCase().startsWith("processing") && (<Spinner />)}
    </div>
  )
}

const DetailsComponent = ({ summary, handleScrollToDrawing, docNumber, docType, addComponent, componentLoading, setDrawingInfo }) => {
  const componentsRef = useRef(null)
  const [componentsHeight, setComponentsHeight] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [numeral, setNumeral] = useState("")

  useEffect(() => {
    if (componentsRef.current) {
      setComponentsHeight(componentsRef.current.clientHeight)
    }
  }, [componentsRef.current])

  const handleRemoveDrawingComponent = (id, name, figure, sheet) => {
    if (!id) {
      id = ""
    }
    if (!name) {
      name = ""
    }
    removeDrawingComponent(docNumber, docType, id, name, figure, sheet).then((removeDrawingComponentResponse) => {
      if (removeDrawingComponentResponse && removeDrawingComponentResponse.drawing_summaries) {
        setDrawingInfo(removeDrawingComponentResponse.drawing_summaries)
      }
    })
  }

  const handleRefreshComponent = (id, name, figure, sheet) => {
    if (!id) {
      id = ""
    }
    if (!name) {
      name = ""
    }
    removeDrawingComponent(docNumber, docType, id, name, figure, sheet).then((removeDrawingComponentResponse) => {
      if (removeDrawingComponentResponse && removeDrawingComponentResponse.drawing_summaries) {
        setDrawingInfo(removeDrawingComponentResponse.drawing_summaries)
        setNumeral(id)
        setTimeout(() => {addComponent(id, figure, sheet); setNumeral("")}, 700)
      }
    })
  }

  return (
    <details className="group border p-3 rounded-lg shadow-md">
      <summary className="text-lg font-semibold cursor-pointer">
        Figure {summary.number} - Sheet {summary.sheet}
      </summary>
      <div className="mt-2 space-y-2 text-gray-700">
        <p>{summary.summary}</p>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div ref={componentsRef}>
              <h4 className="font-semibold">Components:</h4>
              <ul className="list-disc pl-5">
                {summary.components.map(component => (
                  <li key={component.id} className="flex items-center space-x-2">
                    <span>{component.name}</span>
                    <span className="italic font-bold">{component.id}</span>
                    <span title="refresh component name" onClick={() => {setNumeral(""); handleRefreshComponent(component.id, component.name, summary.number, summary.sheet)}} className="cursor-pointer hover:bg-blue-100 p-1 rounded-full transition duration-150 ease-in-out">
                      <FaSync />
                    </span>
                    <span title="delete from component list" onClick={() => {setNumeral(""); handleRemoveDrawingComponent(component.id, component.name, summary.number, summary.sheet)}} className="cursor-pointer hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out">
                      <RedX />
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="p-2 m-2">
              <input
                type="text"
                value={numeral}
                onChange={(e) => setNumeral(e.target.value)}
                className="p-1 border rounded"
                placeholder="Enter numeral"
              />
              <button
                onClick={() => {addComponent(numeral, summary.number, summary.sheet); setNumeral("")}}
                className="p-1 ml-2 border rounded bg-blue-500 text-white"
                disabled={isLoading}
              >
                {componentLoading ? <Spinner /> : 'Add Component'}
              </button>
            </div>
          </div>
          {summary.url && (
            <div
              onClick={() => {handleScrollToDrawing(summary.sheet)}}
              title="Click to jump to this drawing sheet"
              className={`flex justify-center items-center cursor-pointer h-[${componentsHeight}px]`}>
              <img src={summary.url} alt="Diagram" className="w-auto h-full" />
            </div>
          )}
        </div>
      </div>
    </details>
  )
}

export default DrawingSummary
