import React from 'react'
import PropTypes from 'prop-types'

const CitesList = ({ cites, handleParagraphClick }) => {
  return (
    <div className="p-4 bg-white shadow rounded-lg">
      <h2 className="text-xl font-bold text-gray-800 mb-5">Citations</h2>
      <ul className="divide-y divide-gray-300">
        {cites.map((cite, index) => (
          <li key={index} className="py-4">
            <div className="grid grid-cols-3 gap-2">
              <span className="text-sm font-semibold text-gray-600">Type:</span>
              <span className="col-span-2 text-sm text-gray-800">{cite.type}</span>
            </div>
            <div className="grid grid-cols-3 gap-2 mt-2">
              <span className="text-sm font-semibold text-gray-600">Citation:</span>
              <span className="col-span-2 text-sm text-gray-800">{cite.cite_text}</span>
            </div>
            {cite.paragraph && (
              <div className="grid grid-cols-3 gap-2 mt-2">
                <span className="text-sm font-semibold text-gray-600">Paragraph:</span>
                <span className="col-span-2 text-sm text-gray-800" onClick={() => {handleParagraphClick(cite.paragraph)}}>{cite.paragraph}</span>
              </div>
            )}
            {cite.purpose && (
              <div className="grid grid-cols-3 gap-2 mt-2">
                <span className="text-sm font-semibold text-gray-600">Purpose:</span>
                <span className="col-span-2 text-sm text-gray-800">{cite.purpose}</span>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}


CitesList.propTypes = {
  cites: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      cite_text: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default CitesList
