import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import RedX from '../component/RedX'

const PrivacyPolicy = ({ theme, closeFn }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className={`relative p-6 max-w-md mx-auto rounded-xl shadow-md overflow-y-auto ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <button onClick={closeFn} className="absolute top-2 right-2">
        <RedX />
      </button>
      <h2 className="text-xl font-bold mb-4">Privacy Policy</h2>
      <p className="mb-4">Effective: January 31, 2024</p>
      <p className="mb-4">
        We at ForGen AI, LLC ("Patents.chat", "we", "our" or "us") respect your privacy and are strongly committed to keeping secure any information we obtain from you or about you. This Privacy Policy describes our practices with respect to Personal Information we collect from or about you when you use our website, applications, and services (collectively, “Services”).
      </p>
      <h3 className="text-lg font-semibold mb-2 flex items-center cursor-pointer" onClick={toggleExpand}>
        Personal Information We Collect
        <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} className="ml-2" />
      </h3>
      {isExpanded && (
        <div className="mb-4">
          <p className="mb-2"><strong>Personal information you provide:</strong></p>
          <ul className="list-disc list-inside mb-2">
            <li>Account Information: When you create an account with us, we will collect information associated with your account, including your name, contact information, and account credentials.</li>
            <li>User Content: When you use our Services, we collect Personal Information that is included in the input, file uploads, or feedback that you provide to our Services.</li>
            <li>Communication Information: If you communicate with us, we collect your name, contact information, and the contents of any messages you send.</li>
          </ul>
          <p className="mb-2"><strong>Personal information we receive automatically from your use of the Services:</strong></p>
          <ul className="list-disc list-inside mb-4">
            <li>Log Data: Information that your browser or device automatically sends when you use our Services.</li>
            <li>Usage Data: Information about your use of the Services, such as the types of models used.</li>
            <li>Device Information: Information about the device you use to access our Services, including device identifiers and browser type.</li>
            <li>Cookies: We use cookies to operate and administer our Services, and improve your experience, but we never use any tracking or other third party cookies.</li>
          </ul>
        </div>
      )}
      <h3 className="text-lg font-semibold mb-2">How We Use Personal Information</h3>
      <ul className="list-disc list-inside mb-4">
        <li>To provide, administer, maintain and/or analyze the Services.</li>
        <li>To communicate with you when requested by you, including to send you information about our Services and events.</li>
        <li>To prevent fraud, criminal activity, or misuse of our Services.</li>
        <li>To comply with legal obligations and legal processes.</li>
      </ul>
      <h3 className="text-lg font-semibold mb-2">Data Storage and Security</h3>
      <p className="mb-4">
        Your data is stored in a secure database and is used solely for providing you with services. It is not sold or provided to any other parties. Data will only be accessed manually via a personal request.
      </p>
      <h3 className="text-lg font-semibold mb-2">Use of GPT Models</h3>
      <p className="mb-4">
        We use GPT models through OpenAI's Enterprise framework to provide advanced responses. You can review OpenAI's privacy policy <a href="https://openai.com/policies/privacy-policy/" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">here</a>.
      </p>
      <h3 className="text-lg font-semibold mb-2">Contact Information</h3>
      <p className="mb-4">
        ForGen AI, LLC<br />
        Birmingham, Michigan 48009<br />
        Email: support@forgen.ai
      </p>
      <button
        onClick={closeFn}
        className={`mt-4 py-2 px-4 rounded ${theme === 'dark' ? 'bg-primary-light-3 text-gray-900' : 'bg-primary text-white'}`}
      >
        Close
      </button>
    </div>
  )
}

export default PrivacyPolicy
