import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import ScrollableViewer from '../../chat_room/doc_viewer/ScrollableViewer'
import DrawingSummary from '../../chat_room/doc_viewer/DrawingSummary'

const DrawingsSection = ({
  theme, showDrawings, setShowDrawings, drawingUrls, showDrawingInfo, setShowDrawingInfo, drawingInfo,
  setDrawingInfo, handleScrollToDrawing, genSummaries, showExtractDrawingInfoButton, extractDrawingInfoStatus,
  addComponent, componentLoading, docNumber, type
}) => {
  return (
    <div className="mr-4">
      {showDrawings && (
        <>
          { /* <button onClick={() => setShowDrawings(false)} className="flex items-center bg-transparent text-gray-400 px-4 py-1 text-xs rounded inset-0 hover:opacity-70 hover:bg-blue-700">
            <FontAwesomeIcon icon={faEyeSlash} className="mr-1" />
            Hide Drawings
          </button>*/}
          {showDrawingInfo && (
            <>
              <button onClick={() => setShowDrawingInfo(false)} className="flex items-center bg-transparent text-gray-400 px-4 py-1 text-xs rounded inset-0 hover:opacity-70 hover:bg-blue-700">
                <FontAwesomeIcon icon={faEyeSlash} className="mr-2" />
                Hide Drawing Info
              </button>
              <DrawingSummary
                docNumber={docNumber}
                docType={type}
                summaries={drawingInfo}
                handleScrollToDrawing={handleScrollToDrawing}
                genSummaries={genSummaries}
                showExtractDrawingInfoButton={showExtractDrawingInfoButton}
                extractDrawingInfoStatus={extractDrawingInfoStatus}
                addComponent={addComponent}
                componentLoading={componentLoading}
                setDrawingInfo={setDrawingInfo}
              />
            </>
          )}
          {!showDrawingInfo && (
            <button onClick={() => setShowDrawingInfo(true)} className="flex items-center bg-transparent text-gray-400 px-4 py-1 text-xs rounded inset-0 hover:opacity-70 hover:bg-blue-700">
              <FontAwesomeIcon icon={faEye} className="mr-2" />
              Show Drawing Info
            </button>
          )}
          <ScrollableViewer drawingUrls={drawingUrls} />
        </>
      )}
    </div>
  )
}

export default DrawingsSection
