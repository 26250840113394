import React, { useState } from 'react'
import FormattedText from '../component/FormattedText'
import Spinner from '../component/Spinner'
import { getJobStatus, generateResponseForIssue, getResponseForIssue } from '../api'

const Issue = ({ app_no, issue, issue_id }) => {
  const [customInstruction, setCustomInstruction] = useState("")
  const [issueResponseText, setIssueResponseText] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [showGenButton, setShowGenButton] = useState(true)
  const {
    issue_type,
    issue_claims,
    issue_summary,
    issue_documents,
    issue_citations
  } = issue

  const handleGenerateResponse = () => {
    setShowGenButton(false)
    setIsLoading(true)
    generateResponseForIssue(app_no, issue_id, customInstruction).then((issueResponseData) => {
      var queryId = issueResponseData["job_id"]
      const queryInterval = setInterval(() => {
          getJobStatus(queryId).then((jobStatusResponse) => {
              if (jobStatusResponse["status"]) {
                if (jobStatusResponse["status"] == "complete"){
                  setIsLoading(false)
                  clearInterval(queryInterval)
                  getResponseForIssue(app_no, issue_id).then((issueResponse) => {
                    setIssueResponseText(issueResponse)
                  })
                }
              }
          })
      }, 3000)
    })
  }

  return (
    <div className="border p-4 rounded-lg shadow mb-4">
      {issue_type && <h3 className="text-xl font-semibold">Issue Type: {issue_type}</h3>}
      {issue_claims && <p><strong>Claims:</strong> {issue_claims}</p>}
      {issue_summary && <p><strong>Summary:</strong> {issue_summary}</p>}
      {showGenButton && (<><button
          onClick={handleGenerateResponse}
          className="bg-primary bg-blue-500 text-white p-2 rounded hover:bg-blue-100 hover:text-blue-700"
        >
          Generate Response
        </button>
         <input
          type="text"
          value={customInstruction}
          onChange={(e) => setCustomInstruction(e.target.value)}
          className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 text-gray-900 bg-gray-50 focus:ring-primary focus:border-primary"
          placeholder="Enter custom instructions for generating response"
                /></>)}
      {isLoading && <>Generating Response <Spinner/></>}
      {issueResponseText && (<div className="rounded bg-gray-200"><strong>Response: </strong><FormattedText text={issueResponseText} /></div>)}
      {issue_documents && issue_documents.length > 0 && (
        <div>
          <strong>Documents:</strong>
          <ul>
            {issue_documents.map((doc, index) => (
              <li key={index}>{doc}</li>
            ))}
          </ul>
        </div>
      )}
      {issue_citations && issue_citations.length > 0 && (
        <div>
          <strong>Citations:</strong>
          {issue_citations.map((citation, index) => (
            <div key={index} className="mt-2">
              <p><strong>Document ID:</strong> {citation.document_id}</p>
              <ul>
                {citation.citations.map((cite, idx) => (
                  <li key={idx}>{cite}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Issue
