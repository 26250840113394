import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import { capitalizeFirstChar } from '../../util/gen_util'

const DocumentHeader = ({ theme, type, docName, handleRefreshDocument, showRefreshButton, isDrawings }) => (
  <div className={`p-2 ml-2 border-b text-lg overflow-y-auto font-semibold pl-2 ${(!isDrawings && (type === "patent" || type === "publication")) ? "ml-[78px]" : ""} max-w-[calc(100vw-78px)] ${theme === 'dark' ? 'text-gray-200 border-gray-800 bg-gray-900' : 'text-gray-900 border-primary border-gray-200 bg-white'}`}>
    <span className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>{capitalizeFirstChar(type)}</span> {docName}
    {showRefreshButton && (
      <button onClick={handleRefreshDocument} className={`flex items-center ${theme === 'dark' ? 'bg-blue-900 text-blue-200 hover:bg-blue-800' : 'bg-blue-100 text-blue-700 hover:bg-blue-200'} font-medium text-xs rounded-lg px-2 py-1 transition-colors duration-300 ease-in-out`}>
        <FontAwesomeIcon icon={faSync} className="mr-1" />
        <span className='hover:underline'>Refresh</span>
      </button>
    )}
  </div>
)

export default DocumentHeader
