import React, { useState } from 'react'
import { userLogin, forgotPassword, confirmNewPassword, sendSecretCode, delete_the_storage_but_not_auth_ids } from '../api'
import Spinner from '../component/Spinner'

function LoginForm({ onLogin, goToPaymentSite }) {
  const [loginFailed, setLoginFailed] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [loginSuspended, setLoginSuspended] = useState(false)
  const [loginData, setLoginData] = useState({
    username: '',
    password: ''
  })
  const [showForgotPasswordScreen, setShowForgotPasswordScreen] = useState(false)
  const [showVerifyNewPasswordScreen, setShowVerifyNewPasswordScreen] = useState(false)
  const [statusMessage, setStatusMessage] = useState("")
  const [verificationCode, setVerificationCode] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [secretCode, setSecretCode] = useState("")
  const [showSecretCodeScreen, setShowSecretCodeScreen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [changePasswordIsLoading, setChangePasswordIsLoading] = useState(false)

  const handleLoginChange = (e) => {
    const { name, value } = e.target
    setLoginData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleVerificationCodeChange = (e) => {
    const { name, value } = e.target
    setVerificationCode(value)
  }

  const handleNewPasswordChange = (e) => {
    const { name, value } = e.target
    setNewPassword(value)
  }

  const handleSecretCodeChange = (e) => {
    const { name, value } = e.target
    setSecretCode(value)
  }

  const handleLoginSubmit = (e) => {
    e.preventDefault()
    if ((loginData.username == null || loginData.username == "") || (loginData.password == null || loginData.password == "")){
      setStatusMessage("Please enter username and password.")
      return
    }
    setIsLoading(true)
    userLogin(loginData).then((results) => {
      if (results["refresh_token"] === "none") {
         setLoginSuspended(true)
         return
      }
      const loggedInUser = {
        username: results["username"],
        email: results["email"],
        token: results,
      }
      console.log(results)
      delete_the_storage_but_not_auth_ids()
      onLogin(results, results["username"])
    })
    .catch(error => {
        if (error.response && error.response.status === 403) {
          setLoginFailed(true)
          setLoginError(false)
        } else {
          setLoginError(true)
          setLoginFailed(false)
        }
    })
  }

  const handleSendSecretCode = () => {
    if (loginData.username == null || loginData.username == ""){
      setStatusMessage("Please enter username.")
      return
    }
    sendSecretCode(loginData.username, secretCode).then((secretCodeResponse) => {
      setLoginFailed(false)
      setLoginError(false)
      setLoginSuspended(false)
      setStatusMessage("Success! Please try to login.")
    }).catch(error => {
        if (error.response && error.response.status === 403) {
          setStatusMessage("Username and secret code combo not found.")
        } else {
          setStatusMessage("Error. Please contact support@forgen.ai for assistance.")
        }
    })
  }

  const initiateForgotPassword = () => {
    if (loginData.username == null || loginData.username == ""){
      setStatusMessage("Please enter username.")
      return
    }
    setShowForgotPasswordScreen(true)
    forgotPassword(loginData.username).then((initiatePasswordResponse) => {
      setStatusMessage("A verification code has been sent to your email.")
      setShowVerifyNewPasswordScreen(true)
    })
    .catch((e) => {

    })
  }

  const changeToNewPassword = () => {
    let statusMsg = ""
    if (loginData.username == null || loginData.username == ""){
      statusMsg += "Please enter username. "
    }
    if (verificationCode == null || verificationCode == ""){
      statusMsg += "Please enter the verification code. "
    }
    if (newPassword == null || newPassword == ""){
      statusMsg += "Please enter a new password. "
    } else if (!isValidPassword(newPassword.trim())){
      statusMsg += "Please enter a valid password. Must be 8 chars, 1 upper case, 1 lower case, 1 number, 1 special char. "
    }
    if (statusMsg != ""){
      setStatusMessage(statusMsg)
      return
    }
    setChangePasswordIsLoading(true)
    confirmNewPassword(loginData.username, verificationCode, newPassword.trim()).then((initiatePasswordResponse) => {
      setStatusMessage("Password changed. Please try logging in.")
      setShowVerifyNewPasswordScreen(false)
      setShowForgotPasswordScreen(false)
      setChangePasswordIsLoading(false)
    })
    .catch((e) => {
      setChangePasswordIsLoading(false)
    })
  }
  function isValidPassword(password) {
      const regex = /^\S+.*\S+$/
      const minLength = 8
      const hasUpperCase = /[A-Z]/
      const hasLowerCase = /[a-z]/
      const hasNumber = /[0-9]/
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/
      if (!regex.test(password)) {
          console.log("Password must not start or end with whitespace.")
          return false
      }
      if (password.length < minLength) {
          console.log(`Password must be at least ${minLength} characters long.`)
          return false
      }
      if (!hasUpperCase.test(password)) {
          console.log("Password must contain at least one uppercase letter.")
          return false
      }
      if (!hasLowerCase.test(password)) {
          console.log("Password must contain at least one lowercase letter.")
          return false
      }
      if (!hasNumber.test(password)) {
          console.log("Password must contain at least one number.")
          return false
      }
      if (!hasSpecialChar.test(password)) {
          console.log("Password must contain at least one special character.")
          return false
      }
      return true
  }


  return (
    <div className='flex items-center justify-center w-64'>
      <div className='w-64 p-4 my-4 border rounded shadow bg-secondary'>
        <h2 className='text-2xl text-center font-bold text-white mb-2'>Login</h2>
        <form onSubmit={handleLoginSubmit} className='flex flex-col space-y-2'>
          <input
            type='text'
            name='username'
            placeholder='Username'
            value={loginData.username}
            onChange={handleLoginChange}
            className='border rounded p-2'
          />
          {!showForgotPasswordScreen && (<input
            type='password'
            name='password'
            placeholder='Password'
            value={loginData.password}
            onChange={handleLoginChange}
            className='border rounded p-2'
          />)
          }
          {showForgotPasswordScreen && (<>
            <input
              type='text'
              name='verification code'
              placeholder='Verification Code'
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              className='border rounded p-2'
            />
            <input
              type='password'
              name='new password'
              placeholder='New Password'
              value={newPassword}
              onChange={handleNewPasswordChange}
              className='border rounded p-2'
            />
          </>)}
          {!showForgotPasswordScreen && (<div className="cursor-pointer text-center text-red-500 text-xs bg-red-100 p-2 rounded mb-2 hover:bg-red-500 hover:text-white" onClick={initiateForgotPassword}>
            forgot password?
          </div>)}
          {statusMessage!="" && (
            <div className="text-red-500 text-sm bg-red-100 p-2 rounded mb-2">
              {statusMessage}
            </div>
          )}
          {loginFailed && (
            <div className="text-red-500 text-sm bg-red-100 p-2 rounded mb-2">
              Username or password incorrect!
            </div>
          )}
          {loginError && (
            <div className="text-red-500 text-sm bg-red-100 p-2 rounded mb-2">
              Error. If errors persist, contact info@forgen.ai
            </div>
          )}
          {loginSuspended && (
            <div>
              <div className="text-red-500 text-sm bg-red-100 p-2 rounded mb-2">
                Disabled due to non-payment. If you believe this is a mistake, contact info@forgen.ai
              </div>
              <div>
                Please complete payment to get started.
                  <div className="flex items-center justify-center">
                    <button
                      onClick={() => {goToPaymentSite(loginData.username)}}
                      className="bg-blue-500 text-white p-2 rounded"
                    >
                      Pay with Stripe
                    </button>
                  </div>
                  {!showSecretCodeScreen && (<div className="flex items-center justify-center mt-2">
                    <button
                      onClick={() => {setShowSecretCodeScreen(true)}}
                      className="bg-gray-500 text-white p-2 rounded"
                    >
                      Enter Secret Code
                    </button>
                  </div>)}
              </div>
            </div>
          )}
          {showSecretCodeScreen && (<>
            <input
              type='text'
              name='secretCode'
              placeholder='enter secret code'
              value={secretCode}
              onChange={handleSecretCodeChange}
              className='border rounded p-2'
            />
            <button
              onClick={handleSendSecretCode}
              className="bg-blue-500 text-white p-2 rounded"
            >
              Submit Secret Code
            </button>
          </>
          )}
          {showForgotPasswordScreen && (<button type='submit' onClick={changeToNewPassword} className='border bg-tertiary hover:border-tertiary hover:bg-white font-bold py-2 px-4 rounded'>
            {!changePasswordIsLoading && "Login"}
            {changePasswordIsLoading && (<Spinner />)}
          </button>)}
          {!showForgotPasswordScreen && (<button type='submit' className='border bg-tertiary hover:border-tertiary hover:bg-white font-bold py-2 px-4 rounded'>
            {!isLoading && "Login"}
            {isLoading && (<Spinner />)}
          </button>)}
        </form>
      </div>
    </div>
  )
}

export default LoginForm
