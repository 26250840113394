import React, { useState, useEffect } from 'react'
import HighlightTerm from '../component/HighlightTerm'
import Spinner from '../component/Spinner'
import RedX from '../component/RedX'

const TermListAndDefinitions = ({ data, addTheTerms, showLoader, deleteTerm, downloadTerms }) => {
  const [termsToAdd, setTermsToAdd] = useState("")
  const [showAddTerms, setShowAddTerms] = useState(false)
  const [showAddTermsButton, setShowAddTermsButton] = useState(true)
  const [showDefinitions, setShowDefinitions] = useState(false)
  const [showCustOnly, setShowCustOnly] = useState(false)
  const [visibilityStates, setVisibilityStates] = useState(Array(data.length).fill(null))
  useEffect(() => {
    setVisibilityStates(Array(data.length).fill(null));
  }, [data.length])
  const toggleDefinitionVisibility = (index) => {
    setVisibilityStates(prevStates =>
      prevStates.map((state, i) => i === index ? !prevStates[index] : state)
    )
  }
  const toggleAllDefinitions = () => {
    setShowDefinitions(!showDefinitions)
    if (!showDefinitions) {
      setVisibilityStates(Array(data.length).fill(null))
    }
  }
  const isDefinitionVisible = (index) => {
    return visibilityStates[index] !== null ? visibilityStates[index] : showDefinitions
  }
  const handleShowAddTerms = () => {
    setShowAddTerms(true)
    setShowAddTermsButton(false)
  }
  const addTerms = () => {
    addTheTerms(termsToAdd)
  }

  function highlightTerm(text, term) {
      const regex = new RegExp(`\\b${term}\\b`, 'gi')
      const highlightedText = text.replace(regex, `<strong>${term}</strong>`)
      return highlightedText
  }

  function formatNums(claimNums, paragraphNums) {
    const paragraphStr = paragraphNums.length > 0 ? `Paragraphs: ${paragraphNums.join(', ')}` : ''
    const claimStr = claimNums.length > 0 ? `Claims: ${claimNums.join(', ')}` : ''
    return `${paragraphStr}${paragraphStr && claimStr ? '.  ' : ''}${claimStr}`
  }

  return (
    <div className="p-1">
      <button onClick={downloadTerms} className="bg-blue-100 text-blue-700 font-medium text-xs items-center rounded-lg px-1 py-1 transition-colors duration-300 ease-in-out hover:bg-blue-200">
        <span className='hover:underline text-middle'>download terms (*.xlsx)</span>
      </button>
      {showCustOnly && (
        <button onClick={() => setShowCustOnly(false)} className="bg-blue-100 text-blue-700 font-medium text-xs items-center rounded-lg px-1 py-1 transition-colors duration-300 ease-in-out hover:bg-blue-200">
          <span className='hover:underline text-middle'>show all terms</span>
        </button>
      )}
      {!showCustOnly && (
        <button onClick={() => setShowCustOnly(true)} className="bg-blue-100 text-blue-700 font-medium text-xs items-center rounded-lg px-1 py-1 transition-colors duration-300 ease-in-out hover:bg-blue-200">
          <span className='hover:underline text-middle'>show only custom terms</span>
        </button>
      )}
      {showAddTermsButton && !showLoader && (
        <button onClick={handleShowAddTerms} className="bg-blue-100 text-blue-700 font-medium text-xs items-center rounded-lg px-1 py-1 transition-colors duration-300 ease-in-out hover:bg-blue-200">
          <span className='hover:underline text-middle'>add term(s)</span>
        </button>
      )}
      {showLoader && (
        <div className="text-gray-400 p-1 bg-gray-200 rounded">Loading term info ... <Spinner /></div>
      )}
      {showAddTerms && !showLoader && (
        <div className="bg-gray-300 rounded p-1">
          <input
                  id='termsToAdd'
                  name='termsToAdd'
                  type='text'
                  className='appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                  placeholder='Term(s), separated by comma'
                  value={termsToAdd}
                  onChange={e => setTermsToAdd(e.target.value)}
                />
          <button onClick={addTerms} className="bg-blue-100 text-blue-700 font-medium text-xs items-center rounded-lg px-1 py-1 transition-colors duration-300 ease-in-out hover:bg-blue-200">
            <span className='hover:underline text-middle'>Submit</span>
          </button>
          <button onClick={() => {setShowAddTerms(false); setShowAddTermsButton(true)}} className="bg-red-100 text-red-700 font-medium text-xs items-center rounded-lg px-1 py-1 transition-colors duration-300 ease-in-out hover:bg-red-200">
            <span className='hover:underline text-middle'>Close</span>
          </button>
        </div>
      )}
      {!showDefinitions && (
        <button onClick={toggleAllDefinitions} className="bg-blue-100 text-blue-700 font-medium text-xs items-center rounded-lg px-1 py-1 transition-colors duration-300 ease-in-out hover:bg-blue-200">
          <span className='hover:underline text-middle'>show definitions</span>
        </button>
      )}
      {showDefinitions && (
        <button onClick={toggleAllDefinitions} className="bg-red-100 text-red-700 font-medium text-xs items-center rounded-lg px-1 py-1 transition-colors duration-300 ease-in-out hover:bg-red-200">
          <span className='hover:underline text-middle'>hide definitions</span>
        </button>
      )}
      {data.map((item, index) => {
          if (showCustOnly && !item["is_cust"]){
            return null
          }
          return (<div key={index} onClick={() => toggleDefinitionVisibility(index)} className="border-b border-gray-200 py-2 cursor-pointer relative items-center justify-center">
            <div className="flex justify-between items-center">
              <h2 className="text-lg pr-8">{item.name} <span className="text-sm font-bold text-gray-700">{item.numeral}</span></h2>
              <div title="delete term and definition" className="absolute right-0 top-2 p-2 hover:shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer" onClick={() => deleteTerm(index)}><RedX /></div>
            </div>
            <p>{isDefinitionVisible(index) && item.definition}</p>
            {isDefinitionVisible(index) && (<div className='text-sm text-gray-500'>{formatNums(item.claim_nums, item.paragraph_nums)}</div>)}
            {isDefinitionVisible(index) && (item.paragraph_text && item.paragraph_text != "") && (<div className='text-sm text-gray-500'><strong>Paragraphs:</strong> <HighlightTerm text={item.paragraph_text} term={item.name} /></div>)}
            {isDefinitionVisible(index) && (item.claim_text && item.claim_text != "") && (<div className='text-sm text-gray-500'><strong>Claims:</strong>  <HighlightTerm text={item.claim_text} term={item.name} /></div>)}
          </div>)
        }
      )}
    </div>
  )
}

export default TermListAndDefinitions
