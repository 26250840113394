import React from 'react'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)
ChartJS.defaults.color = '#FFFFFF'

const UsageGraph = ({ usage, projections, MODEL_COSTS, isFullScreen }) => {
   const { projectedGpt4, projectedGpt4Turbo, projectedCost } = projections
    const labels = usage.map(item => item.time_period).concat('Projected')
    const gpt4Data = usage.map(item => parseInt(item.gpt_4, 10)).concat(projectedGpt4)
    const gpt4TurboData = usage.map(item => parseInt(item.gpt_4_turbo, 10)).concat(projectedGpt4Turbo)
    const costsData = usage.map(item =>
      (parseInt(item.gpt_4, 10) * MODEL_COSTS.gpt_4 +
      parseInt(item.gpt_4_turbo, 10) * MODEL_COSTS.gpt_4_turbo)).concat(projectedCost)

    const data = {
      labels,
      datasets: [
        {
          label: 'GPT-4 Usage',
          data: gpt4Data,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          yAxisID: 'yUsage',
        },
        {
          label: 'GPT-4 Turbo Usage',
          data: gpt4TurboData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          yAxisID: 'yUsage',
        },
        {
          label: 'Cost ($)',
          data: costsData,
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          yAxisID: 'yCost',
        }
      ],
    }

    const textSize = (isFullScreen ? 24 : 16)

    const options = {
    scales: {
      x: {
        ticks: {
          font: {
            size: textSize // Applies custom font size to x-axis labels
          }
        }
      },
      yUsage: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Units',
          font: {
            size: textSize // Custom font size for yUsage axis title
          }
        },
        ticks: {
          font: {
            size: textSize // Custom font size for yUsage axis ticks
          }
        }
      },
      yCost: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Cost ($)',
          font: {
            size: textSize // Custom font size for yCost axis title
          }
        },
        ticks: {
          font: {
            size: textSize // Custom font size for yCost axis ticks
          }
        },
        grid: {
          drawOnChartArea: false // Ensures yCost grid doesn't overlap yUsage grid
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: textSize // Custom font size for legend labels
          }
        }
      },
      title: {
        display: isFullScreen,
        text: 'Model Usage and Cost Over Time (with Projections)',
        font: {
            size: textSize // Custom font size for chart title
        }
      }
    }
  }
  return <Line data={data} options={options} />
}

export default UsageGraph
