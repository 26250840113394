function getAllLocalStorageItemsAsJSON() {
  let obj = {}
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    obj[key] = localStorage.getItem(key)
  }
  return JSON.stringify(obj)
}

function convertUtcToEasternTime(utcDateString) {
  const date = new Date(utcDateString)
  const options = { timeZone: 'America/New_York', hour12: true, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }
  const easternTime = date.toLocaleString('en-US', options)
  return easternTime
}

function parseTextForBoldAndStrikethrough(text) {
  const boldPattern = /\@\@(.*?)\@\@/g
  const strikethroughBoldPattern = /\[\[(.*?)\]\]/g

  const parts = []
  let lastIndex = 0

  text.replace(boldPattern, (match, p1, offset) => {
    if (offset > lastIndex) {
      parts.push({ text: text.slice(lastIndex, offset), type: 'normal' })
    }
    parts.push({ text: p1, type: 'bold' })
    lastIndex = offset + match.length
  })

  text.replace(strikethroughBoldPattern, (match, p1, offset) => {
    if (offset > lastIndex) {
      parts.push({ text: text.slice(lastIndex, offset), type: 'normal' })
    }
    parts.push({ text: p1, type: 'strikethroughBold' })
    lastIndex = offset + match.length
  })

  if (lastIndex < text.length) {
    parts.push({ text: text.slice(lastIndex), type: 'normal' })
  }

  return parts
}

function addJobToLocalStorage(itemName, jobId) {
    let curJobs = localStorage.getItem(itemName)
    curJobs = curJobs ? JSON.parse(curJobs) : []
    curJobs.push(jobId)
    localStorage.setItem(itemName, JSON.stringify(curJobs))
}

function removeJobFromLocalStorage(itemName, jobId) {
    let curJobs = localStorage.getItem(itemName)
    curJobs = curJobs ? JSON.parse(curJobs) : []
    curJobs = curJobs.filter(id => id !== jobId)
    localStorage.setItem(itemName, JSON.stringify(curJobs))
}

const capitalizeFirstChar = (str) => {
  if (str === "mpep") return "MPEP §"
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const removeCountryAndKindCodes = (docNumber) => {
  // implementation to remove country and kind codes from docNumber
}


export {
  getAllLocalStorageItemsAsJSON,
  convertUtcToEasternTime,
  parseTextForBoldAndStrikethrough,
  addJobToLocalStorage,
  removeJobFromLocalStorage,
  capitalizeFirstChar
}