import React, { createContext, useState, useEffect, useContext } from 'react'
import ResourceSelector from '../chat_room/ResourceSelector'
import ThemeContext from '../context/ThemeContext'
import ResourceStatusLoader from '../component/ResourceStatusLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

function ContextSummary({ onReferenceClick, contextData, setContextData, addToResources, removedResources, setRemovedResources, resources, completeCallback, handleUploadFile }) {
  const ChatContext = createContext()
  const [popupInfo, setPopupInfo] = useState({ visible: false, itemIndex: null, itemType: null })
  const [hideTimeoutId, setHideTimeoutId] = useState(null)
  const [isContextEmpty, setIsContextEmpty] = useState(false)
  const { theme } = useContext(ThemeContext)

  const showPopup = (index, type) => {
    hoveringOverMenu()
    setPopupInfo({ visible: true, itemIndex: index, itemType: type })
  }

  const hoveringOverMenu = () => {
    if (hideTimeoutId !== null) {
      clearTimeout(hideTimeoutId)
      setHideTimeoutId(null)
    }
  }

  const hidePopup = () => {
    setPopupInfo({ visible: false, itemIndex: null, itemType: null })
  }

  const hidePopupWithTimeOut = (timeout) => {
    const timeoutId = setTimeout(() => {
      setPopupInfo({ visible: false, itemIndex: null, itemType: null })
    }, timeout)
    setHideTimeoutId(timeoutId)
  }


  const onViewClick = (item, type, index) => {
    referenceClickHandler(item, type, index)
    hidePopup()
  }

  const onSelectClick = (item, type, index) => {
    contextData.last_context_number = item
    contextData.last_context_type = type
    localStorage.setItem('chat_context', JSON.stringify(contextData))
    hidePopup()
  }

  const removeItemFromContext = (item, type, indexToRemove) => {
    if (contextData.last_context_type === type && contextData.last_context_number === item) {
      contextData.last_context_number = ''
      contextData.last_context_type = ''
    }
    let newContextData
    if (type === 'publication') {
      newContextData = {
        ...contextData,
        current_publications: contextData.current_publications.filter((_, index) => index !== indexToRemove),
      }
    } else if (type === 'patent') {
      newContextData = {
        ...contextData,
        current_patents: contextData.current_patents.filter((_, index) => index !== indexToRemove),
      }
    } else if (type === 'upload') {
      newContextData = {
        ...contextData,
        resource_names: contextData.resource_names.filter((_, index) => index !== indexToRemove),
        resource_ids: contextData.resource_ids.filter((_, index) => index !== indexToRemove),
      }
    } else if (type === 'mpep') {
      newContextData = {
        ...contextData,
        mpep_sections: contextData.mpep_sections.filter((_, index) => index !== indexToRemove),
      }
    }
    setContextData(newContextData)
    localStorage.setItem('chat_context', JSON.stringify(newContextData))
    let newResource = {
      type: type,
      resource_id: item
    }
    setRemovedResources((prevResources) => [...prevResources, newResource])
    checkContextEmpty()
  }

  function loadContextData() {
    const storedData = localStorage.getItem('chat_context')
    if (storedData != null && storedData !== '') {
      setContextData(JSON.parse(storedData))
    }
  }

  useEffect(() => {
    loadContextData()

    const handleStorageUpdate = () => loadContextData()
    window.addEventListener('storage-update', handleStorageUpdate)

    return () => window.removeEventListener('storage-update', handleStorageUpdate)
  }, [])

  const checkContextEmpty = () => {
    try {
      setIsContextEmpty(!contextData.resource_names.length && !contextData.mpep_sections.length &&
        !contextData.current_patents.length && !contextData.current_applications.length &&
        !contextData.current_publications.length && !contextData.last_context_number &&
        !contextData.last_context_type)
    } catch (e) {
      setIsContextEmpty(true)
    }
  }

  useEffect(() => {
    checkContextEmpty()
  }, [contextData])

  const referenceClickHandler = (item, type, index) => {
    contextData.last_context_number = item
    contextData.last_context_type = type
    localStorage.setItem('chat_context', JSON.stringify(contextData))
    if (type === 'mpep') {
      onReferenceClick(item, type, contextData.mpep_sections[index])
    } else if (type === 'patent') {
      onReferenceClick(item, type, contextData.current_patents[index])
    } else if (type === 'publication') {
      onReferenceClick(item, type, contextData.current_publications[index])
    } else {
      onReferenceClick(item, type, contextData.resource_ids[index])
    }
  }

  const RenderContextItem = ({ label, items, resource_array = [], type }) => {
    return (
      items.length > 0 && (
        <div className="flex-none">
          <strong>{label}:</strong>
          {items.map((item, index) => {
            const resource = resource_array.find((res) => {
              console.log('Resource:', res)
              return res.resourceName.split("-")[2] === item || res.resourceName === item
            })
            const jobId = resource ? resource.jobId : null
            const resourceNotLoaded = resource ? !resource.isLoaded : true
            return (
              <div className={`relative inline max-w-[calc(100vw-84px)] overflow-x-auto ${jobId ? "flex flex-row" : "" } ${theme === "dark" ? "text-blue-100" : "text-blue-600"} hover:underline ml-2 py-1 pl-1
                  ${contextData.last_context_type === type && contextData.last_context_number === item ? theme === "dark" ? "bg-blue-700 text-white rounded" : "text-black rounded" : ""}`}
                  key={index}
                  onClick={() => onViewClick(item, type, index)}
                  title="view document"
                  >
                <button
                  onClick={() => onViewClick(item, type, index)}
                  className="mr-1"
                >
                  {item}
                </button>
                <button
                  onClick={(e) => {(popupInfo.visible && popupInfo.itemIndex === index && popupInfo.itemType === type) ? hidePopup() : showPopup(index, type); e.stopPropagation()}}
                  className="rounded p-1 border-white border hover:text-blue-500 hover:bg-white hover:border-blue-500 hover:border"
                >
                  <FontAwesomeIcon icon={faBars} />
                </button>
                {jobId && resourceNotLoaded && (
                  <ResourceStatusLoader
                    resourceType={type}
                    resourcePubNo={item}
                    jobId={jobId}
                    resourceName={resource.resourceName}
                    completeCallback={(title) => {completeCallback(title, resource)}}
                    hideName={true}
                    isMessage={false}
                  />
                )}
                {popupInfo.visible && popupInfo.itemIndex === index && popupInfo.itemType === type && (
                  <div onMouseEnter={hoveringOverMenu} className={`absolute bottom-full mb-2 left-0 border rounded shadow-lg p-2 text-xs flex ${theme === 'dark' ? 'text-white bg-gray-900' : 'bg-white'}`}>
                    <button className="inline m-1 p-1 bg-green-500 text-white rounded hover:bg-green-100 hover:text-green-500"
                      onClick={(e) => {onViewClick(item, type, index); e.stopPropagation()}}
                      title="view document"
                    >View</button>
                    <button className="inline m-1 p-1 bg-blue-500 text-white rounded hover:bg-blue-100 hover:text-blue-500"
                    onClick={(e) => {onSelectClick(item, type, index); e.stopPropagation()}}
                      title="select document as chat default"
                    >Select</button>
                    <button className="inline m-1 p-1 bg-red-500 text-white rounded hover:bg-red-100 hover:text-red-500"
                    onClick={(e) => {removeItemFromContext(item, type, index); e.stopPropagation()}}
                      title="remove document from context"
                    >Remove</button>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )
    )
  }

  const [showAddResource, setShowAddResource] = useState(false)
  const [selectedResourceType, setSelectedResourceType] = useState('application')
  const [resourceId, setResourceId] = useState('')

  return (
    <div className={`p-2 flex flex-wrap gap-4 text-xs ${theme === 'dark' ? 'text-white' : ''}`} onMouseLeave={() => hidePopup()}>
      {isContextEmpty ? (
        <span>No references in context</span>
      ) : (
        <>
          <RenderContextItem label='MPEP' items={contextData.mpep_sections} resource_array={[]} type='mpep' />
          <RenderContextItem label='Uploads' items={contextData.resource_names} resource_array={resources?.filter((resource) => resource.type === 'upload')} type='upload' />
          <RenderContextItem label='Patents' items={contextData.current_patents} resource_array={resources?.filter((resource) => resource.type === 'patent')} type='patent' />
          <RenderContextItem label='Applications' items={contextData.current_applications} resource_array={resources?.filter((resource) => resource.type === 'application')} type='application' />
          <RenderContextItem label='Publications' items={contextData.current_publications} resource_array={resources?.filter((resource) => resource.type === 'publication')} type='publication' />
        </>
      )}
      <div className={`flex items-center space-x-2 rounded p-0 ${showAddResource ? `border p-1` : ``} ${theme === 'dark' ? 'text-white' : ''}`}>
        {!showAddResource && (
        <>
          <div
            className="bg-green-200 p-1 cursor-pointer text-black hover:bg-green-600 hover:text-green-100 rounded flex items-center justify-center"
            onClick={() => setShowAddResource(true)}
          >
            Add Patent Resource
          </div>
          <div
            className="bg-secondary-light p-1 cursor-pointer text-black hover:bg-secondary hover:text-gray-100 rounded flex items-center justify-center"
            onClick={()=> handleUploadFile()}
          >
            Upload File
          </div>
        </>
        )}
        {showAddResource && (
          <div
            className="bg-red-200 p-1 m-1 cursor-pointer text-black hover:bg-red-600 rounded flex items-center justify-center"
            onClick={() => setShowAddResource(false)}
          >
            Cancel
          </div>
        )}
        {showAddResource && (
          <div className="m-1 ml-2 flex items-center">
            <ResourceSelector addToResources={(input) => { setShowAddResource(false); addToResources(input) }} theme={theme} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ContextSummary
