import React, { useState } from 'react'

function ResourceSelector({ addToResources, theme }) {
  const [selectedResourceType, setSelectedResourceType] = useState('application')
  const [resourceId, setResourceId] = useState('')

  const handleAddResource = () => {
    if (resourceId) {
      addToResources([{ type: selectedResourceType, id: resourceId.replaceAll(",","").replaceAll("/","") }])
      setResourceId('')
    }
  }

  return (
    <div className={`flex flex-col space-y-2 ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-black'} p-4 rounded-lg shadow-lg`}>
      <select
        value={selectedResourceType}
        onChange={(e) => setSelectedResourceType(e.target.value)}
        className={`p-1 border rounded ${theme === 'dark' ? 'bg-gray-700 text-white border-gray-600' : 'bg-gray-50 text-black border-gray-300'}`}
      >
        <option value="application">Application</option>
        <option value="patent">Patent</option>
        <option value="publication">Publication</option>
      </select>
      <input
        type="text"
        value={resourceId}
        onChange={(e) => setResourceId(e.target.value.replace(/\D/g, ""))}
        placeholder="Resource ID"
        className={`p-1 border rounded ${theme === 'dark' ? 'bg-gray-700 text-white border-gray-600' : 'bg-gray-50 text-black border-gray-300'}`}
      />
      <button
        onClick={handleAddResource}
        className={`p-1 rounded ${theme === 'dark' ? 'bg-blue-500 text-white hover:bg-blue-400' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
      >
      Add
      </button>
    </div>
  )
}

export default ResourceSelector
