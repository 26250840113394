import React, { useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import RedX from '../../component/RedX'

const CustomQuestionSection = ({ theme, showCustomQuestionHistory, setShowCustomQuestionHistory, customQuestions, setCustomQuestions, showCustomQuestionMenu, setShowCustomQuestionMenu, customQuestionText, setCustomQuestionText, customQuestionLoading, setCustomQuestionLoading, handleCustomQuestionTextChange, handleCustomQuestionSubmit, scrollToBottom, scrollDivRef, handleScroll, checkScrollNeed, showTopButton, showBottomButton, scrollToTop, handleDocumentQuestionHistory }) => {

  useEffect(() => {
    const div = scrollDivRef.current
    if (scrollDivRef.current) {
      checkScrollNeed()
      div.addEventListener('custom-question-history-scroll', handleScroll)
      return () => div.removeEventListener('custom-question-history-scroll', handleScroll)
    }
  }, [])

  return (
    <div className="mr-2">
      {showCustomQuestionHistory && (
        <div>
          <div ref={scrollDivRef} className='relative flex-grow overflow-y-auto py-1 px-2 space-y-4 max-h-96'>
            <button onClick={() => setShowCustomQuestionHistory(false)} className="absolute top-2 right-2 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out">
              <RedX />
            </button>
            {customQuestions && Array.isArray(customQuestions) && customQuestions.length > 0 ? (
              customQuestions.map((messageObj, idx) => {
                const isValidTimestamp = !isNaN(new Date(messageObj.timestamp).getTime())
                return (
                  <div key={idx}>
                    <div className="flex justify-center my-2">
                      <div className="w-full border-t border-gray-400"></div>
                    </div>
                    {isValidTimestamp && (
                      <div className="text-center text-sm text-gray-500">
                        {new Date(messageObj.timestamp).toLocaleString()}
                      </div>
                    )}
                    <div className='flex justify-end mt-1'>
                      <div className={`min-w-[250px] max-w-[calc(100%-100px)] relative p-2 whitespace-pre-wrap rounded-lg border max-w-[72%] ${theme === 'dark' ? 'bg-gray-600 text-gray-100 border-gray-100' : 'bg-primary text-gray-100 border-primary'}`}>
                        <div>{messageObj.input_text.replace(/\n{1,}/g, '\n')}</div>
                      </div>
                    </div>
                    <div className='flex justify-start mt-1'>
                      <div className={`min-w-[250px] max-w-[calc(100%-100px)] relative p-2 whitespace-pre-wrap rounded-lg border max-w-[72%] ${theme === 'dark' ? 'bg-gray-800 text-white' : 'border-primary text-black border-black'}`}>
                        <div>{messageObj.response.replace(/\n{1,}/g, '\n')}</div>
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="text-center text-gray-500">
                No custom question history.
              </div>
            )}
          </div>
          <div className={`border-t p-4 rounded w-full ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
            <div className='flex space-x-4'>
              <textarea
                className={`flex-grow border rounded p-2 ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} ${customQuestionLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                value={customQuestionText}
                onChange={handleCustomQuestionTextChange}
                placeholder='Enter Question'
                disabled={customQuestionLoading}
              />
              <button
                onClick={handleCustomQuestionSubmit}
                className={`rounded relative ${theme === 'dark' ? 'bg-gray-800 text-white border-white border' : 'bg-primary text-white'} ${customQuestionLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={customQuestionLoading}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomQuestionSection
