import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faSync } from '@fortawesome/free-solid-svg-icons'
import TermListAndDefinitions from '../../op/TermListAndDefinitions'

const TermListSection = ({ theme, isTermListLoading, showTermListError, termListErrorMessage, handleGetTermListAndDefinitions, handleRefreshTermListAndDefinitions, termListData, hideTerms, setHideTerms, addCustTerms, handleDeleteTerm, handleDownloadTerms, isCustTermListLoading }) => {
  <div>
    {isTermListLoading && (
      <div className="my-4 bg-gray-200 rounded-lg p-4">
        <div>Loading term list and definitions . . .</div>
      </div>
    )}
    {showTermListError && (
      <div className="my-4 bg-gray-200 rounded-lg p-4">
        <div>Error loading terms: {termListErrorMessage}</div>
      </div>
    )}
    {termListData && !hideTerms && (
      <div className="my-1 bg-gray-50 rounded-lg p-1">
        <button onClick={() => setHideTerms(true)} className="flex items-center bg-red-100 text-red-700 font-medium text-xs rounded-lg px-2 py-1 transition-colors duration-300 ease-in-out hover:bg-red-200">
          <FontAwesomeIcon icon={faEyeSlash} className="mr-1" />
          Hide Terms
        </button>
        <button onClick={handleRefreshTermListAndDefinitions} className="flex items-center bg-orange-100 text-orange-700 font-medium text-xs rounded-lg px-2 py-1 transition-colors duration-300 ease-in-out hover:bg-orange-200">
          <FontAwesomeIcon icon={faSync} className="mr-1" />
          Refresh Terms & Definitions
        </button>
        <div>
          <TermListAndDefinitions data={termListData} addTheTerms={addCustTerms} showLoader={isCustTermListLoading} deleteTerm={handleDeleteTerm} downloadTerms={handleDownloadTerms} />
        </div>
      </div>
    )}
    {termListData && hideTerms && (
      <div className="my-1 bg-gray-50 rounded-lg p-1">
        <button onClick={() => setHideTerms(false)} className={`flex items-center font-medium text-xs rounded-lg px-2 py-1 transition-colors duration-300 ease-in-out ${theme === 'dark' ? 'bg-blue-800 text-blue-400 hover:text-blue-200 hover:bg-blue-600' : 'bg-blue-100 text-blue-700 hover:bg-blue-200'}`}>
          <FontAwesomeIcon icon={faEye} className="mr-1" />
          Show Terms
        </button>
      </div>
    )}
  </div>
}

export default TermListSection
