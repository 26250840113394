import React, { useContext } from 'react'
import PricingModelDesc from '../info/PricingModelDesc'
import RedX from '../component/RedX'
import ThemeContext from '../context/ThemeContext'
import ThemedText from '../component/ThemedText'
import BoldThemedText from '../component/BoldThemedText'
import LightThemedText from '../component/LightThemedText'

const QuickStartGuide = ({ addTextToMessageInput, closeQuickGuide, setShowPolicy, setShowTermsOfUse }) => {
  const { theme } = useContext(ThemeContext)

  return (
    <div className={`p-4 max-w-md mx-auto rounded-xl shadow-md md:max-w-2xl ${theme === 'dark' ? 'text-white bg-gray-900 border-gray-200 border' : 'bg-white'}`}>
      <div className="md:flex">
        <div className="relative pl-2 overflow-y-auto max-h-[calc(100vh-80px)]">
          <div className={`sticky top-0 uppercase tracking-wide text-sm font-semibold flex w-full ${theme === 'dark' ? 'text-indigo-200' : 'text-indigo-500 '}`}>
            <div className={`flex-inline w-full bg-opactiy-50 ${theme === 'dark' ? 'bg-gray-900' : 'bg-white '}`}>Quick Start Guide</div>
            <div className="flex-inline absolute right-1 cursor-pointer" title="Close Quick Start Guide" onClick={closeQuickGuide}><RedX /></div>
          </div>

          <a href="#" className={`block mt-1 text-lg leading-tight font-medium hover:underline ${theme === 'dark' ? 'text-white' : 'text-black'}`}>Getting Started with Patents.chat</a>
          <p className="mt-2 text-sm text-gray-500">This guide helps you quickly dive into conversing with patents, accessing MPEP, and uploading documents for analysis.</p>
          <button
            onClick={() => setShowPolicy(true)}
            className={`mt-4 py-2 px-4 rounded ${theme === 'dark' ? 'bg-secondary-light text-gray-900' : 'bg-secondary text-white'}`}
          >
            Data Policy
          </button>
          <button
            onClick={() => setShowTermsOfUse(true)}
            className={`mt-4 ml-4 py-2 px-4 rounded ${theme === 'dark' ? 'bg-secondary-light text-gray-900' : 'bg-secondary text-white'}`}
          >
            Terms of Use
          </button>
          <h3 className="font-semibold mt-2">Features:</h3>
          <ul className={`list-disc pl-5 ${theme === 'dark' ? 'text-gray-200' : 'text-gray-600'}`}>
            <li>Load specification and claims for US patents and publications.</li>
            <li>Access MPEP guidelines, supplemented with practice information and other relevant patent examination information.</li>
            <li>Upload and discuss text documents (Word docs, PDFs, etc.), such as non-patent literature, engineering documents, or non-US patent references.</li>
            <li>Support for various languages, including <span className="font-semibold text-sm">Spanish, French, German, Italian, Dutch, Russian, Chinese, Japanese, and Korean.</span></li>
            <li>Quickly obtain summaries, insights, and other info from the loaded patents and documents.</li>
            <li><BoldThemedText>New! </BoldThemedText> <span className="">Summaries of drawings, along with viewing drawings.  Drawing summaries and info is loaded by typing in a U.S. patent or publication number along with <ThemedText>-d</ThemedText>, such as <ThemedText>10123456 -d</ThemedText>.</span></li>
            <li><BoldThemedText>New! </BoldThemedText> <span className="">Automatically-generated summaries of full documents, including patent documents and uploaded documents.</span></li>
            <li><BoldThemedText>New! </BoldThemedText> <span className="">Part/component list for patent documents with definitions and paragraphs of support.</span></li>
            <li><BoldThemedText>New! </BoldThemedText> <span className="">Full document question answering/instruction following.</span></li>
            <li><BoldThemedText>New! </BoldThemedText> <span className="">Bulk full document question answering/instruction following.</span></li>
            <li><BoldThemedText>New! </BoldThemedText> <span className="">Discuss multiple documents at once.</span></li>
            <li><BoldThemedText>New! </BoldThemedText> <span className="">OCR uploaded PDFs, including US Patents to determine column, line numbers.</span></li>
            <li><BoldThemedText>New! </BoldThemedText> <span className="">Office action rejection analysis, summary, and insight.</span></li>
            <li><BoldThemedText>New! </BoldThemedText> <span className="">Specification component/function breakdown with citations.</span></li>
            <li><LightThemedText>Coming soon...</LightThemedText> <span className="">Search patents and other publications (Google Scholar) by keywords, inventor, assignee, date, etc.</span></li>
            <li><LightThemedText>Coming soon...</LightThemedText> <span title="Identifying characterizations made in the claims of those terms, as well as identifying where those characterizations are supported in the specification." className="">Schedule of claim terms & characterizations. </span>
            </li>
         </ul>

          <h3 className="font-semibold mt-2">How to Use:</h3>
          <ul className={`list-disc pl-5 ${theme === 'dark' ? 'text-gray-200' : 'text-gray-600'}`}>
            <li>To automatically load a US patent or pre-grant publication, provide a patent or publication number. E.g., "
            <ThemedText>10123456</ThemedText>" or "<ThemedText>summarize claims for 20240123456</ThemedText>".</li>
            <li>Once the document information is loaded, it will be able to answer questions about the patent documents and application. E.g., "
            <ThemedText>what are the differences between the independent claims</ThemedText>" or "<ThemedText>summarize paragraphs 10-14</ThemedText>".</li>
            <li>To load information about the drawings into the chat, use the flag "-d" in connection with a patent or publication number. E.g., "
            <ThemedText>10123456 -d</ThemedText></li>
            <li>To access MPEP content, ask "
            <ThemedText>What does MPEP say about [topic]?</ThemedText>" or "
            <ThemedText>summarize MPEP 2143</ThemedText>".</li>
            <li>To upload a document for discussion, click the <ThemedText>Upload</ThemedText>
            <ThemedText>File</ThemedText> button and select your file. Then, start discussing any part of the document by mentioning the section or keyword you're interested in.</li>
            <li>Click the <ThemedText>Context</ThemedText> button to view the documents loaded for the chat session. Any unambiguous reference made by the user is assumed to refer to the document last referenced by the user. The implicit context is highlighted in blue (e.g., <span className=" text-xs p-1 bg-blue-400 text-white rounded">10123456</span>) and is modifiable through selecting a document.</li>
            <li>Click a document number or name to set that document to the implicit context and load the text in the document viewer pane. Here, individual paragraphs may be added to the message input or summarized.</li>
            <li>Click the <ThemedText>Save</ThemedText> <ThemedText>as</ThemedText> <ThemedText>New</ThemedText> <ThemedText>Chat</ThemedText> button to save your chat for later recall.
              <span className="font-mono text-xs">  Use&nbsp;ctrl&nbsp;+&nbsp;s&nbsp;to&nbsp;re-save&nbsp;chat.</span>
            </li>
            <li><span className="font-bold">Office Action Analysis: </span>Office Action analysis for a U.S. patent application is done by sending <ThemedText>EVAL_OA</ThemedText> followed by the application serial number.  Use <ThemedText>EVAL_OA_R</ThemedText> to generate a new response and overwrite the previous one.  For example,&nbsp;
                <ThemedText>EVAL_OA</ThemedText> <ThemedText>17123456</ThemedText>
            </li>
            <li><span className="font-bold">Office Action Issue Extraction: </span>Extract Issues from most recent Office Action for a U.S. patent application by sending <ThemedText>OA_ISSUES</ThemedText> followed by the application serial number.  For example,&nbsp;
                <ThemedText>OA_ISSUES</ThemedText> <ThemedText>17123456</ThemedText>
                <div className="text-xs hover:text-base hover:font-bold">Note: running <ThemedText>EVAL_OA</ThemedText> will regenerate and overwrite the previously extracted issue information.</div>
            </li>
            <li><span className="font-bold">Feature Map: </span>Generate a feature map for a U.S. patent application by sending <ThemedText>SPEC_MAP</ThemedText> followed by the application serial number and optionally a custom instruction to be considered when generating the specification feature map.  For example,&nbsp;
                <ThemedText>SPEC_MAP</ThemedText> <ThemedText>17123456</ThemedText>. As another example,
                <ThemedText> SPEC_MAP</ThemedText> <ThemedText>17123456</ThemedText> <ThemedText>focus on the system components and not the method</ThemedText>
                <div className="">Run <ThemedText>SPEC_MAP_R</ThemedText> to regenerate and overwrite the previous specification feature map.</div>
            </li>
            <li><span className="font-bold">Parts List: </span>Generate a parts/components list for a U.S. patent application by sending <ThemedText>PARTS_LIST</ThemedText> followed by the application serial number and optionally a custom instruction to be considered when generating the specification feature map.  For example,&nbsp;
                <ThemedText>PARTS_LIST</ThemedText> <ThemedText>17123456</ThemedText>. As another example,
                <ThemedText> PARTS_LIST</ThemedText> <ThemedText>17123456</ThemedText> <ThemedText>focus on components of the robotic arm 12</ThemedText>
                <div className="">Run <ThemedText>PARTS_LIST_R</ThemedText> to regenerate and overwrite the previous parts/components list.</div>
            </li>
            <li><span className="font-bold">Custom Prompt Response: </span>Perform custom question answering/prompt following by clicking on a document to open the document viewer, and then selecting the <span className=" text-xs p-1 bg-blue-400 text-white rounded">Custom Question</span> button.</li>
            <li><span className="font-bold">Bulk Custom Prompt Response: </span>Perform bulk custom question answering/prompt following by typing into the chat the US Patent & Publication numbers along wth the keywords&nbsp;
                <ThemedText>DO_CUSTOM_PROMPT</ThemedText> <ThemedText>PROMPT:</ThemedText> followed by your question or prompt.
                E.g., <ThemedText>10112332, 20190112332, 11233221 DO_CUSTOM_PROMPT PROMPT: does it teach a flux capacitor? And, if so, cite the paragraph number(s) or claim(s).</ThemedText>
            </li>
            {false && <li>To search patent and tech documents, say "
              <ThemedText>Provide documents on [topic]</ThemedText>".</li>
            }
          </ul>
          <PricingModelDesc />
          <div className="mt-4">
            <button className={`font-bold py-2 px-4 rounded ${theme === 'dark' ? 'bg-indigo-700 hover:bg-indigo-600 text-white' : 'bg-indigo-500 hover:bg-indigo-400 text-white'}`} onClick={closeQuickGuide}>
              Start Chatting
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuickStartGuide
