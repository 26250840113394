import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaBook, FaFileAlt, FaImages, FaFolderOpen, FaHistory, FaRibbon, FaBrain, FaArrowUp, FaArrowDown } from 'react-icons/fa'

const VerticalDocNavBar = ({ sections, onSelect, sectionTitles, scrollToTop, scrollToBottom, setShowSummary, theme }) => {
  const [currentSection, setCurrentSection] = useState(null)

  const icons = {
    background: <FaBook />,
    detailed_desc: <FaFileAlt />,
    drawings_desc: <FaImages />,
    summary: <FaFolderOpen />,
    resource: <FaHistory />,
    claims: <FaRibbon />,
    ai_summary: <FaBrain />,
  }

  const handleSelect = (id) => {
    onSelect(id)
    const element = document.getElementById(id === 'claims' ? 'start-of-claims' : id === 'ai_summary' ? 'start-of-ai-summary' : `section-${id}`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
    if (id === "ai_summary") {
      setShowSummary(true)
    }
  }

  const handleScroll = () => {
    const keys = [...Object.keys(sections), 'claims', 'ai_summary']
    let found = false
    for (const key of keys) {
      const element = document.getElementById(key === 'claims' ? 'start-of-claims' : key === 'ai_summary' ? 'start-of-ai-summary' : `section-${key}`)
      if (element) {
        const rect = element.getBoundingClientRect()
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          setCurrentSection(key)
          found = true
          break
        }
      }
    }
    if (!found) {
      setCurrentSection(null)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [sections])

  return (
    <div className={`w-20 border-l-4 px-1 pt-5 ${theme === 'dark' ? 'border-gray-700 bg-gray-900 border-r border-gray-700' : 'border-black bg-gray-100 border-r border-gray-300'} h-screen flex flex-col items-center justify-center`}>
      <button onClick={scrollToTop} className={`text-xl p-2 ${theme === 'dark' ? 'hover:bg-gray-700 text-gray-400 hover:text-primary' : 'hover:bg-gray-200 text-gray-600 hover:text-primary'} flex flex-col items-center`}>
        <FaArrowUp />
        <div className="text-xs">top of spec</div>
      </button>
      <div
        className={`w-full py-4 text-center cursor-pointer flex flex-col items-center justify-center ${currentSection === 'ai_summary' ? 'bg-primary-300 text-primary-700' : `${theme === 'dark' ? 'hover:bg-gray-700 text-gray-400' : 'hover:bg-gray-200 text-gray-600'}`}`}
        onClick={() => handleSelect('ai_summary')}
      >
        <div className="text-2xl">
          {icons.ai_summary}
        </div>
        <span className="text-xs mt-1">AI Summary</span>
      </div>
      {Object.entries(sections).sort((a, b) => {
        const keyA = parseInt(a[1], 10)
        const keyB = parseInt(b[1], 10)
        return keyA - keyB
      }).map(([key, value]) => (
        <div
          key={key}
          className={`w-full py-4 text-center cursor-pointer flex flex-col items-center justify-center ${currentSection === key ? 'bg-primary-300 text-primary-700' : `${theme === 'dark' ? 'hover:bg-gray-700 text-gray-400' : 'hover:bg-gray-200 text-gray-600'}`}`}
          onClick={() => handleSelect(key)}
        >
          <div className="text-2xl">
            {icons[key] || <FaRibbon />}
          </div>
          <span className="text-xs mt-1">{sectionTitles[key]}</span>
        </div>
      ))}
      <div
        className={`w-full py-4 text-center cursor-pointer flex flex-col items-center justify-center ${currentSection === 'claims' ? 'bg-primary-300 text-primary-700' : `${theme === 'dark' ? 'hover:bg-gray-700 text-gray-400' : 'hover:bg-gray-200 text-gray-600'}`}`}
        onClick={() => handleSelect('claims')}
      >
        <div className="text-2xl">
          {icons.claims}
        </div>
        <span className="text-xs mt-1">Claims</span>
      </div>
      <button onClick={scrollToBottom} className={`text-xl p-2 ${theme === 'dark' ? 'hover:bg-gray-700 text-gray-400 hover:text-primary' : 'hover:bg-gray-200 text-gray-600 hover:text-primary'} flex flex-col items-center`}>
        <FaArrowDown />
        <div className="text-xs">bottom of spec</div>
      </button>
    </div>
  )
}

VerticalDocNavBar.propTypes = {
  sections: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  sectionTitles: PropTypes.object.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  scrollToBottom: PropTypes.func.isRequired,
  setShowSummary: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
}

export default VerticalDocNavBar
