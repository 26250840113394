import React, { useContext, useState, useEffect } from 'react'
import { FaBook, FaGavel, FaUniversity, FaFileAlt, FaDollarSign, FaLightbulb } from 'react-icons/fa'
import MpepContent from '../chat_room/MpepContent'
import ThemeContext from '../context/ThemeContext'
import Spinner from '../component/Spinner'

const resources = [
  { id: 'mpep', icon: <FaBook />, label: 'MPEP' },
  { id: 'cfr', icon: <FaGavel />, label: '37 CFR' },
  { id: 'usc', icon: <FaUniversity />, label: '35 USC' },
  { id: 'federal-register', icon: <FaFileAlt />, label: 'Federal Register' },
  { id: 'uspto-fees', icon: <FaDollarSign />, label: 'USPTO Fees' },
  { id: 'abstract-idea-info', icon: <FaLightbulb />, label: '101 Abstract Idea' }
]

const ResourcesPage = ({ resourceNumber, resourceType, setResourceNumber, setResourceType }) => {
  const { theme } = useContext(ThemeContext)
  const [selectedResource, setSelectedResource] = useState(null)
  const [tempResourceNumber, setTempResourceNumber] = useState('')
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const storedResourceType = localStorage.getItem('resourceType')
    const storedResourceNumber = localStorage.getItem('resourceNumber')
    if (!resourceType && storedResourceType) {
      setResourceType(storedResourceType)
      const resource = resources.find(res => res.id === storedResourceType)
      setSelectedResource(resource)
    }
    if (!resourceNumber && storedResourceNumber) {
      setResourceNumber(storedResourceNumber)
    }
  }, [resourceNumber, resourceType, setResourceNumber, setResourceType])

  useEffect(() => {
    let debounceTimeout
    let spinnerTimeout
    let previousResourceNumber = resourceNumber

    if (query && !isNaN(query)) {
      debounceTimeout = setTimeout(() => {
        fetchDocument(query)
      }, 5000)

      spinnerTimeout = setTimeout(() => {
        setLoading(true)
      }, 2700)
    }

    const fetchDocument = async (number) => {
      try {
        const response = await fetchDocument("mpep", number)
        if (response && response.description) {
          setResourceNumber(number)
          localStorage.setItem('resourceNumber', number)
          setErrorMessage('')
        } else {
          setErrorMessage(`No data available for section ${number}.`)
          setResourceNumber(previousResourceNumber)
        }
      } catch (error) {
        setErrorMessage('Error fetching document.')
        setResourceNumber(previousResourceNumber)
      } finally {
        setLoading(false)
      }
    }

    return () => {
      clearTimeout(debounceTimeout)
      clearTimeout(spinnerTimeout)
    }
  }, [query, resourceNumber, setResourceNumber])

  const onSelectResource = resource => {
    setSelectedResource(resource)
    setResourceType(resource.id)
    setTempResourceNumber('')
    localStorage.setItem('resourceType', resource.id)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!isNaN(query)) {
        setResourceNumber(query)
        localStorage.setItem('resourceNumber', query)
        setErrorMessage('')
      } else {
        setErrorMessage('Input should be a number.')
      }
    }
  }

  const handleSubmit = () => {
    if (!isNaN(tempResourceNumber)) {
      setResourceNumber(tempResourceNumber)
      localStorage.setItem('resourceNumber', tempResourceNumber)
      setErrorMessage('')
    } else {
      setErrorMessage('Input should be a number.')
    }
  }

  return (
    <>
      <div className={`flex flex-col ${resourceNumber ? "" : "items-center"}`}>
        {resourceNumber && (
          <div className="w-full flex items-center p-4 bg-primary-light-2 shadow-md">
            <div className="flex items-center space-x-4">
              <div className="text-lg font-semibold text-white">
                {errorMessage ? errorMessage : `Section ${resourceNumber}`}
              </div>
              {resources.map(resource => (
                <div
                  key={resource.id}
                  className={`flex items-center p-2 rounded cursor-pointer transition mr-1
                    ${theme === 'dark' ? 'bg-primary-half hover:bg-primary-3-quarters text-white' : 'bg-primary-light hover:bg-primary-light-2 text-black'} 
                    ${selectedResource?.id === resource.id ? 'ring-4 ring-primary text-white font-bold' : ''}`}
                  onClick={() => onSelectResource(resource)}
                >
                  {resource.icon}
                  <span className="ml-2">{resource.label}</span>
                </div>
              ))}
            </div>
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                value={query}
                onChange={e => setQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                className="form-input px-3 py-2 mx-4 w-72 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-primary-light focus:ring-1 focus:ring-primary-light"
              />
              {loading && <Spinner className="absolute right-2 top-1/2 transform -translate-y-1/2" />}
            </div>
          </div>
        )}
        {selectedResource && !resourceNumber && (
          <div className="flex flex-col items-center mt-8 mb-6">
            <div className={`text-lg font-semibold ${theme === 'dark' ? 'text-gray-300' : 'text-primary-light-2'} mb-2`}>
              Please enter {selectedResource.label} section:
            </div>
            <input
              type="text"
              placeholder="Type here..."
              value={tempResourceNumber}
              onChange={e => setTempResourceNumber(e.target.value)}
              className="form-input mt-1 block w-full max-w-xs px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-primary-light focus:ring-1 focus:ring-primary-light"
            />
            <button
              onClick={handleSubmit}
              className="mt-4 px-4 py-2 bg-primary hover:bg-primary-3-quarters text-white font-bold rounded-lg shadow"
            >
              Submit
            </button>
          </div>
        )}
        {resourceNumber && !errorMessage && (
          <MpepContent resourceNumber={resourceNumber} />
        )}
        {!resourceNumber && (
          <div className={`grid grid-cols-3 gap-6 p-6 ${theme === 'dark' ? 'bg-gray-900' : 'bg-primary'}`}>
            {resources.map(resource => (
              <div
                key={resource.id}
                className={`flex flex-col items-center justify-center p-4 rounded-lg shadow-lg cursor-pointer transition 
                  ${theme === 'dark' ? 'bg-primary-half hover:bg-primary-3-quarters' : 'bg-primary-light hover:bg-primary-light-2'} 
                  ${selectedResource?.id === resource.id ? 'ring-4 ring-primary-light' : ''}`}
                onClick={() => onSelectResource(resource)}
              >
                <div className={`text-4xl text-white mb-4 ${selectedResource?.id === resource.id ? 'text-secondary' : ''}`}>{resource.icon}</div>
                <span className={`text-lg font-semibold ${theme === 'dark' ? 'text-primary-light' : selectedResource?.id === resource.id ? 'text-black' : ''}`}>{resource.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default ResourcesPage
