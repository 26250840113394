import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import RedX from '../component/RedX'

const DataInfo = ({ theme, closeFn, setShowPolicy, setShowTermsOfUse }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className={`relative p-6 max-w-md mx-auto rounded-xl shadow-md overflow-hidden ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <button onClick={closeFn} className="absolute top-2 right-2">
        <RedX />
      </button>
      <h2 className="text-xl font-bold mb-4">Dual Encryption: Enhanced Security</h2>
      <h3 className="text-lg font-semibold mb-2">What is Dual Encryption?</h3>
      <p className="mb-4">
        Dual Encryption combines End-to-End Encryption and HTTPS to provide comprehensive data protection. This means your data is encrypted at all stages of its journey: from your device to our servers and back, and at two levels: in app (AES-256) and in transit (TLS 1.2+).
      </p>
      <h3 className="text-lg font-semibold mb-2 flex items-center cursor-pointer" onClick={toggleExpand}>
        Why is it Important?
        <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} className="ml-2" />
      </h3>
      {isExpanded && (
        <ul className="list-disc list-inside mb-4">
          <li><strong>Confidentiality:</strong> Ensures that your data remains private and unreadable by unauthorized parties</li>
          <li><strong>Integrity:</strong> Prevents data tampering during transmission and storage</li>
          <li><strong>Compliance:</strong> Meets industry standards and regulatory requirements for data protection</li>
          <li><strong>User Privacy:</strong> Safeguards your personal information, enhancing trust and security</li>
          <li><strong>Mitigation Against Attacks:</strong> Protects against Man-in-the-Middle (MitM) attacks and data breaches</li>
        </ul>
      )}
      <h3 className="text-lg font-semibold mb-2">Data Policy</h3>
      <p className="mb-4">
        Your data is always encrypted during web communications. Your information is not used for training or by any other party but solely for generating responses and information for you.
      </p>
      <p className="mb-4">
        We use GPT models through OpenAI's Enterprise framework to provide advanced responses. You can review OpenAI's privacy policy <a href="https://openai.com/enterprise-privacy/" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">here</a>.
      <p />
      <button
        onClick={() => setShowPolicy(true)}
        className={`mt-4 py-2 px-4 rounded ${theme === 'dark' ? 'bg-secondary-light text-gray-900' : 'bg-secondary text-white'}`}
      >
        Data Policy
      </button>
      <button
        onClick={() => setShowTermsOfUse(true)}
        className={`mt-4 ml-4 py-2 px-4 rounded ${theme === 'dark' ? 'bg-secondary-light text-gray-900' : 'bg-secondary text-white'}`}
      >
        Terms of Use
      </button>
      </p>
      <button
        onClick={closeFn}
        className={`mt-4 py-2 px-4 rounded ${theme === 'dark' ? 'bg-primary-light-3 text-gray-900' : 'bg-primary text-white'}`}
      >
        Close
      </button>
    </div>
  )
}

export default DataInfo
