import React from 'react'

const IssueResponse = ({ issue }) => {
  const { issue_type, issue_claims, issue_summary, response, full_text } = issue

  return (
    <div className="border p-4 rounded-lg shadow">
      {issue_type && <h3 className="text-xl font-semibold">Issue Type: {issue_type}</h3>}
      {issue_claims && <p><strong>Claims:</strong> {issue_claims}</p>}
      {issue_summary && <p><strong>Summary:</strong> {issue_summary}</p>}
      {full_text && <p><strong>Full Text:</strong> {full_text}</p>}
      {response && <p><strong>Response:</strong> {response}</p>}
    </div>
  )
}

export default IssueResponse
