import axios from 'axios'
import config from './config'
import { encryptData, decryptData } from './security/encrypt-util'
import { Storage } from 'aws-amplify'
import { encryptArrayBuffer, readFileAsArrayBuffer } from './security/encrypt-util'
import { loadStripe } from '@stripe/stripe-js'

let BASE_URL = null

if (process.env.NODE_ENV === 'development') {
  BASE_URL = config.BASE_URL
} else {
  BASE_URL = config.PROD_URL
}

let ACCESS_OBJ = {}, storedHistory

let stripe = await loadStripe('pk_live_51HmQQxJLxKssJyu66slQWOnqolbwqzUeDxHabUfYXLylNdSUwDF3exVA4c7uN5EepIrwKPY599CDdPkZjIkIZ3JN00w35T2T3Q')
if (process.env.NODE_ENV === 'development') {
  stripe = await loadStripe('pk_test_51HmQQxJLxKssJyu6vtlQ7aN9uaEiEhLP6HJtDsbWXxYDbhpkvdgu02xGIuM4SVQ48qrGQVFK55ZujfFK4AGP1xG700XQVYcZq6')
}

const callSignUp = async(signUpData) => {
  const encryptedData = encryptData(signUpData)
  const response = await axios.post(`${BASE_URL}/user/signup`, { data: encryptedData })
  return decryptData(response.data)
}

const userLogin = async(loginData) => {
  const encryptedData = encryptData(loginData)
  const response = await axios.post(`${BASE_URL}/user/login`, { data: encryptedData })
  const decryptedResponse = decryptData(response.data)
  ACCESS_OBJ = decryptedResponse
  if (ACCESS_OBJ["id_token"]){
      localStorage.setItem('id_token', ACCESS_OBJ["id_token"])
  }
  if (ACCESS_OBJ["refresh_token"]){
      localStorage.setItem('refresh_token', ACCESS_OBJ["refresh_token"])
  }
  if (ACCESS_OBJ["access_token"]){
      localStorage.setItem('access_token', ACCESS_OBJ["access_token"])
  }
  if (ACCESS_OBJ["username"]){
//      localStorage.setItem('username', ACCESS_OBJ["username"])
  }
  return decryptedResponse
}

const confirmSignup = async(confirmationData) => {
  const encryptedData = encryptData(confirmationData)
  const response = await axios.post(`${BASE_URL}/user/confirm_signup`, { data: encryptedData })
  return decryptData(response.data)
}

const resendCode = async(username) => {
  const encryptedData = encryptData({ username })
  const response = await axios.post(`${BASE_URL}/user/resend_code`, { data: encryptedData })
  return decryptData(response.data)
}

const getPatentInfo = async(applicationNumber) => {
  const idToken = localStorage.getItem("id_token")
  const encryptedData = encryptData({ application_num: applicationNumber, id_token: idToken })
  const response = await axios.post(`${BASE_URL}/chat/patent_info`, { data: encryptedData })
  return decryptData(response.data)
}

const refreshTokens = async() => {
  try {
    const refreshToken = localStorage.getItem("refresh_token")
    const idToken = localStorage.getItem("id_token")
    const encryptedData = encryptData({ refresh_token: refreshToken, id_token: idToken })
    const response = await axios.post(`${BASE_URL}/user/refresh_tokens`, { data: encryptedData })
    const decryptedResponse = decryptData(response.data)
    if (decryptedResponse && decryptedResponse.refresh_token) {
      localStorage.setItem("refresh_token", decryptedResponse.refresh_token)
      localStorage.setItem("id_token", decryptedResponse.id_token)
      localStorage.setItem("access_token", decryptedResponse.access_token)
    }
    return decryptedResponse
  } catch (e) {
    console.error(e)
  }
}

const checkLogin = async() => {
  const idToken = localStorage.getItem('id_token')
  const refreshToken = localStorage.getItem('refresh_token')
  const username = localStorage.getItem('username')
  const encryptedData = encryptData({ id_token: idToken, refresh_token: refreshToken, username: username })
  const response = await axios.post(`${BASE_URL}/user/check_login`, { data: encryptedData })
  const decryptedResponse = decryptData(response.data)
  ACCESS_OBJ = decryptedResponse
  if (ACCESS_OBJ["id_token"]){
      localStorage.setItem('id_token', ACCESS_OBJ["id_token"])
  }
  if (ACCESS_OBJ["refresh_token"]){
      localStorage.setItem('refresh_token', ACCESS_OBJ["refresh_token"])
  }
  if (ACCESS_OBJ["access_token"]){
      localStorage.setItem('access_token', ACCESS_OBJ["access_token"])
  }
  if (ACCESS_OBJ["username"]){
//      localStorage.setItem('username', ACCESS_OBJ["username"])
  }
  return decryptedResponse
}

const changePassword = async(currentPassword, newPassword) => {
  const idToken = localStorage.getItem('id_token')
  const accessToken = localStorage.getItem('access_token')
  const username = localStorage.getItem('username')
  const encryptedData = encryptData({ id_token: idToken, access_token: accessToken, username: username, current_password: currentPassword, new_password: newPassword })
  const response = await axios.post(`${BASE_URL}/user/change_password`, { data: encryptedData })
  return decryptData(response.data)
}

const forgotPassword = async(username) => {
  const encryptedData = encryptData({ username })
  const response = await axios.post(`${BASE_URL}/user/forgot_password`, { data: encryptedData })
  return decryptData(response.data)
}

const confirmNewPassword = async(username, verificationCode, newPassword) => {
  const encryptedData = encryptData({ username, verification_code: verificationCode, new_password: newPassword })
  const response = await axios.post(`${BASE_URL}/user/confirm_new_password`, { data: encryptedData })
  return decryptData(response.data)
}

const sendSecretCode = async(username, secretCode) => {
  const encryptedData = encryptData({ username, secret_code: secretCode })
  const response = await axios.post(`${BASE_URL}/user/secret_code`, { data: encryptedData })
  return decryptData(response.data)
}

const delete_the_storage = () => {
  const keysToDelete = [
    'id_token',
    'refresh_token',
    'access_token',
    'username',
    'chat_name',
    'chat_context',
    'patents',
    'history',
    'last_context_number',
    'last_context_type',
    'new_resource_names',
    'curlinput',
    'resourceType',
    'is_saved',
    'term_dict',
    'resourceNumber',
    'theme',
    'is_student',
    'search_results',
    'storedIsSaved',
    'new_resource_ids',
    'messages',
    'drawing-summary-extract-jobs'
  ]
  keysToDelete.forEach(key => {
    localStorage.removeItem(key)
  })
}

const delete_the_storage_but_not_auth_ids = () => {
  const keysToDelete = [
    'chat_name',
    'chat_context',
    'patents',
    'history',
    'last_context_number',
    'last_context_type',
    'new_resource_names',
    'curlinput',
    'resourceType',
    'is_saved',
    'term_dict',
    'resourceNumber',
    'theme',
    'search_results',
    'storedIsSaved',
    'new_resource_ids',
    'messages',
    'drawing-summary-extract-jobs'
  ]
  keysToDelete.forEach(key => {
    localStorage.removeItem(key)
  })
}

const logout = async(delete_storage) => {
  const access_token = localStorage.getItem('access_token')
  if (delete_storage) {
    delete_the_storage()
  }
  const encryptedData = encryptData({ access_token })
  const response = await axios.post(`${BASE_URL}/user/logout`, { data: encryptedData })
  window.location.reload()
  return decryptData(response.data)
}

const getPatents = () => {
  try {
    const patents = localStorage.getItem('patents')
    return JSON.parse(patents)
  } catch (error) {
    console.log("error: " + error)
    return {}
  }
}

const getContext = () => {
  try {
    const context = localStorage.getItem('chat_context')
    return JSON.parse(context)
  } catch (error) {
    console.log("error: " + error)
    return {}
  }
}

const runCustomAnalysis = async(selectedNumber, resourceType, text) => {
  try {
    const encryptedData = encryptData({ selected_number: selectedNumber, resource_type: resourceType, user_prompt: text })
    const response = await axios.post(`${BASE_URL}/task/custom_analysis`, { data: encryptedData }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return decryptData(response.data)
  } catch (e) {
    console.error(e)
  }
}

const getCustomAnalysisResult = async(jobId) => {
  try {
    const encryptedData = encryptData({ job_id: jobId })
    const response = await axios.post(`${BASE_URL}/task/get_analysis`, { data: encryptedData }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return decryptData(response.data)
  } catch (e) {
    console.error(e)
  }
}

const refreshDoc = async(docId, docType) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, doc_id: docId, doc_type: docType })
    const response = await axios.post(`${BASE_URL}/doc/refresh_doc`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.log("error: " + error)
    return {}
  }
}

const sendMessage = async(message, history, userInfo) => {
  try {
    localStorage.setItem('history', JSON.stringify([...history, { "role": "user", "content": message }]))
    const new_resource_ids = localStorage.getItem('new_resource_ids')
    const new_resource_names = localStorage.getItem('new_resource_names')
    const idToken = localStorage.getItem('id_token')
    const context = getContext()
    const encryptedData = encryptData({ message, history, userInfo, id_token: idToken, chat_context: context, new_resource_ids, new_resource_names })
    const response = await axios.post(`${BASE_URL}/chat/message`, { data: encryptedData }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const decryptedResponse = decryptData(response.data)
    if (decryptedResponse && decryptedResponse['chat_context']) {
      localStorage.setItem('chat_context', JSON.stringify(decryptedResponse['chat_context']))
      window.dispatchEvent(new Event('storage-update'))
    }
    if (decryptedResponse && decryptedResponse['history']) {
      localStorage.setItem('history', JSON.stringify(decryptedResponse['history']))
    }
    return decryptedResponse
  } catch (error) {
    if (error.response && error.response.status === 403) {
      logout(false)
    }
    throw error
  }
}

const getMessage = async(messageId, storeReceivedMessageIntoHistory) => {
  const idToken = localStorage.getItem("id_token")
  const encryptedData = encryptData({ message_id: messageId, id_token: idToken })
  const response = await axios.post(`${BASE_URL}/chat/get_message`, { data: encryptedData }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  let decryptedResponse = ""
  if (response.data !== "" && response.data.length > 0) {
    decryptedResponse = decryptData(response.data)
  }
  if (storeReceivedMessageIntoHistory && decryptedResponse && decryptedResponse['message']) {
    storedHistory = localStorage.getItem('history', "")
    if (storedHistory && storedHistory !== "") {
      localStorage.setItem('history', JSON.stringify([...JSON.parse(storedHistory), { "role": "assistant", "content": decryptedResponse['message'] }]))
    }
  }
  return decryptedResponse
}

const fetchTermMap = async(messageId) => {
  const idToken = localStorage.get("id_token")
  const encryptedData = encryptData({ message_id: messageId, id_token: idToken })
  const response = await axios.post(`${BASE_URL}/doc/get_term_dict`, { data: encryptedData }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return decryptData(response.data)
}

const getTermMap = async(patentNumber, applicationNumber, resourceType) => {
  const encryptedData = encryptData({ patent_number: patentNumber, application_number: applicationNumber, resource_type: resourceType })
  const response = await axios.post(`${BASE_URL}/task/term_map`, { data: encryptedData }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return decryptData(response.data)
}

const analyzeSpec = async(patentNumber, applicationNumber, resourceType) => {
  const encryptedData = encryptData({ patent_number: patentNumber, application_number: applicationNumber, resource_type: resourceType })
  const response = await axios.post(`${BASE_URL}/task/analyze_spec_for_context`, { data: encryptedData }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return decryptData(response.data)
}

const deepClaimTermReview = async(patentNumber, applicationNumber, resourceType) => {
  const encryptedData = encryptData({ patent_number: patentNumber, application_number: applicationNumber, resource_type: resourceType })
  const response = await axios.post(`${BASE_URL}/task/deep_claim_term_review`, { data: encryptedData }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return decryptData(response.data)
}

const summarizeAndCharacterize = async(docId, resourceName, resourceType) => {
  const idToken = localStorage.getItem('id_token')
  const encryptedData = encryptData({ doc_id: docId, resource_name: resourceName, resource_type: resourceType, id_token: idToken })
  const response = await axios.post(`${BASE_URL}/task/summarize_and_characterize`, { data: encryptedData }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return decryptData(response.data)
}

const performRag = async (query, useVicuna, clusterId) => {
  const encryptedData = encryptData({ query, useVicuna, clusterId })
  const response = await axios.post(`${BASE_URL}/chat/perform_rag`, { data: encryptedData })
  return decryptData(response.data)
}

const sendMessageFeedback = async (feedbackMessage, isPositive) => {
  const idToken = localStorage.getItem('id_token')
  let feedback = {
    "feedback_message": feedbackMessage,
    "id_token": idToken,
  }
  const keys = ["chat_name", "chat_id", "search_results", "curInput", "patents", "chat_context", "history"]
  keys.forEach(key => {
    const item = localStorage.getItem(key)
    if (item !== null || item !== "") {
      if (key === "curInput") {
        feedback["cur_input"] = item
      } else {
        feedback[key] = item
      }
    }
  })
  feedback["is_positive"] = isPositive
  const encryptedData = encryptData(feedback)
  const response = await axios.post(`${BASE_URL}/chat/feedback`, { data: encryptedData })
  return decryptData(response.data)
}

const sendFeedback = async (feedbackMessage) => {
  const idToken = localStorage.getItem('id_token')
  let feedback = {
    "feedback_message": feedbackMessage,
    "id_token": idToken,
  }
  const keys = ["chat_name", "chat_id", "search_results", "curInput", "patents", "chat_context", "history"]
  keys.forEach(key => {
    const item = localStorage.getItem(key)
    if (item !== null || item !== "") {
      if (key === "curInput") {
        feedback["cur_input"] = item
      } else {
        feedback[key] = item
      }
    }
  })
  const encryptedData = encryptData(feedback)
  const response = await axios.post(`${BASE_URL}/chat/feedback`, { data: encryptedData })
  return decryptData(response.data)
}

const searchPatents = async (query) => {
  const encryptedData = encryptData({ query })
  const response = await axios.post(`${BASE_URL}/chat/search`, { data: encryptedData })
  return decryptData(response.data)
}

const performHybridSearch = async (query, clusterId) => {
  const encryptedData = encryptData({ query, clusterId })
  const response = await axios.post(`${BASE_URL}/chat/perform_hybrid_search`, { data: encryptedData })
  return decryptData(response.data)
}

const getJobStatus = async (query) => {
  const encryptedData = encryptData({ query })
  const response = await axios.post(`${BASE_URL}/task/job_status`, { data: encryptedData })
  return decryptData(response.data)
}

const extractKnowledgeFromFile = async (query) => {
  try {
    const encryptedData = encryptData(query)
    const response = await axios.post(`${BASE_URL}/knowledge/extract_knowledge_from_file`, encryptedData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return decryptData(response.data)
  } catch (error) {
    console.error('Error uploading file: ', error)
  }
  return ''
}

const extractKnowledgeFromUrl = async (query) => {
  const encryptedData = encryptData({ query })
  const response = await axios.post(`${BASE_URL}/knowledge/extract_knowledge_from_url`, { data: encryptedData })
  return decryptData(response.data)
}

const deleteEmbeddings = async () => {
  const encryptedData = encryptData({})
  const response = await axios.post(`${BASE_URL}/knowledge/delete_embeddings`, { data: encryptedData })
  return decryptData(response.data)
}

const fetchClusters = async () => {
  const encryptedData = encryptData({})
  const response = await axios.get(`${BASE_URL}/knowledge/fetch_clusters`, { data: encryptedData })
  return decryptData(response.data)
}

const addCluster = async (cluster) => {
  const encryptedData = encryptData({ cluster })
  const response = await axios.post(`${BASE_URL}/knowledge/add_cluster`, { data: encryptedData })
  return decryptData(response.data)
}

const deleteCluster = async (cluster_id) => {
  const encryptedData = encryptData({ cluster_id })
  const response = await axios.post(`${BASE_URL}/knowledge/delete_cluster`, { data: encryptedData })
  return decryptData(response.data)
}

const saveChat = async (chatData) => {
  try {
    const idToken = localStorage.getItem('id_token')
    chatData['id_token'] = idToken
    chatData['chat_context'] = getContext()
    chatData['patents'] = getPatents()
    const encryptedData = encryptData(chatData)
    const response = await axios.post(`${BASE_URL}/chat/save_chat`, { data: encryptedData }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    return decryptData(response.data)
  } catch (error) {
    console.error("Error adding new chat:", error)
  }
}

const grantUserAccessToChat = async (chatId, masterUsername, targetUsername, permissions) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ chat_id: chatId, master_username: masterUsername, target_username: targetUsername, permissions, id_token: idToken })
    const response = await axios.post(`${BASE_URL}/chat/grant_user_access`, { data: encryptedData }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    return decryptData(response.data)
  } catch (error) {
    console.error("Error sharing chat:", error)
  }
}

const removeAccessFromChat = async (chatId, masterUsername, targetUsername, permissions) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ chat_id: chatId, master_username: masterUsername, target_username: targetUsername, permissions, id_token: idToken })
    const response = await axios.post(`${BASE_URL}/chat/remove_user_access`, { data: encryptedData }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    return decryptData(response.data)
  } catch (error) {
    console.error("Error removing access chat:", error)
  }
}

const uploadFiles = async (query) => {
  try {
    const idToken = localStorage.getItem('id_token')
    query.append('id_token', idToken)

    let chat_context = getContext()
    query.append('chat_context', JSON.stringify(chat_context))
    const response = await axios.post(`${BASE_URL}/doc/upload_files`, query, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    if (response && response.data && response.data['chat_context']){
        localStorage.setItem('chat_context', JSON.stringify(response.data['chat_context']))
        window.dispatchEvent(new Event('storage-update'))
    }
    if (response && response.data && response.data['resource_ids'] && response.data['resource_names']){
      localStorage.setItem('new_resource_ids', response.data['resource_ids'] )
      localStorage.setItem('new_resource_names', response.data['resource_names'])
    }
    return response.data
  } catch (error) {
    console.error('Error uploading file: ', error)
    return 'error'
  }
  return ''
}

const addNewChat = async (chatData) => {
  try {
    const idToken = localStorage.getItem('id_token')
    chatData['id_token'] = idToken
    chatData['chat_context'] = getContext()
    chatData['patents'] = getPatents()
    const encryptedData = encryptData(chatData)
    const response = await axios.post(`${BASE_URL}/chat/add_chat`, { data: encryptedData }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    return decryptData(response.data)
  } catch (error) {
    console.error("Error adding new chat:", error)
  }
}

const fetchSpecInfo = async (doc_id, doc_type) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, doc_id: doc_id, doc_type: doc_type })
    const response = await axios.post(`${BASE_URL}/doc/spec_info`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to fetchSpecInfo:", error)
    throw error
  }
}

const getSummary = async (doc_id, doc_type) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, doc_id: doc_id, doc_type: doc_type })
    const response = await axios.post(`${BASE_URL}/doc/summary`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to getSummary:", error)
    throw error
  }
}

const genSummary = async (doc_id, doc_type) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, doc_id: doc_id, doc_type: doc_type })
    const response = await axios.post(`${BASE_URL}/doc/gen_summary`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to genSummary:", error)
    throw error
  }
}

const getParaSummary = async (text, doc_type) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, text, doc_type })
    const response = await axios.post(`${BASE_URL}/doc/custom_summary`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to getParaSummary:", error)
    throw error
  }
}

const getCustomQuestionHistory = async (doc_id, doc_type) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, doc_id: doc_id, doc_type: doc_type })
    const response = await axios.post(`${BASE_URL}/doc/custom_question_history`, { data: encryptedData })
    const decryptedResponse = decryptData(response.data)
    if (Array.isArray(decryptedResponse)) {
      return decryptedResponse
    }
    return []
  } catch (error) {
    console.error("Failed to getCustomQuestionHistory:", error)
    throw error
  }
}

const customQuestion = async (text, doc_type, doc_id) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, text, doc_id, doc_type })
    const response = await axios.post(`${BASE_URL}/doc/custom_question`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to customQuestion:", error)
    throw error
  }
}

const customQuestions = async (text, doc_objs) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, text, objects: doc_objs })
    const response = await axios.post(`${BASE_URL}/doc/custom_questions`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to customQuestions:", error)
    throw error
  }
}

const getTermListAndDefinitions = async (doc_id, doc_type, refresh) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, doc_id, doc_type, refresh })
    const response = await axios.post(`${BASE_URL}/doc/term_list_and_definitions`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to getTermListAndDefinitions:", error)
    throw error
  }
}

const getIssueResponses = async (app_no) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, app_no })
    const response = await axios.post(`${BASE_URL}/task/issue_responses`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to getOperationResponse:", error)
    throw error
  }
}

const getIssues = async (app_no) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, app_no })
    const response = await axios.post(`${BASE_URL}/task/issues`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to getOperationResponse:", error)
    throw error
  }
}

const generateResponseForIssue = async (app_no, issue_id, customInstruction) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, app_no, issue_id, instruction: customInstruction })
    const response = await axios.post(`${BASE_URL}/task/gen_issue_response`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to generateResponseForIssue:", error)
    throw error
  }
}

const getResponseForIssue = async (app_no, issue_id) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, app_no, issue_id })
    const response = await axios.post(`${BASE_URL}/task/get_issue_response`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to getResponseForIssue:", error)
    throw error
  }
}

const getProsHist = async (app_no) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, app_no })
    const response = await axios.post(`${BASE_URL}/task/pros_hist`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to getProsHist:", error)
    throw error
  }
}

const getSpecMap = async (app_no) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, app_no })
    const response = await axios.post(`${BASE_URL}/task/spec_map`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to getSpecMap:", error)
    throw error
  }
}

const getPartsList = async (app_no) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, app_no })
    const response = await axios.post(`${BASE_URL}/task/parts_list`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to getPartsList:", error)
    throw error
  }
}

const getCustTermListAndDefinitions = async (doc_id, doc_type, terms) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, doc_id, doc_type, terms })
    const response = await axios.post(`${BASE_URL}/doc/cust_term_list_and_definitions`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to getCustTermListAndDefinitions:", error)
    throw error
  }
}

const deleteTermFromList = async (doc_id, doc_type, term_index) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, doc_id, doc_type, term_index })
    const response = await axios.post(`${BASE_URL}/doc/delete_term`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to deleteTermFromList:", error)
    throw error
  }
}

const lookForCites = async (resource_id, resource_type, current_cites) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, resource_id, resource_type, current_cites })
    const response = await axios.post(`${BASE_URL}/doc/look_for_cites`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to lookForCites:", error)
    throw error
  }
}

const getCites = async (resource_id, resource_type, current_cites) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, resource_id, resource_type, current_cites })
    const response = await axios.post(`${BASE_URL}/doc/get_cites`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to getCites:", error)
    throw error
  }
}

const genDrawingSummaries = async (resource_id, resource_type) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, resource_id, resource_type })
    const response = await axios.post(`${BASE_URL}/doc/gen_drawing_summaries`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to genDrawingSummaries:", error)
    throw error
  }
}

const getDrawingSummaries = async (resource_id, resource_type) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, resource_id, resource_type })
    const response = await axios.post(`${BASE_URL}/doc/get_drawing_summaries`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to getDrawingSummaries:", error)
    throw error
  }
}

const addDrawingComponent = async (resource_id, resource_type, component_id, figure, sheet) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, resource_id, resource_type, component_id, figure, sheet })
    const response = await axios.post(`${BASE_URL}/doc/add_drawing_component`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to genDrawingSummaries:", error)
    throw error
  }
}

const removeDrawingComponent = async (resource_id, resource_type, component_id, name, figure, sheet) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, resource_id, resource_type, component_id, name, figure, sheet })
    const response = await axios.post(`${BASE_URL}/doc/delete_drawing_component`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to genDrawingSummaries:", error)
    throw error
  }
}

const deleteDocument = async (doc_type, doc_id) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, doc_type, doc_id })
    const response = await axios.post(`${BASE_URL}/doc/delete_doc`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to deleteDocument:", error)
    throw error
  }
}

const fetchDocument = async (doc_type, doc_id) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, doc_type, doc_id })
    const response = await axios.post(`${BASE_URL}/doc/doc`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to fetchDocument:", error)
    throw error
  }
}

const fetchAndLoadDocument = async (doc_type, doc_id) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, doc_type, doc_id })
    const response = await axios.post(`${BASE_URL}/doc/fetch_and_load_doc`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to fetchDocument:", error)
    throw error
  }
}

const fetchChats = async () => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken })
    const response = await axios.post(`${BASE_URL}/chat/get_all_chats`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to fetch chats:", error)
    throw error
  }
}

const fetchSettings = async () => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken })
    const response = await axios.post(`${BASE_URL}/user/fetch_settings`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to fetch settings:", error)
    throw error
  }
}

const fetchUsage = async () => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken })
    const response = await axios.post(`${BASE_URL}/user/fetch_usage`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to fetch usage:", error)
    throw error
  }
}

const updateSettings = async (userSettings) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, user_settings: userSettings })
    const response = await axios.post(`${BASE_URL}/user/update_settings`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to update settings:", error)
    throw error
  }
}

const deleteExistingChat = async (chat_id) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, chat_id })
    const response = await axios.post(`${BASE_URL}/chat/delete_chat`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to delete chat:" + chat_id, error)
    throw error
  }
}

const fetchFolders = async () => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken })
    const response = await axios.post(`${BASE_URL}/chat/get_all_folders`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to fetch chats:", error)
    throw error
  }
}

const createFolder = async (folder_name) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, folder_name })
    const response = await axios.post(`${BASE_URL}/chat/create_folder`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to create_folder:" + folder_name, error)
    throw error
  }
}

const deleteFolder = async (folder_id) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, folder_id })
    const response = await axios.post(`${BASE_URL}/chat/delete_folder`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to create_folder:" + folder_id, error)
    throw error
  }
}

const addChatToFolder = async (chat_id, folder_id) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, chat_id, folder_id })
    const response = await axios.post(`${BASE_URL}/chat/add_chat_to_folder`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to create_folder:" + folder_id, error)
    throw error
  }
}

const moveChatToFolder = async (old_folder_id, new_folder_id, chat_id) => {
  try {
    const idToken = localStorage.getItem('id_token')
    const encryptedData = encryptData({ id_token: idToken, old_folder_id, new_folder_id, chat_id })
    const response = await axios.post(`${BASE_URL}/chat/move_chat_to_folder`, { data: encryptedData })
    return decryptData(response.data)
  } catch (error) {
    console.error("Failed to move chat (" + chat_id + ") to folder:" + old_folder_id + " -> " + new_folder_id, error)
    throw error
  }
}

const goToPaymentPage = async (username) => {
  try {
    const response = await axios.post(`${BASE_URL}/stripe/create-checkout-session`, { username })
    const sessionId = decryptData(response.data).id
    const { error } = await stripe.redirectToCheckout({ sessionId })
    if (error) {
      console.error(error)
    }
  } catch (error) {
    console.error("Failed to get payment session", error)
    throw error
  }
}

const setPaymentSuccessful = async (pKey) => {
  try {
    const response = await axios.post(`${BASE_URL}/stripe/set-payment-successful`, { payment_key: pKey })
    return response.data
  } catch (error) {
    console.error("Failed to get payment session", error)
    throw error
  }
}

export {
  BASE_URL,

  // user
  callSignUp,
  confirmSignup,
  resendCode,
  userLogin,
  checkLogin,
  changePassword,
  forgotPassword,
  confirmNewPassword,
  sendSecretCode,
  refreshTokens,
  logout,
  fetchSettings,
  updateSettings,
  fetchUsage,

  // doc
  refreshDoc,
  fetchTermMap,
  uploadFiles,
  fetchDocument,
  fetchAndLoadDocument,
  fetchSpecInfo,
  getSummary,
  genSummary,
  getParaSummary,
  getCustomQuestionHistory,
  customQuestion,
  customQuestions,
  getTermListAndDefinitions,
  getCustTermListAndDefinitions,
  deleteTermFromList,
  lookForCites,
  getCites,
  genDrawingSummaries,
  getDrawingSummaries,
  addDrawingComponent,
  removeDrawingComponent,
  deleteDocument,

  // task
  getIssueResponses,
  getIssues,
  generateResponseForIssue,
  getResponseForIssue,
  getProsHist,
  getSpecMap,
  getPartsList,
  runCustomAnalysis,
  getCustomAnalysisResult,
  getTermMap,
  analyzeSpec,
  deepClaimTermReview,
  summarizeAndCharacterize,
  getJobStatus,

  // chat
  getPatentInfo,
  sendMessage,
  getMessage,
  searchPatents,
  sendFeedback,
  sendMessageFeedback,
  addNewChat,
  grantUserAccessToChat,
  removeAccessFromChat,
  saveChat,
  fetchChats,
  deleteExistingChat,
  fetchFolders,
  createFolder,
  deleteFolder,
  addChatToFolder,
  moveChatToFolder,

  // payment
  goToPaymentPage,
  setPaymentSuccessful,

  // deletes local storage
  delete_the_storage,
  delete_the_storage_but_not_auth_ids,
}
