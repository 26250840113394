import React, { useState } from 'react';

const base_url = "https://ped.uspto.gov/api/queries/cms/pdfDocument";

const ExpandableSection = ({ title, children, count }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="mb-6">
      <h2 className="text-xl font-semibold cursor-pointer flex justify-between items-center" onClick={() => setExpanded(!expanded)}>
        <span>{title} ({count})</span>
        <span>{expanded ? '▲' : '▼'}</span>
      </h2>
      {expanded && <div className="mt-2 border-t border-gray-300 pt-2">{children}</div>}
    </div>
  );
};

const createDocumentLink = (app_no, reference) => {
  return `${base_url}/${app_no}/${reference}`;
};

const OfficeAction = ({ action, application_id, responses }) => (
  <div className="py-3 border-b border-gray-200">
    <p className="text-sm"><strong>Date:</strong> {action.date}</p>
    <p className="text-sm"><strong>Description:</strong> {action.description}</p>
    <p className="text-sm"><strong>Document ID:</strong> {action.document_id}</p>
    <p className="text-sm"><strong>Document Link:</strong> <a target="_blank" rel="noopener noreferrer" href={createDocumentLink(application_id, action.id)} className="text-blue-600 hover:underline">{action.id}</a></p>
    {responses && responses.map((response, rindex) => (
      <ResponseToAction key={rindex} response={response} application_id={application_id} />
    ))}
  </div>
);

const ResponseToAction = ({ response, application_id }) => (
  <div className="border-l-4 border-blue-300 pl-4 ml-4 mt-2 bg-gray-50 p-2 rounded-md shadow-sm">
    <p className="text-sm"><strong>Response Date:</strong> {response.date}</p>
    <p className="text-sm"><strong>Description:</strong> {response.description}</p>
    <p className="text-sm"><strong>Document ID:</strong> {response.document_id}</p>
    <p className="text-sm"><strong>Document Link:</strong> <a target="_blank" rel="noopener noreferrer" href={createDocumentLink(application_id, response.id)} className="text-blue-600 hover:underline">PDF</a></p>
    {response.amendments && (
      <div>
        <p className="text-sm"><strong>Amendments:</strong></p>
        {response.amendments.map((amendment, index) => (
          <div key={index} className="ml-4 text-sm">
            <p>- {amendment.type.charAt(0).toUpperCase() + amendment.type.slice(1)} Amendment - <a target="_blank" rel="noopener noreferrer" href={createDocumentLink(application_id, amendment.id)} className="text-blue-600 hover:underline">PDF</a></p>
          </div>
        ))}
      </div>
    )}
    {response.remarks && (
      <div>
        <p className="text-sm"><strong>Remarks:</strong></p>
        {response.remarks.map((remark, index) => (
          <div key={index} className="ml-4 text-sm">
            <p>- {remark.description}</p>
          </div>
        ))}
      </div>
    )}
  </div>
);

const PatentProsHist = ({ data }) => {
  const getCount = (obj) => {
    return obj ? Object.values(obj).reduce((sum, arr) => sum + (Array.isArray(arr) ? arr.length : 0), 0) : 0;
  };

  const groupResponsesWithLastAction = (actions, responses) => {
    const groupedActions = actions.map(action => ({ ...action, responses: [] }));
    responses.forEach(response => {
      const responseDate = new Date(response.date);
      let lastAction = null;
      for (let i = groupedActions.length - 1; i >= 0; i--) {
        const actionDate = new Date(groupedActions[i].date);
        if (actionDate < responseDate) {
          lastAction = groupedActions[i];
          break;
        }
      }
      if (lastAction) {
        lastAction.responses.push(response);
      }
    });

    return groupedActions;
  };

  const officeActions = [
    ...(data.office_actions.non_final || []).map(action => ({ ...action, type: 'Non-Final' })),
    ...(data.office_actions.final || []).map(action => ({ ...action, type: 'Final' })),
    ...(data.office_actions.restriction || []).map(action => ({ ...action, type: 'Restriction' })),
    ...(data.office_actions.notice_of_allowance || []).map(action => ({ ...action, type: 'Notice of Allowance' })),
  ].sort((a, b) => new Date(a.date) - new Date(b.date));

  const groupedOfficeActions = groupResponsesWithLastAction(officeActions, data.responses || []);

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-4">Patent Application Summary</h1>
      <div className="mb-6">
        <h2 className="text-lg font-semibold">Basic Information</h2>
        <p className="text-sm"><strong>Application ID:</strong> {data.application_id}</p>
        <p className="text-sm"><strong>Country:</strong> {data.country}</p>
        <p className="text-sm"><strong>Type:</strong> {data.type}</p>
        <p className="text-sm"><strong>Status:</strong> {data.status}</p>
        <p className="text-sm"><strong>Filing Date:</strong> {data.filing_date}</p>
        <p className="text-sm"><strong>Patent Number:</strong> {data.patent_number}</p>
        <p className="text-sm"><strong>Patent Title:</strong> {data.patent_title}</p>
        <p className="text-sm"><strong>Inventors:</strong> {Array.isArray(data.inventors) ? data.inventors.join(', ') : 'N/A'}</p>
      </div>

      <ExpandableSection title="Office Actions" count={getCount(data.office_actions)}>
        {groupedOfficeActions.length > 0 ? (
          groupedOfficeActions.map((action, index) => (
            <OfficeAction key={index} action={action} application_id={data.application_id} responses={action.responses} />
          ))
        ) : (
          <p className="text-gray-500">No office actions</p>
        )}
      </ExpandableSection>

      <ExpandableSection title="Assignments" count={data.assignments ? data.assignments.length : 0}>
        {data.assignments && data.assignments.length > 0 ? (
          data.assignments.map((assignment, index) => (
            <div key={index} className="border-t border-gray-200 py-3 text-sm">
              <p><strong>Recorded Date:</strong> {assignment.recorded_date}</p>
              <p><strong>Assignor Names:</strong> {Array.isArray(assignment.assignor_names) ? assignment.assignor_names.join(', ') : 'N/A'}</p>
              <p><strong>Assignee Name:</strong> {assignment.assignee_name}</p>
              <p><strong>Assignee Address:</strong> {assignment.assignee_address}</p>
              <p><strong>Document ID:</strong> {assignment.document_id}</p>
              <p><strong>Document Link:</strong> <a target="_blank" rel="noopener noreferrer" href={createDocumentLink(data.application_id, assignment.id)} className="text-blue-600 hover:underline">{assignment.id}</a></p>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No assignments</p>
        )}
      </ExpandableSection>

      <ExpandableSection title="Transactions" count={data.transactions ? data.transactions.length : 0}>
        {data.transactions && data.transactions.length > 0 ? (
          data.transactions.map((transaction, index) => (
            <div key={index} className="border-t border-gray-200 py-3 text-sm">
              <p><strong>Date:</strong> {transaction.date}</p>
              <p><strong>Description:</strong> {transaction.description}</p>
              <p><strong>Code:</strong> {transaction.code}</p>
              <p><strong>Document Link:</strong> <a target="_blank" rel="noopener noreferrer" href={createDocumentLink(data.application_id, transaction.id)} className="text-blue-600 hover:underline">{transaction.id}</a></p>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No transactions</p>
        )}
      </ExpandableSection>

      <ExpandableSection title="Important Documents" count={data.important_documents ? data.important_documents.length : 0}>
        {data.important_documents && data.important_documents.length > 0 ? (
          data.important_documents.map((doc, index) => (
            <div key={index} className="border-t border-gray-200 py-3 text-sm">
              <p><strong>Date:</strong> {doc.date}</p>
              <p><strong>Description:</strong> {doc.description}</p>
              <p><strong>Document ID:</strong> {doc.document_id}</p>
              <p><strong>Document Link:</strong> <a target="_blank" rel="noopener noreferrer" href={createDocumentLink(data.application_id, doc.id)} className="text-blue-600 hover:underline">{doc.id}</a></p>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No important documents</p>
        )}
      </ExpandableSection>

      <div>
        <h2 className="text-lg font-semibold mt-6">Summary Statement</h2>
        <p className="text-sm mt-2">{data.summary_statement}</p>
      </div>
    </div>
  );
};

export default PatentProsHist;
