import React, { useState, useContext } from 'react'
import QuickStartGuide from '../info/QuickStartGuide'
import ThemeContext from '../context/ThemeContext'
import CopyIcon from '../component/CopyIcon'

const About = () => {
  const [showAboutPopup, setShowAboutPopup] = useState(false)
  const [toastVisible, setToastVisible] = useState(false)
  const [showQuickStartGuide, setShowQuickStartGuide] = useState(false)
  const { theme } = useContext(ThemeContext)
  const copyToClipboard = () => {
    navigator.clipboard.writeText("info@forgen.ai")
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 3000)
  }
  return (
    <div className='flex items-center justify-center w-64'>
        <div className='inline-flex justify-center p-2 border rounded shadow bg-ternary w-64'>
          <div className="inline">
            <button  onClick={() => setShowAboutPopup(true)} className="bg-primary text-white hover:text-primary font-semibold py-2 px-4 rounded hover:bg-white transition duration-300">
              Learn More about Features, Pricing, Tech.
             </button>
          </div>
        </div>
      {showQuickStartGuide && (
        <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <QuickStartGuide closeQuickGuide={()=> setShowQuickStartGuide(false)} addTextToMessageInput={(text) => console.log(`Add this text to message input: ${text}`)} />
        </div>
      )}
      {showAboutPopup && !showQuickStartGuide && (
        <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-4 bg-white rounded-lg shadow-md max-w-[800px] max-w-[calc(100vw-200px)] w-auto">
            <p className="mb-1"><b>Patents.chat</b> is a chatbot powered by OpenAI's GPT-4 and tuned for patents by U.S. patent professionals.</p>
            Our platform offers a generative AI platform, centered around a chatbot, which offers quick, useful insight into patent documents and other technical documents.  Currently, with about 55,000 lines of code, Patents.chat is built on newly-developed, retrieval augmentation techniques for technical document processing, developed by and licensed from ForGen AI.
            <div className="w-full text-center p-1 italic text-sm text-gray-800">patent pending</div>
            <button className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5" onClick={() => setShowQuickStartGuide(true)}>View Quick Start Guide</button>
            <p>Email us at <b>info@forgen.ai</b>
            {toastVisible ? (
              <span className="ml-2 bg-green-300 text-white p-1 rounded">
                Copied!
              </span>
            ) : (
              <button
                onClick={copyToClipboard}
                className="ml-1 p-1 hover:bg-blue-700 rounded transition duration-150 ease-in-out"
              >
                <CopyIcon />
              </button>
              )} for more information
            </p>
            <div className="text-center w-full">
              <button
                onClick={() => setShowAboutPopup(false)}
                className="bg-red-400 px-6 hover:bg-red-500 text-white p-2 rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default About
