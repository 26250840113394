import React from 'react'

const PDFTextViewer = ({ data }) => {
  // Define the line numbers to ignore
  const ignoredLineNumbers = ["5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65"]

  // Group the data by page and normalized column for easier rendering
  const pages = data.reduce((acc, item) => {
    // Normalize column number to always be 1 or 2 across all pages
    const normalizedColumn = ((item.column - 1) % 2) + 1

    // Initialize page object if it doesn't exist
    if (!acc[item.page]) {
      acc[item.page] = { 1: [], 2: [] }
    }
    // Filter out line number indicators and push the item to the correct column
    if (!ignoredLineNumbers.includes(item.text.trim())) {
      acc[item.page][normalizedColumn].push(item)
    }
    return acc
  }, {})

  return (
    <div>
      {Object.entries(pages).map(([pageNumber, columns]) => (
        <div key={pageNumber} className="page" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          {Object.entries(columns).map(([columnNumber, items]) => (
            <div key={columnNumber} className="column" style={{ flex: 1, marginRight: columnNumber === "1" ? '10px' : '0', marginLeft: columnNumber === "2" ? '10px' : '0' }}>
              {items.filter(item => item.desc_page !== 0).map((item, index) => (
                <div key={index} style={{ margin: '10px 0' }}>
                  <p><span className='text-xs text-gray-400'>{item.line_number} </span> {item.text}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default PDFTextViewer
