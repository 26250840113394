import React, { useState, useContext } from 'react'
import { getTermMap, deepClaimTermReview, analyzeSpec } from '../api'
import { removeCountryAndKindCodes, formatApplicationNumber, formatPubNumber, formatNumberWithCommas } from '../util/patent_util'
import styles from '../ColorCycle.module.css'
import ResourceProcessingStatus from '../component/ResourceProcessingStatus'
import CustomAnalysis from '../op/CustomAnalysis'
import TermMap from '../op/TermMap'
import RedX from '../component/RedX'
import ThemeContext from '../context/ThemeContext'

const mapping = {
    "applId": "Application Identifier",
    "appFilingDate": "Application Filing Date",
    "appEarlyPubDate": "Early Publication Date",
    "appEarlyPubNumber": "Early Publication Number",
    "appEntityStatus": "Application Entity Status",
    "appExamName": "Patent Examiner Name",
    "appGrpArtNumber": "Group Art Number",
    "appLocation": "Application Location",
    "appStatus": "Application Status",
    "appType": "Application Type",
    "applicants": "Applicants",
    "assignments": "Assignments",
    "attrnyAddr": "Attorney Address",
    "firstInventorFile": "First Inventor to File",
    "id": "Unique Identifier",
    "inventorName": "Inventor Name",
    "inventors": "Inventors",
    "lastUpdatedTimestamp": "Last Updated Timestamp",
    "parentContinuity": "Parent Continuity",
    "patentIssueDate": "Patent Issue Date",
    "patentNumber": "Patent Number",
    "patentTitle": "Patent Title"
}

function PatentInfoComponent({ patentInfo, patentJobId, pubJobId, onRemove, setChatMessage, updateJobId, onDocClick }) {
  const [resource, setResource] = useState({})
  const [getDeepDivePatentTerms, setGetDeepDivePatentTerms] = useState(false)
  const [getDeepDivePublicationTerms, setGetDeepDivePublicationTerms] = useState(false)
  const [showCustomAnalysis, setShowCustomAnalysis] = useState(false)
  const [patInfo, setPatInfo] = useState({})
  const [isPatTermMapVisible, setIsPatTermMapVisible] = useState(false)
  const [patTermMapData, setPatTermMapData] = useState({})
  const [showPatTermMapButton, setShowPatTermMapButton] = useState(false)
  const [isPubTermMapVisible, setIsPubTermMapVisible] = useState(false)
  const [pubTermMapData, setPubTermMapData] = useState({})
  const [showPubTermMapButton, setShowPubTermMapButton] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const { theme, setTheme } = useContext(ThemeContext)

  function lookupKey(rawKey) {

    return mapping[rawKey] || rawKey  // returns the friendly name if available, else returns the raw key
  }

  function renderValue(value) {
      if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
          return JSON.stringify(value)
      }
      return value || 'N/A'
  }

  function addSlashAndComma(num) {
      const str = num.toString()

      // Insert slash after the second character and comma after the fifth character
      return `${str.substring(0, 2)}/${str.substring(2, 5)},${str.substring(5)}`
  }

  function extractPatentComponents(patentStr) {
      const regex = /^([A-Z]{2})(\d+)([A-Z]\d)$/
      const match = patentStr.match(regex)

      if (match) {
          return {
              countryCode: match[1],
              number: match[2],
              kindCode: match[3]
          }
      } else {
          return null
      }
  }

  const analyzeSpecForContext = (patentNumber, applicationNumber, resourceType) => {
    if (resourceType == "patent") {
      setGetDeepDivePatentTerms(true)
      setGetDeepDivePublicationTerms(false)
    } else if (resourceType == "publication"){
      setGetDeepDivePatentTerms(false)
      setGetDeepDivePublicationTerms(true)
    }
    analyzeSpec(patentNumber, applicationNumber, resourceType).then((response) => {
      setResource(response)
      var updatedPatent = patentInfo
      if (resourceType === "patent" && response.jobId && response.jobId != "") {
  //      updatedPatent.patentJobId = response.jobId
        updateJobId(updatedPatent["Application Identifier"], response.jobId, "")
      }
      if (resourceType === "publication" && response.jobId && response.jobId != "") {
  //      updatedPatent.pubJobId = response.jobId
        updateJobId(updatedPatent["Application Identifier"], "", response.jobId)

      }
  //    setPatInfo(patInfo)
  //    setResourceCallback(response)
      let context = JSON.parse(localStorage.getItem("chat_context"))
      context.last_context_type = resourceType
      context.last_context_number = extractPatentComponents(patentNumber)['number']
      localStorage.setItem("chat_context", JSON.stringify(context))
    }).catch((response) => {
        console.log("")
    })
  }

  const deepDivePatentTerms = (patentNumber, applicationNumber, resourceType) => {
    if (resourceType == "patent") {
      setGetDeepDivePatentTerms(true)
      setGetDeepDivePublicationTerms(false)
    } else if (resourceType == "publication"){
      setGetDeepDivePatentTerms(false)
      setGetDeepDivePublicationTerms(true)
    }
    deepClaimTermReview(patentNumber, applicationNumber, resourceType).then((response) => {
      setResource(response)
      var updatedPatent = patentInfo
      if (resourceType === "patent" && response.jobId && response.jobId != "") {
  //      updatedPatent.patentJobId = response.jobId
        updateJobId(updatedPatent["Application Identifier"], response.jobId, "")
      }
      if (resourceType === "publication" && response.jobId && response.jobId != "") {
  //      updatedPatent.pubJobId = response.jobId
        updateJobId(updatedPatent["Application Identifier"], "", response.jobId)

      }
  //    setPatInfo(patInfo)
  //    setResourceCallback(response)
      let context = JSON.parse(localStorage.getItem("chat_context"))
      context.last_context_type = resourceType
      context.last_context_number = extractPatentComponents(patentNumber)['number']
      localStorage.setItem("chat_context", JSON.stringify(context))
    }).catch((response) => {
        console.log("")
    })
  }

  async function resourceComplete(status, resourceType, resourcePubNo){
  //  setGetDeepDivePatentTerms(true)
    setChatMessage("I now know information about the description and claims of " + resourceType + " " + resourcePubNo + ".")
    const response = await getTermMap(resourcePubNo, patentInfo["Application Identifier"], resourceType)
    if (resourceType ==  "patent") {
      setShowPatTermMapButton(true)
      setPatTermMapData(response.term_map)
    } else if (resourceType == "publication") {
      setShowPubTermMapButton(true)
      setPubTermMapData(response.term_map)
    }
  }
  return (
    <div className={`border-b border-black p-2 ${theme === 'dark' ? 'text-white bg-gray-900' : 'bg-white '}`}>
      <div className='flex-col relative justify-center'>
        {/* Close Button and Title always visible */}
        <button
          onClick={() => onRemove(patentInfo["Application Identifier"])}
          className={`absolute top-0 right-2 p-2 rounded-full hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer ${theme === 'dark' ? 'text-white bg-gray-900 hover:bg-gray-700' : 'bg-white hover:bg-gray-200'}`}
          aria-label="Close"
        >
          <RedX />
        </button>
        <div className='text-xl mb-2 font-bold' title='Application Number'>{formatApplicationNumber(renderValue(patentInfo["Application Identifier"])) || 'N/A'}</div>

        {/* Always visible: Title, Application Identifier, Patent Number, Publication Number */}
        <div className="font-bold" title="Title">{renderValue(patentInfo["Title"]) || 'N/A'}</div>
        {patentInfo["Patent Number"] && (
          <div className="my-1" title="Patent No">
            <span className="text-blue-500 cursor-pointer" onClick={() => onDocClick(removeCountryAndKindCodes(patentInfo["Patent Number"]), 'patent', removeCountryAndKindCodes(patentInfo["Patent Number"]))}>
              {"US " + formatNumberWithCommas(renderValue(patentInfo["Patent Number"]))}
            </span>
            {!patentInfo.patentJobId && false && (
              <button onClick={() => deepDivePatentTerms(patentInfo["Patent Number"], patentInfo["Application Identifier"], 'patent')}
                      className="ml-1 px-1 py-1 text-xs text-white bg-blue-500 rounded hover:bg-blue-600 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] cursor-pointer">
                Analyze Spec.
              </button>
            )}
            {!patentInfo.patentJobId && false && (
              <button onClick={() => analyzeSpecForContext(patentInfo["Patent Number"], patentInfo["Application Identifier"], 'patent')}
                      className="ml-1 px-1 py-1 text-xs text-white bg-blue-500 rounded hover:bg-blue-600 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] cursor-pointer">
                Analyze Spec.
              </button>
            )}
            {patentInfo.patentJobId && (
              <ResourceProcessingStatus
                resourceType="patent"
                resourcePubNo={patentInfo["Patent Number"]}
                resourceName="- analyzing spec."
                jobId={patentInfo.patentJobId}
                completeCallback={resourceComplete}
                hideName={false}
              />
            )}
            {showPatTermMapButton && (
              <>
                <button
                  onClick={() => setIsPatTermMapVisible(!isPatTermMapVisible)}
                  className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
                  {isPatTermMapVisible ? 'Hide Term Map' : 'Show Term Map'}
                </button>
                {isPatTermMapVisible && <TermMap termMap={patTermMapData} onClose={() => setIsPatTermMapVisible(false)} />}
              </>
            )}
          </div>
        )}

        {patentInfo["Early Publication Number"] && (
          <div title="Early Pub. No.">
            <span className="text-blue-500 cursor-pointer" onClick={() => onDocClick(removeCountryAndKindCodes(patentInfo["Early Publication Number"]), 'publication', removeCountryAndKindCodes(patentInfo["Early Publication Number"]))}>
              {formatPubNumber(renderValue(patentInfo["Early Publication Number"]))}
            </span>
            {!patentInfo.pubJobId && false && (
              <button onClick={() => deepDivePatentTerms(patentInfo["Early Publication Number"], patentInfo["Application Identifier"], 'publication')}
                      className="ml-1 px-1 py-1 text-xs text-white bg-blue-500 rounded hover:bg-blue-600 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] cursor-pointer">
                Analyze Spec.
              </button>
            )}
            {!patentInfo.pubJobId && false && (
              <button onClick={() => analyzeSpecForContext(patentInfo["Early Publication Number"], patentInfo["Application Identifier"], 'publication')}
                      className="ml-1 px-1 py-1 text-xs text-white bg-blue-500 rounded hover:bg-blue-600 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] cursor-pointer">
                Analyze Spec.
              </button>
            )}
            {patentInfo.pubJobId && (
              <ResourceProcessingStatus
                resourceType="publication"
                resourcePubNo={patentInfo["Early Publication Number"]}
                resourceName="- analyzing spec."
                jobId={patentInfo.pubJobId}
                completeCallback={resourceComplete}
                hideName={false}
              />
            )}
            {showPubTermMapButton && (
              <>
                <button
                  onClick={() => setIsPubTermMapVisible(!isPubTermMapVisible)}
                  className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
                  {isPubTermMapVisible ? 'Hide Term Map' : 'Show Term Map'}
                </button>
                {isPubTermMapVisible && <TermMap termMap={pubTermMapData} onClose={() => setIsPubTermMapVisible(false)} />}
              </>
            )}
          </div>
        )}

        {/* Toggle Button for Detailed Info */}
        <button onClick={() => setShowDetails(!showDetails)} className="text-blue-500 hover:bg-blue-100 p-1 rounded text-xs mt-2">
          {showDetails ? 'Hide Detailed Info' : 'Show Detailed Info'}
        </button>


        {/* Conditionally render the detailed information */}
        {showDetails && Object.entries(patentInfo).map(([key, value]) => {
          // Exclude already displayed info
          if (["Title", "Application Identifier", "Patent Number", "Early Publication Number", "patentJobId", "pubJobId"].includes(key)) {
            return null;
          }

          // Here you can filter out any other keys you don't want to display
          return (
            <p key={key}>
              <i>{lookupKey(key)}:</i> <strong>{renderValue(value) || 'N/A'}</strong>
            </p>
          );
        })}

        {showCustomAnalysis && <CustomAnalysis onClose={() => setShowCustomAnalysis(false)} patentNumber={patentInfo["Patent Number"]} pubNumber={patentInfo["Early Publication Number"]} />}
      </div>

      {/* Button to trigger Custom Analysis */}
      {false && <button onClick={() => setShowCustomAnalysis(true)} className="bg-green-500 text-white p-2 rounded mt-2">
        Custom Analysis
      </button>}
    </div>
  )
}

export default PatentInfoComponent
