import React, { useState, useContext } from 'react'
import { sendFeedback } from '../api'
import ThemeContext from '../context/ThemeContext'

const FeedbackForm = ({ closeFeedback }) => {
  const [feedback, setFeedback] = useState('')
  const [feedbackSubmittedSuccess, setFeedbackSubmittedSuccess] = useState(false)
  const [statusMessage, setStatusMessage] = useState('')
  const { theme, setTheme } = useContext(ThemeContext)

  const handleSubmit = (e) => {
    console.log('Feedback submitted:', feedback)
    sendFeedback(feedback).then((feedbackSubmitResponse) => {
      setFeedbackSubmittedSuccess(true)
      setStatusMessage("Thank you for your feedback.")
    })
    setFeedback('')
  }

  return (
    <div className={`p-4 max-w-md mx-auto rounded-xl shadow-md md:max-w-2xl ${theme === 'dark' ? 'text-white bg-gray-900' : 'bg-white '}`}>
      <div className="max-w-lg mx-auto p-4">
        <div className="mb-6">
          <label htmlFor="feedback" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your feedback</label>
          <textarea
            id="feedback"
            rows="4"
            className={`block p-2.5 w-full text-sm rounded-lg border ${theme === 'dark' ? 'bg-gray-600 border-gray-300 text-gray-200 focus:ring-gray-500 focus:border-gray-500' : 'border-gray-300 text-gray-900 bg-gray-50 focus:ring-blue-500 focus:border-blue-500'}`}
            placeholder="Let us know what you think..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          ></textarea>
        </div>
        <button onClick={handleSubmit} type="submit" className="text-white bg-blue-400 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          Submit Feedback
        </button>
        <button onClick={closeFeedback} type="submit" className="text-white bg-red-200 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
          Return to Chat
        </button>
        {feedbackSubmittedSuccess && (<>
          <div className="">{statusMessage}</div>
        </>)}
      </div>
    </div>
  )
}

export default FeedbackForm
