import React, { useState } from 'react'

const ResourceObject = ({ resource, scrollToParagraph, scrollToClaim }) => (
  <div>
    <h3>Resource ID: {resource.resource_id}</h3>
    <p>Type: {resource.resource_type}</p>
    {resource.paragraph_numbers &&
    <p>
      Paragraph Numbers:&nbsp;
      {resource.paragraph_numbers.map((num, index) => (
        <span
          key={index}
          onClick={() => scrollToParagraph(resource.resource_type, resource.resource_id, num)}
          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
        >
          {num}{index < resource.paragraph_numbers.length - 1 ? ', ' : ''}
        </span>
      ))}
    </p>
    }
    {resource.claim_numbers &&
    <p>
      Claim Numbers:&nbsp;
      {resource.claim_numbers.map((num, index) => (
        <span
          key={index}
          onClick={() => scrollToClaim(resource.resource_type, resource.resource_id, num)}
          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
        >
          {num}{index < resource.claim_numbers.length - 1 ? ', ' : ''}
        </span>
      ))}
    </p>
    }
    {resource.figures && <p>Figures: {resource.figures.join(', ')}</p>}
    {resource.terms && <p>Terms: {resource.terms.join(', ')}</p>}
    {resource.use_full_text && <p>Use Full Text: Yes</p>}
    {resource.use_entire_description && <p>Use Entire Description: Yes</p>}
    {resource.use_entire_claims && <p>Use Entire Claims: Yes</p>}
    {resource.use_summary && <p>Use Summary: Yes</p>}
    {resource.use_bibliographic_info && <p>Use Bibliographic Info: Yes</p>}
    {resource.use_file_wrapper_info && resource.resource_type === 'application' && <p>Use File Wrapper Info: Yes</p>}
  </div>
)

const MessageResponseContext = ({ info, scrollToParagraph, scrollToClaim }) => {
  const [showInfo, setShowInfo] = useState(false)

  return (
    <div>
      <button className={`p-1 rounded-t  ${(showInfo ? 'bg-white text-black border-b' : '')}`} onClick={() => setShowInfo(!showInfo)}>
        {showInfo ? 'Hide Info' : 'Show Info'}
      </button>
      {showInfo && (
        <div className="bg-white rounded-b rounded-r border-black text-black p-1">
          {info.include_history && <p>Include History: Yes</p>}
          {info.include_history_summary_only && <p>Include History Summary Only: Yes</p>}
          {info.chatbot_info && <p>Chatbot Info: Yes</p>}
          {info.has_mpep && (
            <div>
              <p>Manual Patent Examining Procedure (MPEP) Sections Needed:</p>
              <ul>
                {info.mpep_sections.map((section, index) => (
                  <li key={index}>{section}</li>
                ))}
              </ul>
            </div>
          )}
          {info.resources && info.resources.length > 0 && (
            <div>
              <h2>Resources:</h2>
              {info.resources.map((resource, index) => (
                <ResourceObject key={index} resource={resource} scrollToParagraph={scrollToParagraph} scrollToClaim={scrollToClaim} />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MessageResponseContext
