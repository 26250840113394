import React, { useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import RedX from '../component/RedX'
import ThemeContext from '../context/ThemeContext'

const TermsOfUse = ({ onClose }) => {
  const { theme } = useContext(ThemeContext)
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className={`relative top-20 mx-auto p-5 border min-w-[calc(400px)] max-w-[calc(700px)] shadow-lg rounded-md ${theme === 'dark' ? 'text-white bg-gray-900' : 'bg-white'}`}>
        <button onClick={onClose} className="absolute top-2 right-2">
          <RedX />
        </button>
        <h3 className={`text-lg leading-6 font-medium ${theme === 'dark' ? 'text-gray-200' : 'text-gray-900'}`}>Terms of Use</h3>
        <div className={`mt-2 px-7 py-3 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
          <p className="text-sm">Effective: January 31, 2024 (previous version)</p>
          <p className="text-sm mt-4">
            Thank you for using Patents.chat!
          </p>
          <h3 className="text-lg font-semibold mb-2 flex items-center cursor-pointer" onClick={toggleExpand}>
            Registration and Access
            <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} className="ml-2" />
          </h3>
          {isExpanded && (
            <div className="mb-4">
              <p className="mb-2"><strong>Minimum age:</strong></p>
              <p className="mb-2">You must be at least 18 years old or the minimum age required in your country to consent to use the Services.</p>
              <p className="mb-2"><strong>Registration:</strong></p>
              <p className="mb-4">You must provide accurate and complete information to register for an account to use our Services. You may not share your account credentials or make your account available to anyone else and are responsible for all activities that occur under your account. If you create an account or use the Services on behalf of another person or entity, you must have the authority to accept these Terms on their behalf.</p>
            </div>
          )}
          <h3 className="text-lg font-semibold mb-2">Using Our Services</h3>
          <ul className="list-disc list-inside mb-4">
            <li>Subject to your compliance with these Terms, you may access and use our Services.</li>
            <li>You must comply with all applicable laws as well as our policies and guidelines.</li>
            <li>You may not use our Services for any illegal, harmful, or abusive activity.</li>
          </ul>
          <h3 className="text-lg font-semibold mb-2">Content</h3>
          <p className="mb-4">
            You may provide input to the Services (“Input”), and receive output from the Services based on the Input (“Output”). Input and Output are collectively “Content.” You are responsible for Content, including ensuring that it does not violate any applicable law or these Terms. You represent and warrant that you have all rights, licenses, and permissions needed to provide Input to our Services.
          </p>
          <h3 className="text-lg font-semibold mb-2">Ownership of Content</h3>
          <p className="mb-4">
            As between you and Patents.chat, and to the extent permitted by applicable law, you retain your ownership rights in Input and own the Output. We hereby assign to you all our right, title, and interest, if any, in and to Output.
          </p>
          <h3 className="text-lg font-semibold mb-2">User Responsibility</h3>
          <p className="mb-4">
            You are responsible for maintaining the confidentiality of your data. Any use of the platform that results in mistaken distribution or other unintended outcomes is at your own risk. We are always available for help, support, and questions regarding the use of our Services.
          </p>
          <h3 className="text-lg font-semibold mb-2">Disclaimer of Warranties</h3>
          <p className="mb-4">
            Our services are provided “as is.” Except to the extent prohibited by law, we and our affiliates and licensors make no warranties (express, implied, statutory, or otherwise) with respect to the services, and disclaim all warranties including, but not limited to, warranties of merchantability, fitness for a particular purpose, satisfactory quality, non-infringement, and quiet enjoyment, and any warranties arising out of any course of dealing or trade usage. We do not warrant that the services will be uninterrupted, accurate, or error-free, or that any content will be secure or not lost or altered.
          </p>
          <h3 className="text-lg font-semibold mb-2">No Liability</h3>
          <p className="mb-4">
            Neither we nor any of our affiliates or licensors will be liable for any indirect, incidental, special, consequential, or exemplary damages, including damages for loss of profits, goodwill, use, or data or other losses, even if we have been advised of the possibility of such damages. Our aggregate liability under these terms will not exceed the greater of the amount you paid for the service that gave rise to the claim during the 12 months before the liability arose or one hundred dollars ($100). The limitations in this section apply only to the maximum extent permitted by applicable law.
          </p>
          <h3 className="text-lg font-semibold mb-2">Legal Advice Disclaimer</h3>
          <p className="mb-4">
            This application does not provide legal advice. The information is provided for general informational and educational purposes only and is not a substitute for professional legal advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate legal professionals or licensing authorities.
          </p>
          <h3 className="text-lg font-semibold mb-2">AI Hallucinations Disclaimer</h3>
          <p className="mb-4">
            Generative text AI, like the models used in Patents.chat, may sometimes produce incorrect or nonsensical information, known as AI hallucinations. It is important to critically evaluate the outputs and verify them with trusted sources. For more information on AI hallucinations and how to handle them, please refer to this <a href="https://www.stellarcapacity.com/post/understanding-the-phenomenon-of-ai-hallucinations" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">guidance on understanding AI hallucinations</a>.
          </p>
          <h3 className="text-lg font-semibold mb-2">Contact Information</h3>
          <p className="mb-4">
            ForGen AI, LLC<br />
            Birmingham, Michigan 48009<br />
            Email: support@forgen.ai
          </p>
          <div className="items-center px-4 py-3">
            <button
              className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsOfUse
