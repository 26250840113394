import React, { useContext } from 'react'
import ThemeContext from '../context/ThemeContext'

const Philosophy = () => {
  const { theme } = useContext(ThemeContext)
  return (<>
    <h3 className="font-semibold mt-2">About Us</h3>
      <div className={`pb-1 pl-2 ${theme === 'dark' ? 'text-gray-200' : 'text-gray-600'}`}>
        <div className="pl-2 text-sm italic text-gray-300">Struggling to stay competitive with prices while not breaking the bank on large IP software packages is challenging--and is yet another reason why smaller firms and companies are fading away or outsourcing work to larger firms.</div>
        <div>
          <span className="font-semibold py-2 text-white">Enter Patents.chat: </span>
          <span className="pl-2 text-sm">Organically created over the last year or so by experienced U.S. patent practioners, Patents.chat allows individuals curious about generative AI to try it out and only pay for what you use.  No need to share team up with others to share accounts to tools that charge you in large installments, very often not even tied to your actual usage.</span>
          <span className="pl-2 text-sm">Our philosophy is that advanced tools should be available to everyone, not just large firms or companies with the capacity to afford SOTA IP tools.</span>
        </div>
      </div>
   </>)
}

export default Philosophy
