import React, { useState, useRef, useEffect } from 'react'
import DraggableCore from 'react-draggable'
import rehypeRaw from 'rehype-raw'
import { sendMessage, getMessage, getPatentInfo, addNewChat, saveChat, searchPatents, sendMessageFeedback } from '../api'
import { getJobStatus, getIssueResponses, getIssues, getProsHist, getSpecMap, fetchFolders } from '../api'
import { getPartsList, delete_the_storage_but_not_auth_ids, fetchAndLoadDocument }from '../api'
import { removeCountryAndKindCodes, formatApplicationNumber } from '../util/patent_util'
import ResourceProcessingStatus from '../component/ResourceProcessingStatus'
import TypingDots from '../component/TypingDots'
import styles from '../ColorCycle.module.css'
import PatentInfoComponent from '../chat_room/PatentInfoComponent'
import ChatList from '../chat_room/ChatList'
import ResourcesPage from '../chat_room/ResourcesPage'
import VerticalNavigationBar from '../chat_room/VerticalNavigationBar'
import FileInput from '../component/FileInput'
import ContextSummary from '../chat_room/ContextSummary'
import MessageResponseContext from '../chat_room/MessageResponseContext'
import DocumentViewer from '../chat_room/DocumentViewer'
import DrawingsViewer from '../chat_room/DrawingsViewer'
import QuickStartGuide from '../info/QuickStartGuide'
import PatentSearchResults from '../op/PatentSearchResults'
import FeedbackForm from '../component/FeedbackForm'
import CustomQuestionResponse from '../op/CustomQuestionResponse'
import AccountWindow from '../chat_room/AccountWindow'
import DisclaimerModal from '../chat_room/DisclaimerModal'
import PromptBuilder from '../chat_room/PromptBuilder'
import SettingsWindow from '../chat_room/SettingsWindow'
import SavedChats from '../chat_room/SavedChats'
import IssueList from '../op/IssueList'
import IssueResponseList from '../op/IssueResponseList'
import PatentProsHist from '../op/PatentProsHist'
import SpecificationMap from '../op/SpecificationMap'
import PartsList from '../op/PartsList'
import DataInfo from '../info/DataInfo'
import PrivacyPolicy from '../info/PrivacyPolicy'
import TermsOfUse from '../info/TermsOfUse'
import RedX from '../component/RedX'

import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { okaidia, dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FiSettings } from 'react-icons/fi'


const XLSX = require('xlsx')

function ChatRoom({ userInfo, userSettings, isSaved, hideDragBars, setIsSaved, username, onThemeChange, theme, setShowUsageWindow, handleLogout }) {
  const [showDataInfo, setShowDataInfo] = useState(false)
  const [showPolicy, setShowPolicy] = useState(false)
  const [showTermsOfUse, setShowTermsOfUse] = useState(false)
  const [showAccountWindow, setShowAccountWindow] = useState(false)
  const [operationResponse, setOperationResponse] = useState(null)
  const [displayOperationResponse, setDisplayOperationResponse] = useState(false)
  const [issues, setIssues] = useState(null)
  const [displayIssues, setDisplayIssues] = useState(false)
  const [prosHist, setProsHist] = useState(null)
  const [specMap, setSpecMap] = useState(null)
  const [partsList, setPartsList] = useState(null)
  const [curIssueAppNo, setCurIssueAppNo] = useState(null)
  const [displayProsHist, setDisplayProsHist] = useState(false)
  const [displaySpecMap, setDisplaySpecMap] = useState(false)
  const [displayPartsList, setDisplayPartsList] = useState(false)
  const [customSummaryResponses, setCustomSummaryResponses] = useState(null)
  const [showCustomSummaryResponses, setShowCustomSummaryResponses] = useState(false)
  const [masterMessages, setMasterMessages] = useState([])
  const [customPrompt, setCustomPrompt] = useState(null)
  const [selectedSection, setSelectedSection] = useState('chat')
  const handleSelect = (section) => {
    if (section === "faq") {
      setShowGuide(true)
    } else if (section === "settings") {
      setShowSettings(true)
    } else if (section === "usage") {
      setShowUsageWindow(true)
    } else if (section === "account") {
      setShowAccountWindow(true)
    } else if (section === "document" || section === "drawings") {
      if (!docNumber || docNumber === "" || !type || type  === "") {
        setDocNumber(contextData.last_context_number)
        setType(contextData.last_context_type)
      }
      setSelectedSection(section)
    } else {
      setSelectedSection(section)
      setShowGuide(false)
      setShowSettings(false)
      setShowUsageWindow(false)
    }
  }
  const [contextData, setContextData] = useState({
    current_applications: [],
    current_patents: [],
    current_publications: [],
    resource_ids: [],
    resource_names: [],
    mpep_sections: [],
    last_context_number: null,
    last_context_type: null,
  })
  const [removedResources, setRemovedResources] = useState([])
  const checkRemovedResources = (resourcesToCheck) => {
    removedResources.forEach(removedResource => {
      switch (removedResource.type) {
        case 'application':
            resourcesToCheck.current_applications.filter(resource => resource !== removedResource.id)
          break
        case 'patent':
            resourcesToCheck.current_patents.filter(resource => resource !== removedResource.id)
          break
        case 'publication':
            resourcesToCheck.current_publications.filter(resource => resource !== removedResource.id)
          break
        case 'resource':
        case 'upload':
            resourcesToCheck.resource_ids.filter(resource => resource !== removedResource.id)
          break
        case 'mpep_section':
            resourcesToCheck.mpep_sections.filter(resource => resource !== removedResource.id)
          break
        default:
          console.warn(`Unknown resource type: ${removedResource.type}`)
      }
    })
  }

  const addToResources = (new_resources) => {
    setContextData((prevContext) => {
      const updatedContext = { ...prevContext }
      const filteredResources = new_resources //.filter(resource => resource.auto_identified === true)
      filteredResources.forEach((resource) => {
          switch (resource.type) {
              case 'application':
                  if (!updatedContext.current_applications.includes(resource.id)) {
                      updatedContext.current_applications.push(resource.id)
                  }
                  break
              case 'patent':
                  if (!updatedContext.current_patents.includes(resource.id)) {
                      updatedContext.current_patents.push(resource.id)
                      updatedContext.last_context_number = resource.id
                      updatedContext.last_context_type = 'patent'
                  }
                  break
              case 'publication':
                  if (!updatedContext.current_publications.includes(resource.id)) {
                      updatedContext.current_publications.push(resource.id)
                  }
                  break
              case 'mpep_section':
                  if (!updatedContext.mpep_sections.includes(resource.id)) {
                      updatedContext.mpep_sections.push(resource.id)
                  }
                  break
              default:
                  console.warn(`Unknown resource type: ${resource.type}`)
          }
          if (!(resource.type === "patent" || resource.type === "publication" || resource.type === "application")) {
            return
          }
          fetchAndLoadDocument(resource.type, removeCountryAndKindCodes(resource.id)).then((fetchAndLoadDocumentResponse) => {
            if (fetchAndLoadDocumentResponse && fetchAndLoadDocumentResponse["job_id"]){
              const job_id = fetchAndLoadDocumentResponse["job_id"]
              addNewResources([fetchAndLoadDocumentResponse['job_id']], resource.type, [resource.id])
              const fetchAndLoadDocumentInterval = setInterval(() => {
                getJobStatus(job_id).then((getJobStatusResponse) => {
                  if (getJobStatusResponse && getJobStatusResponse.status && getJobStatusResponse.status.toLowerCase().startsWith("complete")) {
                    setType(resource.type)
                    setDocNumber(resource.id)
                    clearInterval(fetchAndLoadDocumentInterval)
                  }
                  if (getJobStatusResponse && getJobStatusResponse.status && getJobStatusResponse.status.toLowerCase().startsWith("error")) {
                    clearInterval(fetchAndLoadDocumentInterval)
                  }
                }).catch((e) => {
                    clearInterval(fetchAndLoadDocumentInterval)
                })
                }, 1500)
            }
          }).catch((e) => {
            // SHOW ERROR MSG
          })
      })
      localStorage.setItem('chat_context', JSON.stringify(updatedContext))
      return updatedContext
    })
  }

  const updateMasterMessages = (docId, docType, message) => {
    setMasterMessages((prevMessages) => {
      const newMessages = [...prevMessages]
      const index = newMessages.findIndex((msg) => msg.docId === docId && msg.docType === docType)
      if (index > -1) {
        newMessages[index].message = message
      } else {
        newMessages.push({ docId, docType, message })
      }
      return newMessages
    })
  }
  const downloadToExcel = () => {
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(masterMessages)
    XLSX.utils.book_append_sheet(wb, ws, 'Messages')
    const filename = 'master_messages.xlsx'
    XLSX.writeFile(wb, filename)
  }
  const [codeToasts, setCodeToasts] = useState({})
  const [feedbackToasts, setFeedbackToasts] = useState({})
  const [toasts, setToasts] = useState({})
  const showCodeToastMessage = (index, message, duration = 3000) => {
    setCodeToasts(prevCodeToasts => ({ ...prevCodeToasts, [index]: message }))
    setTimeout(() => {
      setCodeToasts(prevCodeToasts => ({ ...prevCodeToasts, [index]: '' }))
    }, duration)
  }
  const showFeedbackToastMessage = (index, message, duration = 3000) => {
    setFeedbackToasts(prevFeedbackToasts => ({ ...prevFeedbackToasts, [index]: message }))
    setTimeout(() => {
      setFeedbackToasts(prevFeedbackToasts => ({ ...prevFeedbackToasts, [index]: '' }))
    }, duration)
  }
  const showToastMessage = (index, message, duration = 3000) => {
    setToasts(prevToasts => ({ ...prevToasts, [index]: message }))
    setTimeout(() => {
      setToasts(prevToasts => ({ ...prevToasts, [index]: '' }))
    }, duration)
  }
  const renderers = {
    code: ({ language, value }) => {
      return <SyntaxHighlighter style={dark} language={language} children={value} />
    },
    rsrc: ({node, ...props}) => <CustomRsrc {...props} />
  }
  const copyCodeToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(() => showCodeToastMessage(index, 'Code copied!'))
  }
  const copyMessageToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(() => showToastMessage(index, 'Copied!'))
  }
  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '')
      const index = node.position?.start.line
      return !inline && match ? (
        <div className="relative  max-w-full break-words">
          <button
            onClick={() => copyCodeToClipboard(children, index)}
            className="absolute right-2 top-2 text-xs py-1 px-2 bg-gray-700 text-white rounded-md cursor-pointer opacity-75 hover:opacity-100"
          >
            Copy
          </button>
          {codeToasts[index] && (
            <div className="absolute right-2 top-2 transform translate-y-4 group-hover:translate-y-0 transition-all bg-black text-white px-4 py-2 rounded shadow-lg text-xs z-20">
              {codeToasts[index]}
            </div>
          )}
          <SyntaxHighlighter style={okaidia} language={match[1]} PreTag="div" {...props}>
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
        </div>
      ) : (
        <code className={`${className} max-w-full break-words`} {...props}>
          {children}
        </code>
      )
    }
  }
  const [rightDivWidth, setRightDivWidth] = useState(700)
  const [flexWidth, setFlexWidth] = useState('full')
  const draggableRef = useRef(null)
  const [activeDrags, setActiveDrags] = useState(0)
  const [showTopButton, setShowTopButton] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [showBottomButton, setShowBottomButton] = useState(false)
  const [deleteMessageIndex, setDeleteMessageIndex] = useState(null)
  const scrollDivRef = useRef(null)
  const checkScrollNeed = () => {
    if (!scrollDivRef || !scrollDivRef.current) return
    const { scrollHeight, clientHeight } = scrollDivRef.current
    if (scrollHeight >= 2 * clientHeight) {
      setShowTopButton(false)
      setShowBottomButton(true)
    } else {
      setShowTopButton(false)
      setShowBottomButton(false)
    }
  }
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollDivRef.current
    setShowBottomButton(!(scrollTop + clientHeight >= scrollHeight - 10))
    setShowTopButton(!(scrollTop === 0))
  }
  useEffect(() => {
    //    setOperationResponse(messageObj.operation_response)
    loadFolders()
    const div = scrollDivRef.current
    if (!scrollDivRef || !scrollDivRef.current) return
    checkScrollNeed()
    div.addEventListener('scroll', handleScroll)
    return () => div.removeEventListener('scroll', handleScroll)
  }, [])
  const [controlledPosition, setControlledPosition] = useState({ x: 0, y: 0 })
  const onStart = () => {
    setActiveDrags(prevActiveDrags => prevActiveDrags + 1)
  }
  const onStop = () => {
    setActiveDrags(prevActiveDrags => prevActiveDrags - 1)
    setControlledPosition({ x: 0, y: 0 })
    if (draggableRef.current) {
      const rect = draggableRef.current.getBoundingClientRect()
      const distanceFromRight = window.innerWidth - rect.left
      setFlexWidth(`${distanceFromRight}px`)
    }
  }
  const onControlledDrag = (e, position) => {
    const { x, y } = position
    setControlledPosition({ x: x, y: 0 })
  }
  const [messages, setMessages] = useState([])
  const [resources, setResources] = useState([])
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false)
  const messagesEndRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [showPatentList, setShowPatentList] = useState(false)
  const [showFeedbackWindow, setShowFeedbackWindow] = useState(false)
  const [chatName, setChatName] = useState('')
  const [chatId, setChatId] = useState('')
  const [modalErrorMessage, setModalErrorMessage] = useState(null)
  const chatListRef = useRef(null)
  const [folders, setFolders] = useState([])
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [patents, setPatents] = useState([])
  const [searchResults, setSearchResults] = useState(null)
  const [showSearchResultWindow, setShowSearchResultWindow] = useState(false)
  const messagesRef = useRef(messages)
  const chatIdRef = useRef(chatId)
  const [showDocWindow, setShowDocWindow] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const [showChatListWindow, setShowChatListWindow] = useState(false)
  const [isContextVisible, setIsContextVisible] = useState(true)
  const [isSuppContextVisible, setIsSuppContextVisible] = useState(false)
  const [selectedReference, setSelectedReference] = useState(null)
  const [type, setType] = useState('')
  const [docNumber, setDocNumber] = useState('')
  const [docName, setDocName] = useState('')
  const [resourceNumber, setResourceNumber] = useState('')
  const [resourceType, setResourceType] = useState('')
  const [showGuide, setShowGuide] = useState(false)
  const toggleGuide = () => setShowGuide(!showGuide)
  const addToChatContext = (itemType, resourceId) => {
    let localStorageString = localStorage.getItem('chat_context')
    if (!localStorageString) {
      localStorageString = '{"current_applications":[],"current_patents":[],"current_publications":[],"last_context_number":"","last_context_type":"","mpep_sections":[],"resource_ids":[],"resource_names":[]}'
    }
    const chatContext = JSON.parse(localStorageString)
    if (itemType == "publication" && !chatContext.current_publications.includes(resourceId)) {
      let pubNo = removeCountryAndKindCodes(resourceId)
      chatContext.current_publications.push(pubNo)
    } else if (itemType == "patent" && !chatContext.current_patents.includes(resourceId)) {
      chatContext.current_patents.push(resourceId)
    } else if (itemType == "application" && !chatContext.current_applications.includes(resourceId)) {
      chatContext.current_applications.push(resourceId)
    }
    chatContext.last_context_number = resourceId
    chatContext.last_context_type = itemType
    const updatedLocalStorageString = JSON.stringify(chatContext)
    localStorage.setItem('chat_context', updatedLocalStorageString)
  }

  const handleReferenceClick = (item, itemType, resourceId) => {
    addToChatContext(itemType, resourceId)
    setSelectedReference({ itemType, item })
    setType(itemType)
    if (itemType === "application") {
      setSelectedSection("applications")
      setDocNumber(resourceId)
      setDocName(item)
    } else if (itemType === "mpep" || itemType === "37cfr" || itemType === "35usc" || itemType === "federal_register" || itemType === "uspto_fees" || itemType === "abstract_idea_info") {
      setSelectedSection("resources")
      setDocName(item)
      setResourceNumber(resourceId)
      setResourceType(itemType)
    } else if (itemType === "upload") {
      setSelectedSection("document")
      setResourceNumber(resourceId)
      setDocNumber(resourceId)
      setResourceType(itemType)
      setDocName(item)
    } else {
      setSelectedSection("document")
      setDocNumber(resourceId)
      setDocName(item)
    }
  }

  const mapping = {
    "applId": "Application Identifier",
    "patentTitle": "Title",
    "appFilingDate": "Application Filing Date",
    "patentNumber": "Patent Number",
    "patentIssueDate": "Patent Issue Date",
    "appEarlyPubNumber": "Early Publication Number",
    "appEarlyPubDate": "Early Publication Date",
    "appStatus": "Application Status",
    "appExamName": "Patent Examiner Name",
    "inventors": "Inventors",
    "appGrpArtNumber": "Group Art Number",
    "appType": "Application Type",
    "lastUpdatedTimestamp": "Last Updated",
  }
  useEffect(() => {
    messagesRef.current = messages
  }, [messages])

  useEffect(() => {
    chatIdRef.current = chatId
  }, [chatId])

  useEffect(() => {
    if (selectedSection === "chat"){
      scrollToBottom()
    }
    const div = scrollDivRef.current
    if (!scrollDivRef || !scrollDivRef.current) return
    checkScrollNeed()
    div.addEventListener('scroll', handleScroll)
    return () => div.removeEventListener('scroll', handleScroll)
  }, [selectedSection])

  const handleCurrentChatChange = (chat) => {
    setMessages(chat.chat_history)
    localStorage.setItem('chat_context', JSON.stringify(chat.chat_context))
    localStorage.setItem('chat_name', chat.chat_name)
    localStorage.setItem('chat_id', chat.chat_id)
    localStorage.setItem('search_results', searchResults)
    window.dispatchEvent(new Event('storage-update'))
    setChatName(chat.chat_name)
    setChatId(chat.chat_id)
    setIsSaved(true)
    localStorage.setItem("is_saved", true)
    if (chat.patents && Object.keys(chat.patents).length > 0) {
      let chatPatents = []
      chat.patents.forEach(function (item, index) {
        const orderedObj = {}
        Object.keys(mapping).forEach(key => {
          //                if (item[mapping[key]] !== undefined) {
          orderedObj[mapping[key]] = item[mapping[key]]
          //                }
        })
        orderedObj['patentJobId'] = item['patentJobId']
        orderedObj['pubJobId'] = item['pubJobId']
        chatPatents.push(orderedObj)
      })
      localStorage.setItem('patents', JSON.stringify(chatPatents))
      setPatents(chatPatents)
    }
  }

  function filterAndMapKeys(obj) {
    const filteredObj = {}

    for (const key in obj) {
      if (mapping[key]) {
        filteredObj[mapping[key]] = obj[key]
      }
    }

    return filteredObj
  }

  const removePatent = (applicationNumber) => {
    setPatents(prevPatents => prevPatents.filter(patent => patent["Application Identifier"] !== applicationNumber))
  }

  const findOpResponse = (the_messages) => {
    const message = the_messages.find(message => message.is_op)
    if (message) {
      return message.op_response
    }
    return null
  }

  const findIssues = (the_messages) => {
    const message = the_messages.find(message => message.is_issues)
    if (message) {
      return message.op_response
    }
    return null
  }

  const findProsHist = (the_messages) => {
    const message = the_messages.find(message => message.is_pros_hist)
    if (message) {
      return message.op_response
    }
    return null
  }

  function updateResources(newJobInfo) {
    const newResources = processJobInfo(newJobInfo)
    setResources(prevResources => [...prevResources, ...newResources])
  }

  const initializationDone = useRef(false)
  useEffect(() => {
    if (initializationDone.current) {
      return
    }
    const prev_username = localStorage.getItem('username')
    initializationDone.current = true
    if (username !== prev_username) {
      clearChatMessages(false)
      localStorage.setItem('username', username)
    }
    if (localStorage.getItem('chat_context') !== null) {
    }
    const patentsFromStorage = localStorage.getItem('patents')
    if (patentsFromStorage) {
      setPatents(JSON.parse(patentsFromStorage))
    }
    const searchResultsFromStorage = localStorage.getItem('search_results')
    if (searchResultsFromStorage && Object.keys(searchResultsFromStorage).length > 0) {
      setSearchResults(JSON.parse(searchResultsFromStorage))
      setShowSearchResultWindow(true)
    }
    const storedHistory = localStorage.getItem('history') || ""
    if (!storedHistory) {
      setMessages([{
        role: "assistant",
        content: "Hello. Feel free to ask me about patents, including a specific US case by typing in the patent number, application number, or application publication number.",
      }])
    } else {
      const the_messages = JSON.parse(storedHistory)
      setOperationResponse(findOpResponse(the_messages))
      setIssues(findIssues(the_messages))
      setProsHist(findProsHist(the_messages))
      setMessages(the_messages)
      const is_saved = JSON.parse(localStorage.getItem('is_saved'))
      const chat_id = localStorage.getItem("chat_id")
      const chat_name = localStorage.getItem("chat_name")
      setChatId(chat_id)
      setChatName(chat_name)
      if (is_saved) {
        setIsSaved(true)
      } else if (the_messages.length > 1) {
        setIsSaved(false)
      }
    }
  }, [])

  const resubmitMessage = (messageText, index) => {
    //        setMessages(prevMessages => prevMessages.slice(0, -1))
    //        setInput(messageText)
    handleSend(messageText)
    showToastMessage(index, 'Sent!')
  }

  useEffect(() => {
    localStorage.setItem('history', JSON.stringify(messages))
  }, [messages])

  const handleSend = (messageInput) => {
    if (!messageInput || typeof messageInput !== 'string') {
      messageInput = input
    }
    if (typeof messageInput === 'string' && messageInput.trim() !== '') {
      setMessages(prevMessages => [...prevMessages, { content: messageInput, role: 'user', username: username, sendTime: Date.now() }])
      setInput('')
      setIsSaved(false)
      localStorage.setItem("is_saved", false)
      setTimeout(() => {
        setLoading(true)
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
      }, 50)
      sendMessage(messageInput, messages, userInfo).then((sendMessageResponse) => {
        if (sendMessageResponse["error"]) {
          setLoading(false)
          setMessages(prevMessages => [...prevMessages, { content: "Please provide 8 digit serial number following the command.", role: 'assistant', receiveTime: Date.now(), userMessageId: sendMessageResponse["job_id"], ...(sendMessageResponse['context'] && { context: sendMessageResponse['context'] }) }])
          return
        }
        if (sendMessageResponse && sendMessageResponse["op"] && sendMessageResponse["op"] != "") {
          let op_message = "Operation Started"
          if (sendMessageResponse["op"] === "EVAL_OA") {
            op_message = "Office Action evaluation started for " + formatApplicationNumber(sendMessageResponse["app_no"])
          }
          if (sendMessageResponse["op"] === "OA_ISSUES") {
            op_message = "Office Action issue extraction started for " + formatApplicationNumber(sendMessageResponse["app_no"])
          }
          if (sendMessageResponse["op"] === "PROS_HIST") {
            op_message = "Prosecution history summarization started for " + formatApplicationNumber(sendMessageResponse["app_no"])
          }
          if (sendMessageResponse["op"] === "SPEC_MAP") {
            op_message = "Specification feature map generation started for " + formatApplicationNumber(sendMessageResponse["app_no"])
          }
          if (sendMessageResponse["op"] === "PARTS_LIST") {
            op_message = "Parts list generation started for " + formatApplicationNumber(sendMessageResponse["app_no"])
          }
          setMessages(prevMessages => [...prevMessages, { content: op_message, role: 'assistant', receiveTime: Date.now(), userMessageId: sendMessageResponse["job_id"], ...(sendMessageResponse['context'] && { context: sendMessageResponse['context'] }) }])
          var queryId = sendMessageResponse["job_id"]
          let issuesExtracted = false
          const queryInterval = setInterval(() => {
            getJobStatus(queryId).then((jobStatusResponse) => {
              if (jobStatusResponse["status"]) {
                if (jobStatusResponse["status"] == "complete") {
                  //                              setShowCustomSummaryResponses(true)
                  clearInterval(queryInterval)
                  setLoading(false)
                  if (sendMessageResponse["op"] === "EVAL_OA") {
                    getIssueResponses(sendMessageResponse["app_no"]).then((operationResponseData) => {
                      setOperationResponse(operationResponseData)
                      setDisplayOperationResponse(true)
                      addChatMessage("All responses to issues have been generated.", true, false, false, false, false, operationResponseData, sendMessageResponse["app_no"])
                    })
                  } else if (sendMessageResponse["op"] === "OA_ISSUES") {
                    getIssues(sendMessageResponse["app_no"]).then((operationResponseData) => {
                      setIssues(operationResponseData)
                      setCurIssueAppNo(sendMessageResponse["app_no"])
                      setDisplayIssues(true)
                      addChatMessage("All issues for last Office Action for USSN " + formatApplicationNumber(sendMessageResponse["app_no"]) + " have been extracted.", false, true, false, false, false, operationResponseData, sendMessageResponse["app_no"])
                    })
                  } else if (sendMessageResponse["op"] === "PROS_HIST") {
                    getProsHist(sendMessageResponse["app_no"]).then((operationResponseData) => {
                      setProsHist(operationResponseData)
                      setDisplayProsHist(true)
                      addChatMessage("Prosecution history summary generated for USSN " + formatApplicationNumber(sendMessageResponse["app_no"]) + " have been extracted.", false, false, true, false, false, operationResponseData)
                    })
                  } else if (sendMessageResponse["op"] === "SPEC_MAP") {
                    getSpecMap(sendMessageResponse["app_no"]).then((operationResponseData) => {
                      setSpecMap(operationResponseData)
                      setDisplaySpecMap(true)
                      addChatMessage("Specification feature map generated for USSN " + formatApplicationNumber(sendMessageResponse["app_no"]) + " have been extracted.", false, false, false, true, false, operationResponseData)
                    })
                  } else if (sendMessageResponse["op"] === "PARTS_LIST") {
                    getPartsList(sendMessageResponse["app_no"]).then((operationResponseData) => {
                      setPartsList(operationResponseData)
                      setDisplayPartsList(true)
                      addChatMessage("Parts list generated for USSN " + formatApplicationNumber(sendMessageResponse["app_no"]) + " have been extracted.", false, false, false, false, true, operationResponseData)
                    })
                  }
                  return
                }
                if (jobStatusResponse["status"] == "issues extracted") {
                  //                              setShowCustomSummaryResponses(true)
                  if (!issuesExtracted) {
                    setMessages(prevMessages => [...prevMessages, { content: "Issues from the Office Action have been extracted. Now generating responses...", role: 'assistant', receiveTime: Date.now(), userMessageId: sendMessageResponse["job_id"], ...(sendMessageResponse['context'] && { context: sendMessageResponse['context'] }) }])
                  }
                  issuesExtracted = true
                  return
                }
              }
            })
          }, 3500)

          return
        }
        if (sendMessageResponse && sendMessageResponse["cust_query_id"] && sendMessageResponse["cust_query_id"] != "") {
          var queryId = sendMessageResponse["cust_query_id"]
          const queryInterval = setInterval(() => {
            getJobStatus(queryId).then((jobStatusResponse) => {
              if (jobStatusResponse["status"]) {
                if (jobStatusResponse["status"]["message_objects"]) {
                  setCustomSummaryResponses(jobStatusResponse["status"]["message_objects"])
                  if (jobStatusResponse["status"]["prompt"]) {
                    setCustomPrompt(jobStatusResponse["status"]["prompt"])
                  }
                  setShowCustomSummaryResponses(true)
                  clearInterval(queryInterval)
                  return
                }
              }
            })
          }, 3500)
          return
        }
        updateResources(sendMessageResponse['jobInfo'])
        const message_id = sendMessageResponse["message_id"]
        const interval = setInterval(() => {
          getMessage(message_id, false).then((getMessageResponse) => {
            if (getMessageResponse['message'] && getMessageResponse['message'] !== undefined &&
                getMessageResponse['message'] !== null && getMessageResponse['message'] !== "null") {
              setLoading(false)
              setMessages(prevMessages => [...prevMessages, { content: getMessageResponse['message'], role: 'assistant', receiveTime: Date.now(), userMessageId: message_id, ...(getMessageResponse['context'] && { context: getMessageResponse['context'] }) }])
              setIsSaved(false)
              localStorage.setItem("is_saved", false)
              clearInterval(interval)
              if (getMessageResponse.search_results && getMessageResponse.search_results !== "null") {
                setSearchResults(getMessageResponse.search_results)
                setShowSearchResultWindow(true)
              }
              if (getMessageResponse.new_resources && getMessageResponse.new_resources !== "null" && Array.isArray(getMessageResponse.new_resources)) {
                addToResources(getMessageResponse.new_resources)
              }
              if (getMessageResponse.chat_context && getMessageResponse.chat_context !== "null" && typeof getMessageResponse.chat_context === 'object') {
                checkRemovedResources(getMessageResponse.chat_context)
                localStorage.setItem("chat_context", getMessageResponse.chat_context)
                setRemovedResources([])
              }
            }
          }).catch(() => {
            setLoading(false)
            clearInterval(interval)
          })
        }, 3500)
      }).catch(() => {
        setLoading(false)
      })
    }
  }
  const handleEnterKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSend()
    }
  }

  async function handleKeyDown(e) {
    if ((e.ctrlKey || e.metaKey) && e.key === "s") {
      e.preventDefault()
      await saveCurrentChat()
    }
  }

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages, loading])

  function addChatMessage(newChatMessage) {
    addChatMessage(newChatMessage, false, false, false, false, false, null)
  }

  function addChatMessage(newChatMessage, is_op, is_issues, is_pros_hist, is_spec_map, is_parts_list, op_response, app_no) {
    setMessages((prevMessages) => {
      if (prevMessages.length > 0 && newChatMessage === prevMessages[prevMessages.length - 1].content) {
        return prevMessages
      }
      const newMessages = [...prevMessages, { content: newChatMessage, role: 'assistant', receiveTime: Date.now(), userMessageId: app_no, app_no: app_no, is_op: is_op, is_issues: is_issues, is_pros_hist: is_pros_hist, is_spec_map: is_spec_map, is_parts_list: is_parts_list, op_response: op_response }]
      localStorage.setItem('history', JSON.stringify(newMessages))
      setIsSaved(false)
      localStorage.setItem("is_saved", false)
      return newMessages
    })
    setOperationResponse(op_response)
  }

  function processJobInfo(jobInfo) {
    const resourceTypes = [
      { type: "application", prefix: "patent_application", key: "patent_application_resource_ids" },
      { type: "patent", prefix: "patent", key: "patent_resource_ids" },
      { type: "publication", prefix: "patent_publication", key: "patent_publication_resource_ids" }
    ]

    let resources = []

    resourceTypes.forEach(resourceType => {
      const jobKey = `${resourceType.prefix}_job_ids`
      jobInfo[jobKey].forEach((job, index) => {
        resources.push({
          type: resourceType.type,
          resourceName: jobInfo[resourceType.key][index]?.resource_name,
          jobId: job.job_id,
          isLoaded : false
        })
        if (jobInfo[resourceType.key][index]?.application_num) {
          resourceComplete(jobInfo[resourceType.key][index]?.application_num)
        }
      })
    })
    return resources
  }

  useEffect(() => {
    const curInput = localStorage.getItem('curInput')
    if (curInput && curInput !== "") {
      setInput(curInput)
    }
    const prevChatName = localStorage.getItem('chat_name')
    if (prevChatName && prevChatName !== "") {
      setChatName(prevChatName)
    }
    const prevChatId = localStorage.getItem('chat_id')
    const is_saved = JSON.parse(localStorage.getItem('is_saved'))
    if (prevChatId && prevChatId !== "" && is_saved && is_saved != "") {
      setChatId(prevChatId)
      setIsSaved(true)
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('curInput', input)
  }, [input])

  useEffect(() => {
    if (patents && patents.length > 0)
      localStorage.setItem('patents', JSON.stringify(patents))
  }, [patents])

  useEffect(() => {
    if (searchResults && searchResults !== null) {
      localStorage.setItem('search_results', JSON.stringify(searchResults))
    }
  }, [searchResults])

  function addOrUpdatePatent(newPatent) {
    setPatents(prevPatents => {
      //          removePatent(newPatent["Application Identifier"])
      const filteredPatents = prevPatents.filter(patent => patent["Application Identifier"] !== newPatent["Application Identifier"])
      newPatent.patentJobId = ""
      newPatent.pubJobId = ""
      return [newPatent, ...filteredPatents]
    })
  }

  function showClearChatMessagesWarning() {
    if (!isSaved && messages.length > 1) {
      setShowWarning(true)
    }
    clearChatMessages(true)
  }

  function clearChatMessages(reload_win) {
    delete_the_storage_but_not_auth_ids()
    if (reload_win) {
      window.location.reload()
    }
  }

  async function resourceComplete(applicationNumber) {
    const patent_obj = await getPatentInfo(applicationNumber)
    if (patent_obj["inventors"]) {
      const formatInventor = inventor => {
        return `${inventor.nameLineOne} ${inventor.nameLineTwo}, ${inventor.city} ${inventor.geoCode} ${inventor.country}`
      }
      patent_obj["inventors"] = patent_obj["inventors"].map(formatInventor).join(' ')
    }
    const filter_obj = filterAndMapKeys(patent_obj)
    for (const key in filter_obj) {
      if (key.toLowerCase().includes("date")) {
        filter_obj[key] = filter_obj[key].substring(0, 10)
      }
    }
    const orderedObj = {}

    Object.keys(mapping).forEach(key => {
      if (filter_obj[mapping[key]] !== undefined) {
        orderedObj[mapping[key]] = filter_obj[mapping[key]]
      }
    })
    addOrUpdatePatent(orderedObj)
  }

  const handleAddNewChat = () => {
    setIsModalOpen(true)
  }

  const cancelAddNewChat = () => {
    setIsModalOpen(false)
  }

  const handleUploadFile = () => {
    setIsUploadModalOpen(true)
  }

  const cancelUploadFile = () => {
    setIsUploadModalOpen(false)
  }

  const handleToggleContext = () => {
    setIsContextVisible(!isContextVisible)
  }

  async function saveCurrentChat() {
    const chatData = {
      chat_id: chatIdRef.current,
      folder_id: selectedFolder,
      messages: messagesRef.current,
    }
    const saveChatResponse = await saveChat(chatData)
    try {
      if (saveChatResponse.message && saveChatResponse.message === "success") {
        setIsSaved(true)
        localStorage.setItem("is_saved", true)
        console.log("Chat saved successfully!")
        chatListRef.current.triggerLoadChats()
      }
    }
    catch (e) {
      console.log("Error saving chat.")
    }
  }

   async function resourceUploadComplete(resource_title, resource) {
      const resourceType = resource.type
      if (resourceType === "patent" || resourceType === "publication") {
          const resourceNameParts = resource.resourceName.split("-")
          let relevantNamePart = resourceNameParts.length >= 3 ? resourceNameParts[2] : resource.resourceName
          addChatMessage("I now know information about " + resourceType + " " + relevantNamePart)
      } else {
          addChatMessage("I now know information about " + resourceType + " " + resource_title)
      }
      if (resource) resource.isLoaded = true
  }

  async function confirmAddNewChat() {
    if (chatName === "") {
      setModalErrorMessage("Please enter a name.")
      return
    }
    const chatData = {
      chat_name: chatName,
      messages: messages,
      folder_id: selectedFolder,
    }
    const addNewChatResponse = await addNewChat(chatData)
    if (addNewChatResponse.message && addNewChatResponse.chat_id) {
      console.log("Chat added successfully!!")
      try {
        chatListRef.current.triggerLoadChats()
      }
      catch {

      }
      setIsSaved(true)
      localStorage.setItem("is_saved", true)
      localStorage.setItem('chat_name', chatName)
      localStorage.setItem('chat_id', addNewChatResponse.chat_id)
      setChatId(addNewChatResponse.chat_id)
    }
    setIsModalOpen(false)
  }

  const addNewResources = (jobIds, type, resourceNames) => {
    let newResources = []
    for (let i = 0; i < resourceNames.length; i++) {
      let new_resource = {
        "type": type,
        "resourceName": resourceNames[i],
        "jobId": jobIds[i],
        "resourceIsLoaded": false
      }
      newResources.push(new_resource)
    }
    setResources(prevResources => [...prevResources, ...newResources])

  }

  const addTextToInput = (text_input) => {
    setInput((input + " " + text_input).trim())
  }

  const updateJobId = (applicationNumber, newPatentJobId, newPubJobId) => {
    setPatents((prevPatents) => {
      return prevPatents.map((patent) => {
        if (patent["Application Identifier"] === applicationNumber) {
          return {
            ...patent,
            patentJobId: newPatentJobId !== "" ? newPatentJobId : patent.patentJobId,
            pubJobId: newPubJobId !== "" ? newPubJobId : patent.pubJobId,
          }
        }
        return patent
      })
    })
  }

  function renderMessageWithStyledReferences(message, isUser) {
    const patterns = [
      { regex: /MPEP\s(?:Section|§)?\s?(\d{3,4})(\.\d+[A-Za-z]?)?/ig, type: 'mpep' },
      { regex: /(?<!\d)(\d{7,8})(?!\d)/g, type: 'number' },
      { regex: /\b((?:US)?\d{4}0\d{6}|\d{10})\b/ig, type: 'publication' },
      {
        regex: /\bhttps?:\/\/[A-Za-z0-9-]+(?:\.[A-Za-z0-9-]+)*\.(ai|com|co|chat|net|org|gov|edu|info|io|tech)\b|(?<=\s|^)[A-Za-z0-9-]+\.(ai|com|co|chat|net|org|gov|edu|info|io|tech)\b(?!@)/ig,
        type: 'url'
      }
    ]
    let parts = [], lastIndex = 0
    const combinedMatches = []
    patterns.forEach(({ regex, type }) => {
      if (typeof message === 'string') {
        const matches = message.matchAll(regex)
        for (const match of matches) {
          let number = match[1]?.replace(/[^\d]/g, '') || ''
          let numericValue = parseInt(number, 10) || 0
          if (type === 'number') {
            if (numericValue <= 13000000) {
              combinedMatches.push({ match: match[0], number, offset: match.index, type: 'patent' })
            } else if (numericValue > 13000000) {
              combinedMatches.push({ match: match[0], number, offset: match.index, type: 'application' })
            }
          } else {
            combinedMatches.push({ match: match[0], number, offset: match.index, type })
          }
        }
      }
    })
    combinedMatches.sort((a, b) => a.offset - b.offset)
    combinedMatches.forEach(({ match, number, offset, type }, index) => {
      if (offset > lastIndex) {
        parts.push(message.substring(lastIndex, offset))
      }
      let onClickHandler
      if (type === 'url') {
        const urlForOpen = match.startsWith('http://') || match.startsWith('https://') ? match : `http://${match}`
        onClickHandler = () => window.open(urlForOpen, '_blank')
      } else {
        onClickHandler = () => handleReferenceClick(number, type, number)
      }
      const className = type === 'url' ? "text-primary underline cursor-pointer" : `${isUser ? "text-white underline" : type === 'patent' ? "font-bold" : "text-primary"} cursor-pointer`
      const element = type === 'url' ?
        <a key={`${type}-${index}`} className={className} onClick={onClickHandler} style={{ cursor: 'pointer' }}>{match}</a> :
        <span key={`${type}-${index}`} className={className} onClick={onClickHandler}>{match}</span>
      parts.push(element)
      lastIndex = offset + match.length
    })
    if (lastIndex < message.length) {
      parts.push(message.substring(lastIndex))
    }
    return <>{parts}</>
  }

  const scrollToBottom = () => {
    const { scrollHeight } = scrollDivRef.current
    scrollDivRef.current.scrollTo({
      top: scrollHeight,
      behavior: 'smooth'
    })
  }

  const scrollToTop = () => {
    scrollDivRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const handleSendPositiveFeedback = (messageText, index) => {
    sendMessageFeedback(messageText, true)
    showFeedbackToastMessage(index, "Feedback sent!")
  }

  const handleSendNegativeFeedback = (messageText, index) => {
    sendMessageFeedback(messageText, false)
    showFeedbackToastMessage(index, "Feedback sent!")
  }

  const deleteMessage = (index) => {
    setMessages(currentMessages => {
      const newMessages = currentMessages.filter((_, i) => i !== index)
      localStorage.setItem('history', JSON.stringify(newMessages))
      return newMessages
    })
  }

  const [paragraphNumberToScrollTo, setParagraphNumberToScrollTo] = useState(null)

  const handleParagraphClick = (clickedType, clickedDocNumber, paragraphNumber) => {
    if (clickedType === type && clickedDocNumber === docNumber) {
      if (showDocWindow) {
        scrollToParagraph(paragraphNumber)
      } else {
        setSelectedSection("document")
        scrollToParagraph(paragraphNumber)
      }
    } else {
      setType(clickedType)
      setDocNumber(clickedDocNumber)
      setSelectedSection("document")
      setParagraphNumberToScrollTo(paragraphNumber)
    }
  }

  const scrollToParagraph = (paragraphNumber) => {
    const element = document.getElementById("paragraph-" + paragraphNumber)
    setParagraphNumberToScrollTo(paragraphNumber)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const handleScrollToDrawing = (sheet_number) => {
    const element = document.getElementById(`drawing-${sheet_number}`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const [claimNumberToScrollTo, setClaimNumberToScrollTo] = useState(null)

  const handleClaimClick = (clickedType, clickedDocNumber, claimNumber) => {
    if (clickedType === type && clickedDocNumber === docNumber) {
      if (showDocWindow) {
        scrollToClaim(claimNumber)
      } else {
        setClaimNumberToScrollTo(claimNumber)
        setSelectedSection("document")
        scrollToClaim(claimNumber)
      }
    } else {
      setType(clickedType)
      setDocNumber(clickedDocNumber)
      setClaimNumberToScrollTo(claimNumber)
      setSelectedSection("document")
    }
  }

  const scrollToClaim = (claimNumber) => {
    const element = document.getElementById("claim-" + claimNumber)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const [showFiguresInDocWindow, setShowFiguresInDocWindow] = useState(null)

  const handleFigureClick = (clickedType, clickedDocNumber, figureNumber) => {
    if (clickedType === type && clickedDocNumber === docNumber) {
      setShowFiguresInDocWindow(true)
      setSelectedSection("document")
    } else {
      setType(clickedType)
      setDocNumber(clickedDocNumber)
      setShowFiguresInDocWindow(true)
      setSelectedSection("document")
    }
  }

  const clickMsgCite = (tagType, type, id, num) => {
    switch (tagType) {
      case 'paragraph':
        handleParagraphClick(type, id, num)
        break
      case 'claim':
        handleClaimClick(type, id, num)
        break
      case 'figure':
        handleFigureClick(type, id, num)
        break
      default:
        console.log(`Unknown tagType: ${tagType}`)
    }
  }

  const replaceTagsWithNumbers = (html) => {
    const regex = /<Rsrc([^>]*)\/>/g
    const parts = []
    let lastIndex = 0
    let match
    while ((match = regex.exec(html)) !== null) {
      const fullMatch = match[0]
      const offset = match.index
      const attributes = match[1]
      const numTypeMatch = attributes.match(/num_type="([^"]+)"/)
      const numMatch = attributes.match(/num="([^"]+)"/)
      const rsrcTypeMatch = attributes.match(/rsrc_type="([^"]+)"/)
      const rsrcIdMatch = attributes.match(/rsrc_id="([^"]+)"/)
      if (numTypeMatch && numMatch && rsrcTypeMatch && rsrcIdMatch) {
        const numType = numTypeMatch[1]
        const num = numMatch[1]
        const rsrcType = rsrcTypeMatch[1]
        const rsrcId = rsrcIdMatch[1]
        const cleanNum = num.replace(/^p-0*/, '').replace(/^0+/, '')
        parts.push(html.substring(lastIndex, offset))
        parts.push(
          `<rsrc numType="${numType}" cleanNum="${cleanNum}" rsrcType="${rsrcType}" rsrcId="${rsrcId}">${cleanNum}</Rsrc>`
        )
        lastIndex = offset + fullMatch.length
      } else {
        parts.push(html.substring(lastIndex, offset + fullMatch.length))
        lastIndex = offset + fullMatch.length
      }
    }
    parts.push(html.substring(lastIndex))
    return parts.join('')
  }

  const loadFolders = async () => {
    try {
      const fetchedFolders = await fetchFolders()
      setFolders(fetchedFolders.folders)
    } catch (error) {
      console.error("Failed to load folders:", error)
    }
  }

  const [placeBarBehind, setPlaceBarBehind] = useState(false)
  useEffect(() => {
    const shouldPlaceBarBehind = hideDragBars || isModalOpen || deleteMessageIndex || isUploadModalOpen || showDisclaimer || showGuide || showSettings || showCustomSummaryResponses
    setPlaceBarBehind(shouldPlaceBarBehind)
  }, [hideDragBars, isModalOpen, deleteMessageIndex, isUploadModalOpen, showDisclaimer, showGuide, showSettings, showCustomSummaryResponses])

  const CustomRsrc = ({ numType, cleanNum, rsrcType, rsrcId, children }) => (
    <span
      title={`click to view ${numType} ${cleanNum}`}
      className="inline text-blue-500 underline cursor-pointer"
      onClick={() => clickMsgCite(numType, rsrcType, rsrcId, cleanNum)}
    >
      {children}
    </span>
  )

  const components1 = {
    ul: ({node, ...props}) => <ul className="list-disc leading-normal space-y-1" {...props} />,
    ol: ({node, ...props}) => <ol className="list-decimal leading-normal space-y-1" {...props} />,
    li: ({node, ...props}) => <li className="ml-4" {...props} />,
    rsrc: ({ node, ...props }) => {
      const { cleannum, numtype, rsrctype, rsrcid } = node.properties
      return (
        <CustomRsrc numType={numtype} cleanNum={cleannum} rsrcType={rsrctype} rsrcId={rsrcid}>
          {props.children}
        </CustomRsrc>
      )
    }
  }

  return (
    <div>
      <div  className={`fixed overflow-y-auto top-0 left-0 h-[calc(100vh)] custom-scroll ${theme === 'dark' ? 'bg-gray-900' : 'bg-gray-100'}`}>
        <VerticalNavigationBar onSelect={handleSelect} selectedSection={selectedSection} theme={theme} />
      </div>
      <div className={`flex pl-2 ml-[78px] overflow-y-hidden ${theme === 'dark' ? '' : ''}`}>
        {selectedSection === "builder" && (<PromptBuilder contextData={contextData} addToResources={addToResources} />)}
        {selectedSection === 'saved_chats' && (
          <SavedChats username={username} clearChatMessages={clearChatMessages} setSelectedSection={setSelectedSection}
            setCurrentChat={handleCurrentChatChange} folders={folders} setFolders={setFolders} loadFolders={loadFolders} />
        )}
        {(selectedSection === 'saved_chats' || showChatListWindow) && false && (<div className={`px-2 py-1 min-w-[calc(300px)] relative ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
          <div className='flex-col relative flex-col ml-2 text-sm'>
            <ChatList setCurrentChat={handleCurrentChatChange} username={username} ref={chatListRef} />
          </div>
        </div>)}
        {selectedSection === 'chat' && (<div className={`flex relative items-center left-0 h-screen flex-col max-w-[calc(100vw-72px)] min-w-[450px] max-h-[calc(100vh)] overflow-hidden shadow-lg
          ${loading ? styles.colorCycleReverse : (theme === 'dark' ? 'bg-gray-800 text-white' : '')}`}>
          <div ref={scrollDivRef} className='flex-grow overflow-y-auto py-1 px-2 space-y-4'>
          <div className="cursor-pointer inline-block absolute top-0 right-0 mt-2 mr-2" title="Dual Encryption with HTTPS & End-to-End In-App Encryption" onClick={() => setShowDataInfo(true)}>
            <div className="relative">
              <FontAwesomeIcon icon={faLock} className={`${theme === 'dark' ? 'text-white hover:text-black' : 'text-black hover:text-white'} absolute border-black border-1 top-0 right-0 text-xl`} />
              <FontAwesomeIcon icon={faLock} className={`${theme === 'dark' ? 'text-secondary hover:text-secondary-light' : 'text-gray-500 hover:text-black'} absolute border-black border-1 top-1 right-1 text-xl`} />
          </div>
          </div>
            {messages.map((messageObj, idx) => (
              <div key={idx} className={`flex ${messageObj.role === 'user' ? 'justify-end' : 'justify-start'}`}
                title={messageObj.hasOwnProperty("receiveTime") ?
                        `${new Date(messageObj.receiveTime).toLocaleString()}` :
                        ``
                      }
              >
                <div className={`min-w-[calc(250px)] max-w-[calc(100%-100px)] max-w-[calc(60%)] relative p-2 rounded-lg max-w-[72%]
                ${messageObj.role === 'user' ? (theme === 'dark' ? 'bg-gray-600 text-gray-100' : 'text-black') : (theme === 'dark' ? 'bg-gray-800 text-white' : 'text-black')}`}>
                  {messageObj.role === 'user' && messageObj.hasOwnProperty("username") && idx != 0 && (
                    <div className="absolute left-1 top-1">
                      <div className="pl-1 text-gray-600 hover:text-gray-100 text-sm italic" title={messageObj.hasOwnProperty("sendTime") ?
                        `${messageObj.username} sent this message at ${new Date(messageObj.sendTime).toLocaleString()}` :
                        `${messageObj.username}`}>{messageObj.username}
                      </div>
                    </div>
                  )}
                  {messageObj.role === 'assistant' && idx != 0 && (
                    <div className="absolute w-full top-1 flex items-center space-x-1">
                      <div className="absolute left-1 top-1">
                        <button
                          onClick={() => handleSendPositiveFeedback(messageObj.content, idx)}
                          className="text-xs py-1 mr-1 px-2 bg-green-700 text-white rounded-md cursor-pointer opacity-75 hover:opacity-100"
                        >
                          <FaThumbsUp />
                        </button>
                        <button
                          onClick={() => handleSendNegativeFeedback(messageObj.content, idx)}
                          className="text-xs py-1 px-2 bg-red-700 text-white rounded-md cursor-pointer opacity-75 hover:opacity-100"
                        >
                          <FaThumbsDown />
                        </button>
                      </div>
                      {messageObj.hasOwnProperty("context") && (
                        <div className="text-xs py-1 pl-16 px-2 rounded-md cursor-pointer">
                          <MessageResponseContext info={messageObj["context"]} scrollToParagraph={handleParagraphClick} scrollToClaim={handleClaimClick} />
                        </div>
                      )}
                      {feedbackToasts[idx] && (
                        <div className="w-fit transform translate-y-2 group-hover:translate-y-0 transition-all bg-primary-3-quarters text-white px-2 py-2 rounded shadow-lg text-xs z-20">
                          {feedbackToasts[idx]}
                        </div>
                      )}
                    </div>
                  )}
                  {idx != 0 && (<div className="absolute right-1 top-1">
                    <button
                      onClick={() => copyMessageToClipboard(messageObj.content, idx)}
                      className="text-xs py-1 px-2 bg-gray-700 text-white rounded-md cursor-pointer opacity-75 hover:opacity-100"
                    >
                      Copy
                    </button>
                    {messageObj.role === "user" && (<button
                      disabled={loading}
                      onClick={() => resubmitMessage(messageObj.content, idx)}
                      className="ml-1 text-xs py-1 px-2 bg-gray-700 text-white rounded-md cursor-pointer opacity-75 hover:opacity-100"
                    >
                      Resend
                    </button>)}
                    <button title="Delete message"
                      onClick={() => setDeleteMessageIndex(idx)}
                      className="ml-1 text-xs text-white hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
                    >
                      <RedX />
                    </button>
                    {toasts[idx] && (
                      <div className="w-fit transform translate-y-2 group-hover:translate-y-0 transition-all bg-blue-600 text-white px-2 py-2 rounded shadow-lg text-xs z-20">
                        {toasts[idx]}
                      </div>
                    )}
                  </div>)}
                  {/*renderMessageWithStyledReferences(messageObj.content, messageObj.role === 'user')*/}
                  {/* <div className={`${idx == 0 ? "pt-1" : "pt-6"} w-full max-w-full overflow-x-auto`}><ReactMarkdown key={idx} children={messageObj.content.replace(/\n{1,}/g, '\n')} components={components} /></div> */}
                  <div className={`${idx === 0 ? "pt-1" : "pt-6"} w-full max-w-full overflow-x-auto`}>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} components={components1}>
                      {replaceTagsWithNumbers(messageObj.content)}
                    </ReactMarkdown>
                  </div>
                  {/*<div className={`${idx == 0 ? "pt-1" : "pt-6"}`}>{replaceTagsWithNumbers(messageObj.content.replace(/\n{1,}/g, '\n'))}</div>*/}
                  {messageObj.is_op && messageObj.is_op === true && (
                    <button
                      onClick={() => setDisplayOperationResponse(true)}
                      className="ml-1 text-xs py-1 px-2 bg-gray-700 text-white rounded-md cursor-pointer opacity-75 hover:opacity-100"
                    >
                      View Responses
                    </button>
                  )}
                  {messageObj.is_issues && messageObj.is_issues === true && (
                    <button
                      onClick={() => { setCurIssueAppNo(messageObj.app_no); setDisplayIssues(true) }}
                      className="ml-1 text-xs py-1 px-2 bg-gray-700 text-white rounded-md cursor-pointer opacity-75 hover:opacity-100"
                    >
                      View Issues
                    </button>
                  )}
                  {messageObj.is_pros_hist && messageObj.is_pros_hist === true && (
                    <button
                      onClick={() => { setProsHist(messageObj.op_response); setDisplayProsHist(true) }}
                      className="ml-1 text-xs py-1 px-2 bg-gray-700 text-white rounded-md cursor-pointer opacity-75 hover:opacity-100"
                    >
                      View Prosecution History Data
                    </button>
                  )}
                  {messageObj.is_spec_map && messageObj.is_spec_map === true && (
                    <button
                      onClick={() => { setSpecMap(messageObj.op_response); setDisplaySpecMap(true) }}
                      className="ml-1 text-xs py-1 px-2 bg-gray-700 text-white rounded-md cursor-pointer opacity-75 hover:opacity-100"
                    >
                      View Specification Feature Map
                    </button>
                  )}
                  {messageObj.is_parts_list && messageObj.is_parts_list === true && (
                    <button
                      onClick={() => { setPartsList(messageObj.op_response); setDisplayPartsList(true) }}
                      className="ml-1 text-xs py-1 px-2 bg-gray-700 text-white rounded-md cursor-pointer opacity-75 hover:opacity-100"
                    >
                      View Parts List
                    </button>
                  )}
                </div>
              </div>
            ))
            }
            {loading && (
              <div className='justify-start'>
                <div className='p-2 rounded-lg flex max-w-[80%] w-fit pr-6 pl-6 h-10 items-center pl-3 bg-gray-200 text-black border-gray-400 border'>
                  <TypingDots />
                </div>
              </div>
            )}
            <div ref={messagesEndRef}></div>
            {(showBottomButton || showTopButton) && (
              <div className="flex-end inline pl-4 justify-between sticky bottom-1 w-fit">
                {showBottomButton && <button onClick={scrollToBottom} className={`inline text-gray-400 hover:text-primary hover:bg-primary-half rounded-full px-2 py-1 text-xs transition-colors duration-300 ease-in-out ml-2 ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>Scroll to Bottom</button>}
                {showTopButton && <button onClick={scrollToTop} className={`inline text-gray-400 hover:text-primary hover:bg-primary-half rounded-full px-2 py-1 text-xs transition-colors duration-300 ease-in-out ml-2 ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>Scroll to Top</button>}
              </div>
            )}
            {isSaved && chatName && chatName !== "" && (<div className="inline pl-4 flex-end text-sm bold italic text-blue-400">
              saved as {chatName}
            </div>)}
            {!isSaved && (<div className="inline pl-4 flex-end inline-block text-sm italic text-red-300">
              not saved
            </div>)}
            {!isSaved && chatId !== "" && (
              <button
                onClick={saveCurrentChat}
                className="bg-transparent inline pl-2 text-gray-400 hover:text-primary hover:bg-primary-half rounded-full px-2 py-1 text-xs transition-colors duration-300 ease-in-out ml-2"
              >
                Save <span className='text-xs not-italic'>(ctrl + s)</span>
              </button>)}
            <button
              onClick={() => setShowFeedbackWindow(true)}
              className={`bg-transparent ${theme === 'dark' ? 'text-gray-400 hover:bg-primary-1-quarter hover:text-white' : 'text-gray-700 hover:bg-primary-half hover:text-white'} rounded-full px-2 py-1 text-xs transition-colors duration-300 ease-in-out right-1 ml-2`}
            >
              <span className='text-xs not-italic'>send feedback</span>
            </button>
          </div>
          {isContextVisible && (
            <ContextSummary onReferenceClick={handleReferenceClick} contextData={contextData} setContextData={setContextData}
              addToResources={addToResources} removedResources={removedResources} setRemovedResources={setRemovedResources}
              resources={resources} completeCallback={resourceUploadComplete} handleUploadFile={handleUploadFile} />
          )}
          <div className={`border-t p-4 rounded w-full ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-primary-1-quarter text-gray-900'}`}>
            <div className='flex space-x-4'>
              <textarea
                className={`flex-grow border rounded p-2 ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleEnterKeyDown}
                placeholder='Type a message...'
              />
              <button onClick={handleSend} disabled={loading} className={`rounded relative ${theme === 'dark' ? 'bg-gray-800 text-white border-white border' : ''} text-white`}>
                <div className='px-4 py-8 rounded inset-0 hover:bg-primary hover:text-white'>Send</div>
              </button>
            </div>
          </div>
          <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2'>
            <button
              onClick={showClearChatMessagesWarning}
              className={`bg-transparent ${theme === 'dark' ? 'text-gray-400' : 'text-primary'} text-sm hover:text-red-500 hover:bg-red-100 rounded-full px-6 py-1 transition-colors duration-300 ease-in-out w-full md:w-auto`}
            >
              Clear Chat Messages
            </button>
            <button
              onClick={handleAddNewChat}
              className={`text-sm ${theme === 'dark' ? 'text-gray-400 hover:bg-primary-1-quarter hover:text-white' : 'text-primary hover:bg-primary-half hover:text-white'}  rounded-full px-6 py-1 transition-colors duration-300 ease-in-out w-full md:w-auto`}
            >
              Save as New Chat
            </button>
          </div>
        </div>)}
        {selectedSection === "resources" && (<ResourcesPage resourceNumber={resourceNumber} resourceType={resourceType} setResourceNumber={setResourceNumber} setResourceType={setResourceType} />)}
        {selectedSection === "drawings" && (<div className="bg-primary flex flex-col"><DrawingsViewer type={type} docNumber={docNumber} docName={docName} addTextToMessageInput={addTextToInput}
                scrollToParagraphFn={scrollToParagraph} paragraphNumberToScrollTo={paragraphNumberToScrollTo}
                scrollToClaimFn={scrollToClaim} claimNumberToScrollTo={claimNumberToScrollTo}
                showFiguresInDocWindow={showFiguresInDocWindow} handleScrollToDrawing={handleScrollToDrawing}
                setIsSuppContextVisible={setIsSuppContextVisible} contextData={contextData}
        />
          {isSuppContextVisible && (<ContextSummary onReferenceClick={handleReferenceClick} contextData={contextData} setContextData={setContextData}
              addToResources={addToResources} removedResources={removedResources} setRemovedResources={setRemovedResources}
              resources={resources} completeCallback={resourceUploadComplete} handleUploadFile={handleUploadFile} />
        )}</div>
        )}
        {selectedSection === "document" && (<div className="bg-primary flex-col"><DocumentViewer type={type} setType={setType} docNumber={docNumber} setDocNumber={setDocNumber} docName={docName} addTextToMessageInput={addTextToInput}
                scrollToParagraphFn={scrollToParagraph} paragraphNumberToScrollTo={paragraphNumberToScrollTo}
                scrollToClaimFn={scrollToClaim} claimNumberToScrollTo={claimNumberToScrollTo}
                showFiguresInDocWindow={showFiguresInDocWindow} handleScrollToDrawing={handleScrollToDrawing}
                setIsSuppContextVisible={setIsSuppContextVisible} setSelectedSection={setSelectedSection}
                contextData={contextData} setContextData={setContextData} setRemovedResources={setRemovedResources}
        >
            <ContextSummary onReferenceClick={handleReferenceClick} contextData={contextData} setContextData={setContextData}
              addToResources={addToResources} removedResources={removedResources} setRemovedResources={setRemovedResources}
              resources={resources} completeCallback={resourceUploadComplete} handleUploadFile={handleUploadFile} />
        </DocumentViewer>
          {isSuppContextVisible && (<ContextSummary onReferenceClick={handleReferenceClick} contextData={contextData} setContextData={setContextData}
              addToResources={addToResources} removedResources={removedResources} setRemovedResources={setRemovedResources}
              resources={resources} completeCallback={resourceUploadComplete} handleUploadFile={handleUploadFile} />
        )}</div>
        )}
        {showDocWindow && (<>
          <div className="relative z-10">
            <DraggableCore axis="x" onDrag={onControlledDrag} onStop={onStop} onStart={onStart} position={controlledPosition} >
              <div ref={draggableRef} className={`relative h-screen w-2 cursor-col-resize ${theme === 'dark' ? 'bg-gray-300' : 'bg-primary-half'} ${(placeBarBehind) ? '' : 'z-0'}`}>
              </div>
            </DraggableCore>
          </div>
          <div className={`max-h-[calc(100vh-20px)] bg-gray-100 pt-8 overflow-y-hide flex ${theme === 'dark' ? 'text-white bg-gray-900' : 'bg-white '}`} >
            <div className={`relative rounded text-sm flex-grow`} style={{ width: flexWidth }}>
              <button
                onClick={() => { setSelectedSection("applications") }}
                className="absolute top-0 right-0 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
              >
                <RedX />
              </button>
              <DocumentViewer type={type} setType={setType} docNumber={docNumber} setDocNumber={setDocNumber} docName={docName} addTextToMessageInput={addTextToInput}
                scrollToParagraphFn={scrollToParagraph} paragraphNumberToScrollTo={paragraphNumberToScrollTo}
                scrollToClaimFn={scrollToClaim} claimNumberToScrollTo={claimNumberToScrollTo}
                showFiguresInDocWindow={showFiguresInDocWindow} handleScrollToDrawing={handleScrollToDrawing}
                setIsSuppContextVisible={setIsSuppContextVisible} setSelectedSection={setSelectedSection}
                contextData={contextData} setContextData={setContextData} setRemovedResources={setRemovedResources}
                >
                  <ContextSummary onReferenceClick={handleReferenceClick} contextData={contextData} setContextData={setContextData}
                    addToResources={addToResources} removedResources={removedResources} setRemovedResources={setRemovedResources}
                    resources={resources} completeCallback={resourceUploadComplete} handleUploadFile={handleUploadFile} />
              </DocumentViewer>
            </div>
          </div>
        </>)}
        {showSettings && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <SettingsWindow userSettings={userSettings} closeShowSettings={() => setShowSettings(false)} onThemeChange={onThemeChange} currentTheme={theme} />
          </div>
        )}
        {showGuide && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <QuickStartGuide closeQuickGuide={() => setShowGuide(false)} addTextToMessageInput={(text) => console.log(`Add this text to message input: ${text}`)} setShowPolicy={setShowPolicy} setShowTermsOfUse={setShowTermsOfUse} />
          </div>
        )}
        {showDataInfo && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <DataInfo theme={theme} closeFn={() => setShowDataInfo(false)} setShowPolicy={setShowPolicy} setShowTermsOfUse={setShowTermsOfUse} />
          </div>
        )}
        {showPolicy && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full max-h-[calc(100vh-60px)] my-2 overflow-y-auto custom-scroll flex items-center justify-center bg-black bg-opacity-50">
            <div className="max-h-full overflow-y-auto p-4">
              <PrivacyPolicy theme={theme} closeFn={() => setShowPolicy(false)} />
            </div>
          </div>
        )}
        {showTermsOfUse && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full max-h-[calc(100vh-60px)] my-2 overflow-y-auto custom-scroll flex items-center justify-center bg-black bg-opacity-50">
            <div className="max-h-full overflow-y-auto p-4">
              <TermsOfUse theme={theme} onClose={() => setShowTermsOfUse(false)} />
            </div>
          </div>
        )}
        {showFeedbackWindow && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <FeedbackForm closeFeedback={() => setShowFeedbackWindow(false)} />
          </div>
        )}
        {showDisclaimer && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <DisclaimerModal onClose={() => setShowDisclaimer(false)} />
          </div>
        )}
        {deleteMessageIndex && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="items-center px-4 py-3">
              <button
                className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                onClick={() => { deleteMessage(deleteMessageIndex); setDeleteMessageIndex(null) }}
              >
                Confirm Delete Message
              </button>
              <button
                className="px-4 py-2 bg-primary text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-primary"
                onClick={() => setDeleteMessageIndex(null)}
              >
                Back
              </button>
            </div>
          </div>
        )}
        {isModalOpen && (
          <div className="fixed z-10 inset-0 flex overflow-y-auto">
            <div className="flex relative items-center justify-center w-full min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              <div className={`inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
                <div>
                  <h3 className={`text-lg leading-6 font-medium mb-4 ${theme === 'dark' ? 'text-gray-100' : 'text-gray-900'}`}>
                    Name Your Chat
                  </h3>
                  <input
                    type="text"
                    value={chatName}
                    onChange={(e) => setChatName(e.target.value)}
                    className={`block p-2.5 w-full text-sm rounded-lg border ${theme === 'dark' ? 'bg-gray-600 border-gray-300 text-gray-200 focus:ring-gray-500 focus:border-gray-500' : 'border-gray-300 text-gray-900 bg-gray-50 focus:ring-primary focus:border-primary'}`}
                    placeholder="Enter chat name"
                  />
                </div>
                <div className="grid grid-cols-1 gap-4 mt-2 pt-1">
                  Select a folder (optional):
                  {folders.map(folder => (
                    <button
                      key={folder.folder_id}
                      onClick={() => setSelectedFolder(folder.folder_id)}
                      className={`cursor-pointer flex justify-center items-center w-full rounded-md px-4 py-2 text-sm
                        focus:outline-none transition ease-in-out duration-150
                        ${folder.folder_id === selectedFolder ?
                          (theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-blue-500 text-white') :
                          (theme === 'dark' ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300')}`}
                    >
                      {folder.folder_name}
                    </button>
                  ))}
                </div>
                <div className="mt-5 sm:mt-6">
                  {modalErrorMessage && (
                    <div className="text-red-500 text-center text-sm bg-red-100 p-2 rounded mb-2">{modalErrorMessage}</div>
                  )}
                  <span className="flex w-full rounded-md shadow-sm">
                    <button onClick={confirmAddNewChat} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Confirm
                    </button>
                    <button onClick={cancelAddNewChat} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Cancel
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {isUploadModalOpen && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className={`inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
                <div>
                  <FileInput completeCallback={resourceUploadComplete} addResources={addNewResources} />
                </div>
                <div className="mt-5 sm:mt-6">
                  {modalErrorMessage && (
                    <div className="text-red-500 text-center text-sm bg-red-100 p-2 rounded mb-2">{modalErrorMessage}</div>
                  )}
                  <span className="flex w-full rounded-md shadow-sm">
                    <button onClick={cancelUploadFile} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Close Window
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {(showPatentList || selectedSection === "applications") && patents && patents.length > 0 && (<>
          <div className={`relative ${placeBarBehind ? 'z-1' : 'z-10'}`}>
            <DraggableCore axis="x" onDrag={onControlledDrag} onStop={onStop} onStart={onStart} position={controlledPosition} >
              <div ref={draggableRef} className={`relative h-screen z-0 w-2 cursor-col-resize ${theme === 'dark' ? 'bg-gray-300' : 'bg-primary-half'} ${placeBarBehind ? '' : 'z-0'}`}>
              </div>
            </DraggableCore>
          </div>
          <div className={`max-h-[calc(100vh)] pt-2 overflow-y-hide flex ${theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-primary-1-quarter'}`} >
            <div className={`relative rounded text-sm flex-grow`} style={{ width: flexWidth }}>
              <div className="mt-8">
                {patents.map((patent, index) => {
                  return (
                    <PatentInfoComponent
                      key={patent["Application Identifier"]}
                      patentInfo={patent}
                      patentJobId={patent.patentJobId}
                      pubJobId={patent.pubJobId}
                      onRemove={removePatent}
                      setChatMessage={addChatMessage}
                      updateJobId={updateJobId}
                      onDocClick={handleReferenceClick}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </>)}
        {!showCustomSummaryResponses && customSummaryResponses != null && (
          <button className="bg-primary text-white p-2 rounded" onClick={() => { setShowCustomSummaryResponses(true) }}>Show Bulk Prompt Window</button>
        )}
        {showCustomSummaryResponses && (
          <div className="fixed m-0 z-10 items-center justify-center w-full top-0 left-0 h-full flex bg-black bg-opacity-50">
            <div className={`p-4 m-4 overflow-y-auto relative rounded-lg shadow-md max-h-[calc(100vh-50px)]`}>
              <button
                onClick={() => { setShowCustomSummaryResponses(false) }}
                className="absolute top-0 right-0 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
              >
                <RedX />
              </button>
              <p className="mb-4 font-bold">Bulk Custom Prompt Responses</p>
              {customPrompt && <div className="text-lg font-semibold text-gray-800">Prompt: <span className="font-normal">{customPrompt}</span></div>}
              <div className="flex-col space-x-4 max-h-[calc(100vh-150px)]">
                {customSummaryResponses.map((doc) => (
                  <CustomQuestionResponse
                    key={doc.doc_id}
                    docId={doc.doc_id}
                    docType={doc.doc_type}
                    queryId={doc.message_id}
                    updateMasterMessages={updateMasterMessages}
                  />
                ))}
                <button className="bg-primary text-white p-2 rounded" onClick={downloadToExcel}>Download to Excel</button>
              </div>
            </div>
          </div>
        )}
        {displayOperationResponse && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100">
            <div className='overflow-x-hidden flex-grow overflow-y-auto max-h-[calc(100vh-45px)] px-4 py-2'>
              <button
                onClick={() => setDisplayOperationResponse(false)}
                className="absolute top-2 right-2 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
              >
                <RedX />
              </button>
              <div className={`p-4 rounded-lg shadow-md ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
                <IssueResponseList issues={operationResponse} />
                <div className="flex space-x-4 mt-4">
                  <button
                    onClick={() => setDisplayOperationResponse(false)}
                    className="bg-primary bg-red-500 text-white p-2 rounded hover:bg-red-100 hover:text-red-700"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
        }
        {displayIssues && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100">
            <div className='overflow-x-hidden flex-grow overflow-y-auto max-h-[calc(100vh-45px)] px-4 py-2'>
              <button
                onClick={() => setDisplayIssues(false)}
                className="absolute top-2 right-2 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
              >
                <RedX />
              </button>
              <div className={`p-4 rounded-lg shadow-md ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
                <IssueList appNo={curIssueAppNo} issues={issues} />
                <div className="flex space-x-4 mt-4">
                  <button
                    onClick={() => setDisplayIssues(false)}
                    className="bg-primary bg-red-500 text-white p-2 rounded hover:bg-red-100 hover:text-red-700"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
        }
        {displayProsHist && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100">
            <div className='overflow-x-hidden flex-grow overflow-y-auto max-h-[calc(100vh-45px)] px-4 py-2'>
              <button
                onClick={() => setDisplayProsHist(false)}
                className="absolute top-2 right-2 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
              >
                <RedX />
              </button>
              <div className={`p-4 rounded-lg shadow-md ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
                <PatentProsHist data={prosHist} />
                <div className="flex space-x-4 mt-4">
                  <button
                    onClick={() => setDisplayProsHist(false)}
                    className="bg-primary bg-red-500 text-white p-2 rounded hover:bg-red-100 hover:text-red-700"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
        }
        {displaySpecMap && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100">
            <div className='overflow-x-hidden flex-grow overflow-y-auto max-h-[calc(100vh-45px)] px-4 py-2'>
              <button
                onClick={() => setDisplaySpecMap(false)}
                className="absolute top-2 right-2 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
              >
                <RedX />
              </button>
              <div className={`p-4 rounded-lg shadow-md ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
                <SpecificationMap data={specMap} />
                <div className="flex space-x-4 mt-4">
                  <button
                    onClick={() => setDisplaySpecMap(false)}
                    className="bg-primary bg-red-500 text-white p-2 rounded hover:bg-red-100 hover:text-red-700"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
        }
        {displayPartsList && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100">
            <div className='overflow-x-hidden flex-grow overflow-y-auto max-h-[calc(100vh-45px)] px-4 py-2'>
              <button
                onClick={() => setDisplayPartsList(false)}
                className="absolute top-2 right-2 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
              >
                <RedX />
              </button>
              <div className={`p-4 rounded-lg shadow-md ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
                <PartsList parts={partsList} />
                <div className="flex space-x-4 mt-4">
                  <button
                    onClick={() => setDisplayPartsList(false)}
                    className="bg-primary bg-red-500 text-white p-2 rounded hover:bg-red-100 hover:text-red-700"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
        }
        {showWarning && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className={`p-4 rounded-lg shadow-md ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
              <p className="mb-4">Current chat will be lost. Make sure to save first.</p>
              <div className="flex space-x-4">
                <button
                  onClick={() => clearChatMessages(true)}
                  className="bg-red-500 text-white p-2 rounded"
                >
                  Continue
                </button>
                <button
                  onClick={() => setShowWarning(false)}
                  className="bg-primary text-white p-2 rounded"
                >
                  Go Back to Save
                </button>
              </div>
            </div>
          </div>
        )}
        {showAccountWindow && (
          <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <AccountWindow closeAccountWindow={() => setShowAccountWindow(false)} username={username} theme={theme} handleLogout={handleLogout} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatRoom
