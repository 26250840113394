import React, { useState } from 'react'
import RedX from '../component/RedX'

const ContactPopup = ({ isOpen, close }) => {
  const [toastVisible, setToastVisible] = useState(false)
  if (!isOpen) return null
  const copyToClipboard = () => {
    navigator.clipboard.writeText("info@forgen.ai")
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 3000)
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative bg-white p-8 rounded-lg z-10 min-w-64">
        <button
          onClick={close}
          className="absolute top-2 right-2 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
        >
          <RedX />
        </button>
        <h2 className="text-xl mb-4">Contact Information</h2>
        <p>Email us at <b>info@forgen.ai</b>
         {toastVisible ? (
            <span className="ml-2 bg-green-300 text-white p-1 rounded">
              Copied!
            </span>
          ) : (
            <button
              onClick={copyToClipboard}
              className="ml-2 hover:bg-blue-700 p-1 rounded transition duration-150 ease-in-out"
            >
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 16H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2m-6 12h8a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2z"
                ></path>
              </svg>
            </button>
          )}
        </p>
      </div>
    </div>
  )
}

export default ContactPopup
