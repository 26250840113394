import React from 'react'
import DocParagraph from '../../chat_room/DocParagraph'
import ClaimsDoc from './ClaimsDoc'
import PDFTextViewer from '../../chat_room/PDFTextViewer'
import { getParagraphNumber } from '../../util/patent_util'

function ContentSection({
  theme,
  paragraphs,
  claims,
  showClaims,
  type,
  specInfo,
  sectionHeaders,
  pdfData,
  isImageContent,
  imageContent,
  documentContent,
  addTextToMessageInput
}) {

  return (
    <div className="relative pt-8 mt-2 z-0">
      <div id="top-of-content"></div>
      {pdfData && pdfData !== "" && <PDFTextViewer data={pdfData} />}
      {isImageContent && imageContent !== "" && (
        <>
          <div className="text-lg">Uploaded Image:</div>
          <img src={`data:image/jpeg;base64,${imageContent}`} alt="Uploaded" />
        </>
      )}
      {!isImageContent && Object.entries(paragraphs)
        .sort((a, b) => {
          const keyA = parseInt(a[0].replace(/[^\d]/g, ''), 10)
          const keyB = parseInt(b[0].replace(/[^\d]/g, ''), 10)
          return keyA - keyB
        })
        .map(([paragraphNumber, { content }]) => {
          const paraNum = getParagraphNumber(paragraphNumber)
          return (
            <React.Fragment key={paragraphNumber}>
              {sectionHeaders[paraNum] && <div className="text-lg font-bold mt-2" id={`section-${sectionHeaders[paraNum].id}`}>{sectionHeaders[paraNum].text}</div>}
              <DocParagraph
                text={content}
                type={type}
                paraId={paragraphNumber}
                paraNum={paraNum.toString()} // Convert to string if needed for ID or display
                specInfo={specInfo}
                addTextToMessageInput={addTextToMessageInput}
              />
            </React.Fragment>
          )
        })
      }
      {showClaims && <div id="start-of-claims"><ClaimsDoc claims={claims} theme={theme} /></div>}
      {type === "upload" && documentContent}
      <div id="bottom-of-content"></div>
    </div>
  )
}

export default ContentSection
