import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Spinner from '../component/Spinner'
import classNames from 'classnames'

import { getJobStatus } from '../api'

function FileProcessingStatus({ jobId, resourceName, completeCallback }) {
  const [status, setStatus] = useState('Processing')
  const [isLoading, setIsLoading] = useState(true)
  const statusClassName = classNames('text-base', {
    'text-green-500 font-bold': status.startsWith('Complete'),
    'text-blue-500': status === 'Processing',
    'text-red-500': status === 'error',
  })
  useEffect(() => {
    if (jobId !== null && jobId.startsWith('Complete')) {
      setStatus('Complete (already loaded)')
      setIsLoading(false)
      return
    }
    const intervalId = setInterval(async () => {
      try {
        const res = await getJobStatus(jobId)
        setStatus(res.status)
        if (res.status === 'Complete' || res.status === 'error') {
          clearInterval(intervalId)
          if (res.status === 'Complete') {
            completeCallback()
          }
        }
      } catch (error) {
        console.error(error)
      }
    }, 3000)

    return () => clearInterval(intervalId)
  }, [jobId])

  useEffect(() => {
    if (status !== 'Processing' && status !== 'info loaded...loading description') {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [status])

  return (
    <div>
      {resourceName}: {(isLoading || status.startsWith('Processing') || status.startsWith('info loaded')) && <Spinner />}
      <span className={statusClassName}>
        {status.startsWith('Complete') && (
          <img src='green-checkmark.png' alt='' className='inline-flex h-4 pb-1 pl-1 pr-1' />
        )}
        {status.startsWith('error') && (
          <img src='red-x.png' alt='' className='inline-flex h-4 pb-1 pl-1 pr-1' />
        )}
         {status}
      </span>
    </div>
  )
}

FileProcessingStatus.propTypes = {
  jobId: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  completeCallback: PropTypes.func.isRequired,
}

export default FileProcessingStatus
