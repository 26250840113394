import React, { useState, useEffect, useContext } from 'react'
import { fetchSettings, updateSettings } from '../api'
import RedX from '../component/RedX'

const AccountWindow = ({ username, closeAccountWindow, theme, handleLogout }) => {
  const [maxIterationLoops, setMaxIterationLoops] = useState(5)
  const [userSettings, setUserSettings] = useState(null)
  const [updateStatus, setUpdateStatus] = useState({ updated: false, message: '' })
  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settingsResponse = await fetchSettings()
        if (settingsResponse && settingsResponse["user_settings"] === "unknown"){
          const settings = { maxIterationLoops: 5 }
          setUserSettings(settings)
          updateSettings(settings)
          return
        }
        if (settingsResponse && settingsResponse["user_settings"]){
          const settings = settingsResponse["user_settings"]
          setUserSettings(settings)
          setMaxIterationLoops(settings.maxIterationLoops)
        }
      } catch (error) {
        console.error("Failed to load settings:", error)
      }
    }
    loadSettings()
  }, [])
  const fetchUsage = () => {

  }

  const saveChanges = () => {
    try {
      userSettings.maxIterationLoops = maxIterationLoops
      const updateResponse = updateSettings(userSettings)
      setUpdateStatus({ updated: true, message: 'Settings updated successfully!' })
      setTimeout(() => {
        setUpdateStatus({ updated: false, message: '' })
      }, 3000)
    } catch (error) {
      console.error("Failed to save settings:", error);
      setUpdateStatus({ updated: false, message: 'Failed to update settings.' });
    }
  }

  return (
    <div className={`relative max-w-md mx-auto shadow-md rounded-lg p-4 mt-6 min-w-[400px] ${theme === 'dark' ? 'text-white bg-gray-900' : 'bg-white'}`}>
      <div className="flex-inline absolute right-2 hover:bg-red-200 rounded-full p-2 cursor-pointer" title="Close Window" onClick={closeAccountWindow}><RedX /></div>
      <h2 className="text-lg font-semibold border-b pb-2 mb-4">Account Settings</h2>
      <div className="mb-4">
        <span className="italic">username</span><span className="font-bold"> {username}</span>
      </div>
      <div className="mb-4 w-full text-center">
        <span className="italic font-bold">not much to do here yet</span>
      </div>
      <div>
        {updateStatus.updated && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Success! </strong>
            <span className="block sm:inline">{updateStatus.message}</span>
          </div>
        )}
        <button
          onClick={handleLogout}
          className="inline-flex items-center px-4 mt-2 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Logout
        </button>
        <p />
        <button
          onClick={closeAccountWindow}
          className="inline-flex items-center px-4 mt-2 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default AccountWindow
