import CryptoJS from 'crypto-js'

// Encryption key - Keep this secret and manage it securely
const encryptionKey = process.env.REACT_APP_SECRET_KEY

if (!encryptionKey) {
  throw new Error('No encryption key set')
}

// Convert encryption key to a WordArray (CryptoJS format) and ensure it's 32 bytes (256 bits)
const key = CryptoJS.enc.Utf8.parse(encryptionKey.substring(0, 32))

const encryptArrayBuffer = (buffer) => {
  const wordArray = CryptoJS.lib.WordArray.create(buffer)
  const iv = CryptoJS.lib.WordArray.random(16)
  const encrypted = CryptoJS.AES.encrypt(wordArray, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
  const encryptedData = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64)
  return encryptedData
}

// Function to encrypt data
const encryptData = (data) => {
  try {
    // Convert data to a string
    const dataUtf8 = CryptoJS.enc.Utf8.parse(JSON.stringify(data))

    // Generate a random IV (initialization vector)
    const iv = CryptoJS.lib.WordArray.random(16)

    // Encrypt the data
    const encrypted = CryptoJS.AES.encrypt(dataUtf8, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })

    // Combine the IV and ciphertext and encode as base64
    const encryptedData = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64)

    return encryptedData
  } catch (e) {
    throw new Error(`Encryption failed: ${e.message}`)
  }
}

// Function to decrypt data
const decryptData = (encryptedData) => {
  try {
    // Decode the base64-encoded data
    const encryptedWordArray = CryptoJS.enc.Base64.parse(encryptedData)

    // Separate the IV and ciphertext
    const iv = CryptoJS.lib.WordArray.create(encryptedWordArray.words.slice(0, 4))
    const ciphertext = CryptoJS.lib.WordArray.create(encryptedWordArray.words.slice(4))

    // Decrypt the data
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })

    // Convert decrypted data back to a string
    const decryptedData = decrypted.toString(CryptoJS.enc.Utf8)

    return JSON.parse(decryptedData)
  } catch (e) {
    throw new Error(`Decryption failed: ${e.message}`)
  }
}

export { encryptData, decryptData }
