import React, { useState, useEffect, useContext } from 'react'
import { fetchSettings, updateSettings } from '../api'
import RedX from '../component/RedX'
import ThemeContext from '../context/ThemeContext'

const SettingsWindow = ({ closeShowSettings, userSettings, onThemeChange, currentTheme }) => {
  const [maxIterationLoops, setMaxIterationLoops] = useState(5)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [showSaveWarning, setShowSaveWarning] = useState(false)
  const [updateStatus, setUpdateStatus] = useState({ updated: false, message: '' })
  const [themeSelection, setThemeSelection] = useState("system")
  const { theme, setTheme } = useContext(ThemeContext)
  const loadSettings = () => {
    if (userSettings["maxIterationLoops"]){
      setMaxIterationLoops(userSettings["maxIterationLoops"])
    }
    if (userSettings["theme"]){
      let userTheme = userSettings["theme"]
      setThemeSelection(userSettings["theme"])
    }
  }
  useEffect(() => {
    loadSettings()
  }, [])

  const saveChanges = () => {
    try {
      userSettings.maxIterationLoops = maxIterationLoops
      userSettings.theme = themeSelection
      const updateResponse = updateSettings(userSettings)
      setUpdateStatus({ updated: true, message: 'Settings updated successfully!' })
      setUnsavedChanges(false)
      setTimeout(() => {
        setUpdateStatus({ updated: false, message: '' })
      }, 3000)
    } catch (error) {
      console.error("Failed to save settings:", error);
      setUpdateStatus({ updated: false, message: 'Failed to update settings.' });
    }
  }

  //Triggered when in app theme setting value changed
  const handleThemeChange = (e) => {
    setUnsavedChanges(true)
    var inAppTheme = e.target.value
    setThemeSelection(inAppTheme)
    localStorage.setItem('theme', inAppTheme)
    if (inAppTheme === "system") {
      const systemMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
      inAppTheme = (systemMediaQuery.matches ? 'dark' : 'light')
     }
     onThemeChange(inAppTheme)
  }

  const handleCloseShowSettings = () => {
    if (unsavedChanges){
      setShowSaveWarning(true)
      return
    }
    closeShowSettings()
  }

  return (
    <div className={`relative min-w-[400px] max-w-md mx-auto shadow-md rounded-lg p-4 mt-6 ${theme === 'dark' ? 'text-white bg-gray-900 border-gray-200 border' : 'bg-white'}`}>
      <div className="flex-inline absolute right-2 hover:bg-red-200 rounded-full p-2 cursor-pointer" title="Close Settings" onClick={handleCloseShowSettings}><RedX /></div>
      <h2 className="text-lg font-semibold border-b pb-2 mb-4">Settings</h2>
      {/*<div className="mb-4">
        <label htmlFor="maxIterationLoops" className={`block text-sm font-medium ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
          Max Iteration Loops
        </label>
        <input
          id="maxIterationLoops"
          type="number"
          value={maxIterationLoops}
          onChange={(e) => {setMaxIterationLoops(Number(e.target.value)); setUnsavedChanges(true)}}
          min="1"
          max="10"
          className={`mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm ${theme === 'dark' ? 'text-white bg-gray-900 focus:ring-gray-300 focus:border-gray-600 ' : 'bg-white focus:ring-blue-500 focus:border-blue-500 '}`}
        />
        <p className="mt-2 text-sm text-gray-500">
          Set the max number of AI model usage calls per chat message.
        </p>
      </div>*/}
      { /*<div className="mb-4">
        <h3 className="text-md font-semibold mb-2">Chatbot Preferences</h3>
        <div className="flex items-center mb-2">
          <input id="autoRespond" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" /><label htmlFor="autoRespond" className="ml-2 text-sm font-medium text-gray-900">Enable Auto-Response</label>
        </div>
        <div className="flex items-center mb-2">
          <input id="sendTranscripts" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" /><label htmlFor="sendTranscripts" className="ml-2 text-sm font-medium text-gray-900">Send Transcripts to Email</label>
        </div>
      </div>*/}
      <div className="mb-4 mt-4">
        <h2 className="text-md font-semibold border-b pb-2 mb-4">Theme</h2>
        <div>
        <label className="block mb-2">
          <input
            type="radio"
            name="theme"
            value="light"
            checked={themeSelection === 'light'}
            onChange={handleThemeChange}
            className="mr-2"
          />
          Light
        </label>
        <label className="block mb-2">
          <input
            type="radio"
            name="theme"
            value="dark"
            checked={themeSelection === 'dark'}
            onChange={handleThemeChange}
            className="mr-2"
          />
          Dark
        </label>
        <label className="block mb-2">
          <input
            type="radio"
            name="theme"
            value="system"
            checked={themeSelection === 'system'}
            onChange={handleThemeChange}
            className="mr-2"
          />
          System
        </label>
      </div>
      </div>
      <div className="items-center">
        {updateStatus.updated && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Success! </strong>
            <span className="block sm:inline">{updateStatus.message}</span>
          </div>
        )}
        {showSaveWarning && (
          <div className={`border ext-green-700 px-4 py-3 rounded relative ${theme === 'dark' ? 'text-pink-200 bg-gray-900' : 'bg-white text-red'}`} role="alert">
            <strong className="font-bold">Unsaved settings changes.</strong>
            <button
                onClick={closeShowSettings}
                className="inline-flex items-center px-4 mt-2 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Close without saving
              </button>
          </div>
        )}
        <button
          onClick={saveChanges}
          className="inline-flex items-center px-4 mt-2 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Save Changes
        </button>
      </div>
    </div>
  )
}

export default SettingsWindow
