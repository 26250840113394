// customScroll.js

export const getScrollStyles = (theme) => `
  .custom-scroll {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--trackBG);
  }

  .custom-scroll::-webkit-scrollbar {
    width: 12px;
  }

  .custom-scroll::-webkit-scrollbar-track {
    background: var(--trackBG);
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 20px;
    border: 3px solid var(--trackBG);
  }

  :root {
    --trackBG: ${theme === 'dark' ? '#1a202c' : '#12455c'};
    --thumbBG: ${theme === 'dark' ? '#4a5568' : '#32657c'};
  }
`
