import React from 'react'

const SpecificationMap = ({ data }) => {
  const renderCharacteristics = (characteristics) => {
    return characteristics.map((char, index) => (
      <li key={index}>
        {char.feature} <span className="text-gray-500 text-sm">({char.citation})</span>
      </li>
    ))
  }

  const renderChildren = (children) => {
    return Object.keys(children).map((key) => (
      <div key={key} className="ml-6 mt-2 border-l-2 pl-2 border-gray-200">
        <h4 className="font-semibold text-lg">{key.replace(/_/g, ' ')}</h4>
        {children[key].characteristics && (
          <ul className="list-disc ml-4">
            {renderCharacteristics(children[key].characteristics)}
          </ul>
        )}
        {children[key].children && renderChildren(children[key].children)}
      </div>
    ))
  }

  const renderTerms = () => {
    return Object.keys(data).map((key) => (
      <div key={key} className="mt-4">
        <h3 className="font-bold text-xl">{key.replace(/_/g, ' ')}</h3>
        {data[key].characteristics && (
          <ul className="list-disc ml-4">
            {renderCharacteristics(data[key].characteristics)}
          </ul>
        )}
        {data[key].children && renderChildren(data[key].children)}
      </div>
    ))
  }

  return (
    <div className="p-6 bg-gray-100 rounded-lg">
      {renderTerms()}
    </div>
  )
}

export default SpecificationMap
