import React, { useState } from 'react'
import { runCustomAnalysis, getCustomAnalysisResult } from '../api'
import ResourceProcessingStatus from '../component/ResourceProcessingStatus'
import ReactMarkdown from 'react-markdown'


function CustomQuestionResponse({ docId, docType, queryId, updateMasterMessages }){
  const [message, setMessage] = useState('')

  const getAndShowCustomResult  = async (message) => {
      setMessage(message)
      updateMasterMessages(docId, docType, message)
  }

  return (
    <div className="flex flex-col m-4 p-4 bg-white shadow-lg rounded-lg border border-gray-100">
      <div className="text-lg font-semibold text-gray-800">Doc ID: <span className="font-normal">{docId}</span></div>
      <div className="text-lg font-semibold text-gray-800">Doc Type: <span className="font-normal">{docType}</span></div>
      <div className="mt-4">
        <ResourceProcessingStatus
          jobId={queryId}
          resourceName="Custom Analysis"
          completeCallback={getAndShowCustomResult}
          hideName={false}
          isMessage={true}
        />
      </div>
      {message ? (
        <><b>Response: </b><ReactMarkdown>{message}</ReactMarkdown></>
      ) : (
        <p className="mt-4 text-gray-500"><b>... loading response ...</b></p>
      )}
    </div>

  )
}

export default CustomQuestionResponse