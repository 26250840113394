import React, { useState, useEffect, useContext, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faEye, faSpinner, faEyeSlash, faArrowDown, faArrowUp, faTimes, faQuestionCircle, faCommentDots, faBars, faList, faTimesCircle, faFileAlt, faExclamationTriangle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { fetchDocument, getSummary, fetchSpecInfo, getDrawingLinks, getCustomQuestionHistory, customQuestion, addDrawingComponent } from '../api'
import { getMessage, fetchTermMap, summarizeAndCharacterize, fetchAndLoadDocument, refreshDoc, getTermListAndDefinitions, deleteDocument } from '../api'
import { getCustTermListAndDefinitions, deleteTermFromList, lookForCites, getCites, genDrawingSummaries, getDrawingSummaries, getJobStatus } from '../api'
import { addJobToLocalStorage, removeJobFromLocalStorage } from '../util/gen_util'
import { removeCountryAndKindCodes } from '../util/patent_util'
import ResourceSelector from '../chat_room/ResourceSelector'
import ContextSummary from '../chat_room/ContextSummary'
import DocParagraph from '../chat_room/DocParagraph'
import TermListAndDefinitions from '../op/TermListAndDefinitions'
import ClaimsDoc from '../chat_room/doc_viewer/ClaimsDoc'
import PDFTextViewer from '../chat_room/PDFTextViewer'
import CitesList from '../chat_room/doc_viewer/CitesList'
import ScrollableViewer from '../chat_room/doc_viewer/ScrollableViewer'
import DrawingSummary from '../chat_room/doc_viewer/DrawingSummary'
import VerticalDocNavBar from '../chat_room/doc_viewer/VerticalDocNavBar'
import RedX from '../component/RedX'
import Spinner from '../component/Spinner'
import ThemeContext from '../context/ThemeContext'

import DocumentHeader from '../chat_room/doc_viewer/DocumentHeader'
import LoadingIndicator from '../chat_room/doc_viewer/LoadingIndicator'
import SummarySection from '../chat_room/doc_viewer/SummarySection'
import SpecInfoSection from '../chat_room/doc_viewer/SpecInfoSection'
import DrawingsSection from '../chat_room/doc_viewer/DrawingsSection'
import CustomQuestionSection from '../chat_room/doc_viewer/CustomQuestionSection'
import TermListSection from '../chat_room/doc_viewer/TermListSection'
import CitesSection from '../chat_room/doc_viewer/CitesSection'
import ContentSection from '../chat_room/doc_viewer/ContentSection'

const XLSX = require('xlsx')


const startJobInterval = (jobId, docNumber, type, setExtractDrawingInfoStatus, setDrawingInfo, setShowExtractDrawingInfoButton) => {
  const interval = setInterval(() => {
    getJobStatus(jobId).then((drawingSummariesJobResponse) => {
      if (drawingSummariesJobResponse && drawingSummariesJobResponse.status && drawingSummariesJobResponse.status.toLowerCase().startsWith("processing")) {
        const splitJobStatus = drawingSummariesJobResponse.status.split("-")
        if (splitJobStatus.length > 2) {
          setExtractDrawingInfoStatus("Loading...sheet " + splitJobStatus[1] + " out of " + splitJobStatus[2] + " total sheets")
        }
      } else if (drawingSummariesJobResponse && drawingSummariesJobResponse.status && drawingSummariesJobResponse.status.toLowerCase().startsWith("complete")) {
        clearInterval(interval)
        removeJobFromLocalStorage("drawing-summary-extract-jobs", jobId)
        getDrawingSummaries(docNumber, type).then((getDrawingSummariesResponse) => {
          if (getDrawingSummariesResponse && getDrawingSummariesResponse.drawing_summaries) {
            setDrawingInfo((prevDrawingInfo) => ({
              ...prevDrawingInfo,
              drawing_summaries: getDrawingSummariesResponse.drawing_summaries,
            }))
            setExtractDrawingInfoStatus("")
          }
        })
      }
    }).catch((e) => {
      setExtractDrawingInfoStatus("Error extracting")
      setShowExtractDrawingInfoButton(true)
    })
  }, 2000)
}

const genSummaries = (docNumber, type, setExtractDrawingInfoStatus, setDrawingInfo, setShowExtractDrawingInfoButton) => {
  setShowExtractDrawingInfoButton(false)
  setExtractDrawingInfoStatus("Loading... extracting info from drawing sheets")

  genDrawingSummaries(docNumber, type).then((genDrawingSummariesResponse) => {
    if (genDrawingSummariesResponse && genDrawingSummariesResponse.job_id) {
      addJobToLocalStorage("drawing-summary-extract-jobs", genDrawingSummariesResponse.job_id)
      startJobInterval(genDrawingSummariesResponse.job_id, docNumber, type, setExtractDrawingInfoStatus, setDrawingInfo, setShowExtractDrawingInfoButton)
    }
  })
}

const checkLocalStorageForJobs = (docNumber, type, setExtractDrawingInfoStatus, setDrawingInfo, setShowExtractDrawingInfoButton) => {
  const jobId = localStorage.getItem("drawing-summary-extract-jobs")
  if (jobId) {
    startJobInterval(jobId, docNumber, type, setExtractDrawingInfoStatus, setDrawingInfo, setShowExtractDrawingInfoButton)
  }
}

const DeleteDocumentConfirmation = ({ onConfirmDelete, onCancelDelete }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-4 rounded shadow-lg">
      <h2 className="text-lg font-semibold">Delete Document</h2>
      <p>Are you sure you want to delete this document?</p>
      <div className="mt-4 flex justify-end">
        <button onClick={onCancelDelete} className="mr-2 px-4 py-2 bg-gray-300 rounded">
          Cancel
        </button>
        <button onClick={onConfirmDelete} className="px-4 py-2 bg-red-600 text-white rounded">
          Delete
        </button>
      </div>
    </div>
  </div>
)


const DocumentViewer = ({ children, type, setType, docNumber, setDocNumber, docName, addTextToMessageInput,
scrollToParagraphFn, paragraphNumberToScrollTo, scrollToClaimFn, claimNumberToScrollTo, showFiguresInDocWindow,
handleScrollToDrawing, setIsSuppContextVisible, setSelectedSection, contextData, setContextData, setRemovedResources }) => {
  const [documentContent, setDocumentContent] = useState('')
  const [docNotLoaded, setDocNotLoaded] = useState(false)
  const [showSelectDocument, setShowSelectDocument] = useState(false)
  const [showDeleteDocument, setShowDeleteDocument] = useState(false)
  const [showDocumentDeleteError, setShowDocumentDeleteError] = useState(false)
  const [summaryText, setSummaryText] = useState("")
  const [showSummary, setShowSummary] = useState(true)
  const [summaryFigure, setSummaryFigure] = useState(null)
  const [specInfo, setSpecInfo] = useState(null)
  const [specInfoLoaded, setSpecInfoLoaded] = useState(false)
  const [showExtractDrawingInfoButton, setShowExtractDrawingInfoButton] = useState(true)
  const [extractDrawingInfoStatus, setExtractDrawingInfoStatus] = useState("")
  const [componentLoading, setComponentLoading] = useState(false)
  const [showDrawings, setShowDrawings] = useState(false)
  const [drawingUrls, setDrawingUrls] = useState([])
  const [showDrawingInfo, setShowDrawingInfo] = useState(false)
  const [drawingInfo, setDrawingInfo] = useState([])
  const [showViewSpecInfo, setShowViewSpecInfo] = useState(false)
  const [cites, setCites] = useState([])
  const [isCitesVisible, setIsCitesVisible] = useState(false)
  const [tables, setTables] = useState([])
  const [equations, setEquations] = useState([])
  const [showSummaryFigure, setShowSummaryFigure] = useState(false)
  const [showCites, setShowCites] = useState(false)
  const [showTables, setShowTables] = useState(false)
  const [showEquations, setShowEquations] = useState(false)
  const [summaryJobId, setSummaryJobId] = useState('')
  const [pdfData, setPdfData] = useState('')
  const [claims, setClaims] = useState([])
  const [imageContent, setImageContent] = useState('')
  const [isImageContent, setIsImageContent] = useState(false)
  const [showClaims, setShowClaims] = useState(false)
  const [paragraphs, setParagraphs] = useState([])
  const [loading, setLoading] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [showCustomQuestionMenu, setShowCustomQuestionMenu] = useState(false)
  const [customQuestions, setCustomQuestions] = useState([])
  const [showCustomQuestionHistory, setShowCustomQuestionHistory] = useState(false)
  const [customQuestionText, setCustomQuestionText] = useState("")
  const [customQuestionResponse, setCustomQuestionResponse] = useState("")
  const [customQuestionLoading, setCustomQuestionLoading] = useState(false)
  const [isNotLoaded, setIsNotLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isTermListLoading, setIsTermListLoading] = useState(false)
  const [termListData, setTermListData] = useState(null)
  const [showRefreshButton, setShowRefreshButton] = useState(false)
  const [showGetTermListAndDefinitions, setShowGetTermListAndDefinitions] = useState(true)
  const [hideTerms, setHideTerms] = useState(false)
  const [termListErrorMessage, setTermListErrorMessage] = useState('')
  const [uploadName, setUploadName] = useState('')
  const [showTermListError, setShowTermListError] = useState(false)
  const [isCustTermListLoading, setIsCustTermListLoading] = useState(false)
  const { theme, setTheme } = useContext(ThemeContext)
  const buttonClass = `flex items-center px-4 py-2 text-sm cursor-pointer font-medium rounded-lg ml-1 transition-colors duration-300 ease-in-out ${
                    theme === 'dark'
                      ? 'bg-gray-800 text-gray-200 hover:bg-gray-600'
                      : 'bg-primary-100 text-primary hover:bg-primary-1-quarter'
                  }`
  const [showTopButton, setShowTopButton] = useState(false)
  const [showBottomButton, setShowBottomButton] = useState(false)
  const scrollDivRef = useRef(null)
  const checkScrollNeed = () => {
    if (scrollDivRef.current) {
      const { scrollHeight, clientHeight } = scrollDivRef.current
      if (scrollHeight >= 2 * clientHeight) {
        setShowTopButton(false)
        setShowBottomButton(true)
      } else {
        setShowTopButton(false)
        setShowBottomButton(false)
      }
    }
  }

  const handleScroll = () => {
    if (scrollDivRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollDivRef.current
      setShowBottomButton(!(scrollTop + clientHeight >= scrollHeight - 10))
      setShowTopButton(!(scrollTop === 0))
    }
  }

  const scrollToParagraph = (paragraphNumber) => {
    const element = document.getElementById(`paragraph-${paragraphNumber}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }


  useEffect(() => {
    const resourceIndex = contextData.resource_ids.indexOf(docNumber)
    if (resourceIndex !== -1) {
      const resourceName = contextData.resource_names[resourceIndex]
      setUploadName(resourceName)
    }
    const div = scrollDivRef.current
    if (scrollDivRef.current) {
      checkScrollNeed()
      div.addEventListener('custom-question-history-scroll', handleScroll)
      return () => div.removeEventListener('custom-question-history-scroll', handleScroll)
    }
    if (!specInfo) {
      handleFetchSpecInfo(false)
    }
  }, [])

  const scrollToTop = () => {
    scrollDivRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const scrollToBottom = () => {
    const { scrollHeight } = scrollDivRef.current
    scrollDivRef.current.scrollTo({
      top: scrollHeight,
      behavior: 'smooth'
    })
  }

  const scrollToTopDoc = () => {
    const element = document.getElementById("top-of-content")
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const scrollToBottomDoc = () => {
    const element = document.getElementById("bottom-of-content")
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const removeItemFromContext = () => {
    const item = docNumber
    if (contextData.last_context_type === type && contextData.last_context_number === item) {
      contextData.last_context_number = ''
      contextData.last_context_type = ''
    }
    let newContextData
    if (type === 'publication') {
      newContextData = {
        ...contextData,
        current_publications: contextData.current_publications.filter((item_, index) => item_ !== item),
      }
    } else if (type === 'patent') {
      newContextData = {
        ...contextData,
        current_patents: contextData.current_patents.filter((item_, index) => item_ !== item),
      }
    } else if (type === 'upload') {
      newContextData = {
        ...contextData,
        resource_names: contextData.resource_names.filter((item_, index) => item_ !== item),
        resource_ids: contextData.resource_ids.filter((item_, index) => item_ !== item),
      }
    } else if (type === 'mpep') {
      newContextData = {
        ...contextData,
        mpep_sections: contextData.mpep_sections.filter((item_, index) => item_ !== item),
      }
    }
    setContextData(newContextData)
    localStorage.setItem('chat_context', JSON.stringify(newContextData))
    let newResource = {
      type: type,
      resource_id: item
    }
    setRemovedResources((prevResources) => [...prevResources, newResource])
  }

  const loadDocument = async () => {
    setParagraphs([])
    setIsImageContent(false)
    setImageContent("")
    setShowCustomQuestionHistory(false)
    setLoading(true)
    setShowGetTermListAndDefinitions(false)
    setShowClaims(false)
    try {
      const response = await fetchDocument(type, removeCountryAndKindCodes(docNumber))
      if (response) {
        if (response.content && response.content.length > 0) {
            if (response.content[0].paragraph && response.content[0].paragraph.startsWith("[{\"page\":")) {
              setPdfData(JSON.parse(response.content[0].paragraph))
              return
            }
          const newParas = arrayToJsonObject(response.content)
          setParagraphs(newParas)
        } else if (response.content && response.content.length === 0) {
          setDocNotLoaded(true)
        } else if (response.base64image) {
          setIsImageContent(true)
          setImageContent(response.base64image)
        } else if (response.description !== undefined && response.description !== false && type !== "mpep") {
          if (response.description === "unknown") {
            setIsNotLoaded(true)
            return
          }
          if (!termListData) {
            setShowGetTermListAndDefinitions(true)
          }
          setDocumentContent(response.description + "\n" + response.claims)
          setParagraphs(response.description)
          if (response.claims) {
            setClaims(response.claims)
            setShowClaims(true)
          }
          if (response.drawing_urls) {
            setDrawingUrls(response.drawing_urls)
          }
          if (response.drawing_summaries) {
            setDrawingInfo(response.drawing_summaries)
          }
        } else if (response.description === false) {
          setDocNotLoaded(true)
        } else if (response.description) {
          setParagraphs(response.description)
        }
        if (paragraphNumberToScrollTo) {
          setTimeout(() => {
            scrollToParagraphFn(paragraphNumberToScrollTo);
          }, 400)
        }

        if (claimNumberToScrollTo) {
          setTimeout(() => {
            scrollToClaimFn(claimNumberToScrollTo);
          }, 400)
        }

        if (showFiguresInDocWindow) {
          setShowDrawings(true)
        }
    } else {
        setParagraphs("Response not found");
    }

    } catch (error) {
      console.error('Error fetching document:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (type && docNumber) {
      setSpecInfoLoaded(false)
      setSummaryText("")
      setCites([])
      setTables([])
      setEquations([])
      setSummaryFigure("")
      setShowSelectDocument(false)
      loadDocument()
    }
    if (type === "patent" || type === "publication" || type === "upload"){
      setShowGetTermListAndDefinitions(true)
    }
  }, [type, docNumber])

  useEffect(() => {
    localStorage.setItem("term_dict", termListData)
  }, [termListData])

  function extractHeadersFromParagraphs(paras) {
    const headersWithParagraphNumbers = []
    let currentHeader = ''
    const exceptions = ["summary", "abstract", "technical field"]

    paras.forEach((paragraph, index) => {
        const lastPeriodIndex = paragraph.text.lastIndexOf('.')
        let potentialHeader = paragraph.text.substring(lastPeriodIndex + 1).trim()

        // Check if potentialHeader is all-uppercase or matches any exception
        const isUpperCaseHeader = potentialHeader === potentialHeader.toUpperCase() && potentialHeader.match(/^[A-Z\s]+$/)
        const isExceptionHeader = exceptions.some(exception => potentialHeader.toLowerCase().includes(exception))

        if (isUpperCaseHeader || isExceptionHeader) {
            currentHeader = potentialHeader
            paragraph.text = paragraph.text.substring(0, lastPeriodIndex + 1).trim()
        }

        if (currentHeader) {
            headersWithParagraphNumbers.push({ header: currentHeader, paragraphNumber: paragraph.number + 1 })
        }
    })

    return headersWithParagraphNumbers
  }

  function updateParagraphHeaders(paras, headersWithParagraphNumbers) {
      let currentHeader = ''
    let nextHeaderIndex = 0

    paras.forEach((paragraph, index) => {
        if (nextHeaderIndex < headersWithParagraphNumbers.length &&
            paragraph.number === headersWithParagraphNumbers[nextHeaderIndex].paragraphNumber) {
            currentHeader = headersWithParagraphNumbers[nextHeaderIndex].header
            nextHeaderIndex++
        }
        paragraph.header = currentHeader
    })
    return paras
  }

  function pad(num, size) {
      num = num.toString()
      while (num.length < size) num = "0" + num
      return num
  }

  function arrayToJsonObject(arr) {
    const jsonObject = {}
    arr.forEach(item => {
      const key = "[" + pad(item.para_id, 4) + "]"
      jsonObject[key] = { content: item.paragraph }
    })
    return jsonObject
  }

  function parseTextToParagraphs(text, isPatent) {
    let splitRegex = /(?:\r\n|\r|\n)/
    if (isPatent) splitRegex = /(?:\r\n|\r|\n)[([](0*\d+)[)\]]/
    const parts = text.split(splitRegex)
    let paras = []
    let currentHeader = parts[0] && !parts[0].includes('.') ? parts[0].trim() : ''
    let paragraphNumber = 0
    for (let i = 1; i < parts.length; i += 2) {
        const paragraphText = parts[i + 1]?.trim()
        if (!paragraphText || paragraphText.trim() === "") {
            continue
        }
        if (isPatent) {
            paragraphNumber = parseInt(parts[i], 10)
        } else {
            paragraphNumber = paragraphNumber + 1
        }
        const exceptions = ["summary", "abstract", "technical field"]
        let potentialHeader = ''
        const lastPeriodIndex = paragraphText.lastIndexOf('.')
        potentialHeader = paragraphText.substring(lastPeriodIndex + 1).trim()
        const isUpperCaseHeader = potentialHeader === potentialHeader.toUpperCase() && potentialHeader.match(/^[A-Z\s]+$/)
        const isExceptionHeader = exceptions.some(exception => potentialHeader.toLowerCase().includes(exception))
        let the_text = paragraphText
        if (isUpperCaseHeader || isExceptionHeader) {
            the_text = paragraphText.substring(0, lastPeriodIndex + 1).trim()
        }
        if (!isNaN(paragraphNumber) && paragraphText && the_text.trim() !== "") {
            paras.push({ number: paragraphNumber, header: currentHeader, text: the_text })
        } else {
            paragraphNumber = paragraphNumber - 1
        }
        if (isUpperCaseHeader || isExceptionHeader) {
            currentHeader = potentialHeader
        }
    }
    return paras
  }

  function capitalizeFirstChar(str) {
      if (str === "mpep") return "MPEP §"
      if (!str) return str
      return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const handleConfirmDelete = () => {
    deleteDocument(type, docNumber).then((deleteDocumentResponse) => {
      if (deleteDocumentResponse === "error") {
        setShowDocumentDeleteError(true)
      } else {
        loadDocument()
        removeItemFromContext()
        setShowDeleteDocument(false)
        setSelectedSection("chat")
      }
    }).catch((e) => {
      setShowDocumentDeleteError(true)
    })
  }

  const handleFetchSpecInfo = (showSpecInfo) => {
    if (specInfoLoaded) {
      if (showSpecInfo) {
        setShowCites(true)
        setShowTables(true)
        setShowEquations(true)
        setShowViewSpecInfo(showSpecInfo)
      }
      return
    }
    getCites(docNumber, type).then((getCitesResponse) => {
      if (getCitesResponse['cites'] && getCitesResponse['cites'] !== undefined && getCitesResponse['cites'] !== null && getCitesResponse['cites'] !== "null") {
        setCites(getCitesResponse["cites"])
        if (showSpecInfo) setShowCites(true)
      }
    }).catch(() => {
    })
    fetchSpecInfo(docNumber, type).then((fetchSpecInfoResponse) => {
      setSpecInfo(fetchSpecInfoResponse)
      if (fetchSpecInfoResponse.hasOwnProperty("summary")){
        if (fetchSpecInfoResponse["summary"].hasOwnProperty("text")){
          setSummaryText(fetchSpecInfoResponse["summary"]["text"])
        } else if (fetchSpecInfoResponse["summary"]) {
          const summaryTxt = fetchSpecInfoResponse["summary"].replace(/\n\n/g, '\n')
          setSummaryText(summaryTxt)
        } else {
          setSummaryText("There appears to be an error obtaining the summary.")
        }
        if (fetchSpecInfoResponse["summary"].hasOwnProperty("figure")){
          setSummaryFigure(fetchSpecInfoResponse["summary"]["figure"])
        }
        setSpecInfoLoaded(true)
      }
      if (fetchSpecInfoResponse.hasOwnProperty("cites") && Array.isArray(fetchSpecInfoResponse["cites"]) && fetchSpecInfoResponse["cites"].length > 0 && cites.length === 0){
        setCites(fetchSpecInfoResponse["cites"])
        if (showSpecInfo) setShowCites(true)
        setSpecInfoLoaded(true)
      }
      if (fetchSpecInfoResponse.hasOwnProperty("tables") && Array.isArray(fetchSpecInfoResponse["tables"]) && fetchSpecInfoResponse["tables"].length > 0){
        setTables(fetchSpecInfoResponse["tables"])
        if (showSpecInfo) setShowTables(true)
        setSpecInfoLoaded(true)
      }
      if (fetchSpecInfoResponse.hasOwnProperty("equations") && Array.isArray(fetchSpecInfoResponse["equations"]) && fetchSpecInfoResponse["equations"].length > 0){
        setEquations(fetchSpecInfoResponse["equations"])
        if (showSpecInfo) setShowEquations(true)
        setSpecInfoLoaded(true)
      }
      if (showSpecInfo) setShowViewSpecInfo(showSpecInfo)
    }).catch((e) => {
      console.error("An error occurred fetching spec info:", e)
    })

  }

  const handleSummarize = () => {
    getSummary(docNumber, type).then((getSummaryResponse) => {
      if (getSummaryResponse && (typeof getSummaryResponse === 'string' || getSummaryResponse instanceof String)) {
        setSummaryText(getSummaryResponse)
      } else if (getSummaryResponse.hasOwnProperty("summary") && getSummaryResponse["summary"].hasOwnProperty("text")){
        setSummaryText(getSummaryResponse["summary"]["text"])
      } else {
        setSummaryText("There appears to be an error obtaining the summary.")
      }
      setShowSummary(true)
    })
  }

  const handleDocumentQuestionHistory = () => {
    getCustomQuestionHistory(docNumber, type).then((getCustomQuestionHistoryResponse) => {
      setCustomQuestions(getCustomQuestionHistoryResponse)
      setShowCustomQuestionHistory(true)
    })
  }

  const handleLoadDocument = async() => {
    setIsLoading(true)
    setIsNotLoaded(false)
    fetchAndLoadDocument(type, removeCountryAndKindCodes(docNumber)).then((fetchAndLoadDocumentResponse) => {
      if (fetchAndLoadDocumentResponse && fetchAndLoadDocumentResponse["job_id"]){
        const job_id = fetchAndLoadDocumentResponse["job_id"]
        const fetchAndLoadDocumentInterval = setInterval(() => {
          getJobStatus(job_id).then((getJobStatusResponse) => {
            if (getJobStatusResponse && getJobStatusResponse.status && getJobStatusResponse.status.toLowerCase().startsWith("complete")) {
              setIsLoading(false)
              setDocNotLoaded(false)
              loadDocument()
              clearInterval(fetchAndLoadDocumentInterval)
            }
            if (getJobStatusResponse && getJobStatusResponse.status && getJobStatusResponse.status.toLowerCase().startsWith("error")) {
              clearInterval(fetchAndLoadDocumentInterval)
            }
          }).catch((e) => {
              setIsLoading(false)
              setDocNotLoaded(true)
              clearInterval(fetchAndLoadDocumentInterval)
          })
          }, 1500)
      }
    }).catch((e) => {
      setIsLoading(false)
      setDocNotLoaded(true)
    })
  }

  const handleGenSummary = async() => {
    setSummaryText("Submitting job")
    setShowSummary(true)
    setCustomQuestionLoading(true)
    summarizeAndCharacterize(docNumber, docName, type).then((getSummaryResponse) => {
      setCustomQuestionLoading(false)
      if (getSummaryResponse && getSummaryResponse["job_id"]){
        setSummaryJobId(getSummaryResponse["job_id"])
        setSummaryText("Generating")
      }
    })
  }

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const handleCustomQuestionClick = () => {
    setShowCustomQuestionMenu(true)
  }

  const handleCustomQuestionTextChange = (event) => {
    setCustomQuestionText(event.target.value)
  }

  const handleCustomQuestionSubmit = async() => {
      setCustomQuestionLoading(true)
      const newQuestion = {
        input_text: customQuestionText,
        response: '...', // Indicate that the response is being generated
        timestamp: new Date().toISOString(),
      }

      setCustomQuestions([...customQuestions, newQuestion])
      scrollToBottom()

      customQuestion(customQuestionText, type, docNumber).then((customQuestionResponse) => {
          const message_id = customQuestionResponse["message_id"]
          const interval = setInterval(() => {
              getMessage(message_id, false).then((getMessageResponse) => {
                  if (getMessageResponse['message'] && getMessageResponse['message'] !== undefined && getMessageResponse['message'] !== null && getMessageResponse['message'] !== "null") {
                      getCustomQuestionHistory(docNumber, type).then((getCustomQuestionHistoryResponse) => {
                        setCustomQuestions(getCustomQuestionHistoryResponse)
                        setShowCustomQuestionHistory(true)
                      })
                      setCustomQuestionLoading(false)
                      setCustomQuestionText("")
                      scrollToBottom()
                      clearInterval(interval)
                  }
              }).catch(() => {
                  setCustomQuestionLoading(false)
                  setCustomQuestionResponse("Error generating response")
                  clearInterval(interval)
              })
          }, 7500)
        }).catch(() => {
//                setLoading(false)
        })
  }

  const handleAddCommentClick = () =>{

  }

  const handleRefreshDocument = () => {
    refreshDoc(docNumber, type)
  }

  const handleGenTermListAndDefinitionsResponse = (getTermListAndDefinitionsResponse) => {
    if (getTermListAndDefinitionsResponse && getTermListAndDefinitionsResponse["message_id"]){
      const message_id = getTermListAndDefinitionsResponse["message_id"]
      const fetchAndLoadTermListInterval = setInterval(() => {
        fetchTermMap(message_id).then((getMessageResponse) => {
            if (getMessageResponse['error'] && getMessageResponse['error'] !== undefined && getMessageResponse['error'] !== null && getMessageResponse['message'] !== "null" && getMessageResponse['error'] !== "") {
                setIsTermListLoading(false)
                setIsCustTermListLoading(false)
                setTermListErrorMessage(getMessageResponse['error'])
                setShowTermListError(true)
            }
            if (getMessageResponse['message'] && getMessageResponse['message'] !== undefined && getMessageResponse['message'] !== null && getMessageResponse['message'] !== "null" && getMessageResponse['message'] !== "") {
                setIsTermListLoading(false)
                setIsCustTermListLoading(false)
                setTermListData(getMessageResponse['message'])
                setShowGetTermListAndDefinitions(false)
                clearInterval(fetchAndLoadTermListInterval)
            }
        }).catch(() => {
            setIsTermListLoading(false)
            setIsCustTermListLoading(false)
            setTermListData("Error fetching term list")
            clearInterval(fetchAndLoadTermListInterval)
        })
      }, 7500)
    } else if (getTermListAndDefinitionsResponse && getTermListAndDefinitionsResponse["term_dict"]){
      setIsTermListLoading(false)
      setIsCustTermListLoading(false)
      setShowGetTermListAndDefinitions(false)
      setTermListData(getTermListAndDefinitionsResponse['term_dict'])
    }
  }

  const handleGetTermListAndDefinitions = () => {
    setIsTermListLoading(true)
    setTermListErrorMessage("")
    setShowTermListError(false)
    setIsCustTermListLoading(false)
    getTermListAndDefinitions(docNumber, type, false).then(handleGenTermListAndDefinitionsResponse).catch(() => {
      setIsTermListLoading(false)
    })
  }

  const handleRefreshTermListAndDefinitions = () => {
    setTermListData(null)
    setTermListErrorMessage("")
    setShowTermListError(false)
    setIsCustTermListLoading(false)
    setShowGetTermListAndDefinitions(false)
    setIsTermListLoading(true)
    getTermListAndDefinitions(docNumber, type, true).then(handleGenTermListAndDefinitionsResponse).catch(() => {
      setIsTermListLoading(false)
    })
  }

  const addCustTerms = (terms) => {
    setTermListErrorMessage("")
    setShowTermListError(false)
    setIsCustTermListLoading(true)
    getCustTermListAndDefinitions(docNumber, type, terms).then(handleGenTermListAndDefinitionsResponse).catch(() => {
      setIsTermListLoading(false)
      setIsCustTermListLoading(false)
    })
  }

  const handleDeleteTerm = (term_index) => {
    deleteTermFromList(docNumber, type, term_index).then(handleGenTermListAndDefinitionsResponse).catch(() => {
      setIsTermListLoading(false)
      setIsCustTermListLoading(false)
    })
  }

  const [citesStatus, setCitesStatus] = useState("")
  const [showLookForMoreCitesButton, setShowLookForMoreCitesButton] = useState(true)

  const handleLookForCites = () => {
    setShowLookForMoreCitesButton(false)
    setCitesStatus("Searching for more cites")
    lookForCites(docNumber, type, cites).then((lookForCitesResponse) => {
      const job_id = lookForCitesResponse["job_id"]
      const fetchAndLoadCitesInterval = setInterval(() => {
        getCites(docNumber, type).then((getCitesResponse) => {
          if (getCitesResponse['cites'] && getCitesResponse['cites'] !== undefined && getCitesResponse['cites'] !== null && getCitesResponse['cites'] !== "null") {
            setCites(getCitesResponse["cites"])
            setCitesStatus("")
            clearInterval(fetchAndLoadCitesInterval)
          }
        }).catch(() => {
          setCitesStatus("Error searching for citations.")
          setShowLookForMoreCitesButton(true)
          clearInterval(fetchAndLoadCitesInterval)
        })
      }, 3500)
    }).catch(() => {
      setShowLookForMoreCitesButton(false)
      setCitesStatus("There appears to have been an error.")
    })
  }

  function writeToExcel(data) {
    const workbook = XLSX.utils.book_new()
    const worksheet = {}
    worksheet["A1"] = { t: "s", v: "Numeral" }
    worksheet["B1"] = { t: "s", v: "Name" }
    worksheet["C1"] = { t: "s", v: "Definition" }
    worksheet["D1"] = { t: "s", v: "Paragraphs" }
    let rowIndex = 2
    data.forEach(item => {
      const rowPrefix = "A" + rowIndex + ":D" + rowIndex
      worksheet["A" + rowIndex] = { t: "s", v: item.numeral }
      worksheet["B" + rowIndex] = { t: "s", v: item.name }
      worksheet["C" + rowIndex] = { t: "s", v: item.definition }
      worksheet["D" + rowIndex] = { t: "s", v: item.paragraphs.join(", ") }
      rowIndex++
    })
    const range = { s: { c: 0, r: 0 }, e: { c: 3, r: rowIndex - 1 } }
    worksheet['!ref'] = XLSX.utils.encode_range(range)
    XLSX.utils.book_append_sheet(workbook, worksheet, type + "-" + docNumber + " Terms")
    const fileName = type + "-" + docNumber + "terms.xlsx"
    XLSX.writeFile(workbook, fileName)
  }

  const handleDownloadTerms = () => {
    writeToExcel(termListData)
  }

  const genSummaries = () => {
    setShowExtractDrawingInfoButton(false)
    setExtractDrawingInfoStatus("Loading... extracting info from drawing sheets")
    genDrawingSummaries(docNumber, type).then((genDrawingSummariesResponse) => {
      if (genDrawingSummariesResponse && genDrawingSummariesResponse.job_id) {
        const genDrawingSummariesInterval = setInterval(() => {
          getJobStatus(genDrawingSummariesResponse.job_id).then((drawingSummariesJobResponse) => {
            if (drawingSummariesJobResponse && drawingSummariesJobResponse.status && drawingSummariesJobResponse.status.toLowerCase().startsWith("processing")) {
              const splitJobStatus = drawingSummariesJobResponse.status.split("-")
              if (splitJobStatus.length > 2) {
                setExtractDrawingInfoStatus("Loading...sheet " + splitJobStatus[1] + " out of " + splitJobStatus[2] + " total sheets")
              }
            } else if (drawingSummariesJobResponse && drawingSummariesJobResponse.status && drawingSummariesJobResponse.status.toLowerCase().startsWith("complete")) {
              clearInterval(genDrawingSummariesInterval)
              getDrawingSummaries(docNumber, type).then((getDrawingSummariesResponse) => {
                if (getDrawingSummariesResponse && getDrawingSummariesResponse.drawing_summaries) {
                  setDrawingInfo((prevDrawingInfo) => ({
                    ...prevDrawingInfo,
                    drawing_summaries: getDrawingSummariesResponse.drawing_summaries,
                  }))
                  setExtractDrawingInfoStatus("")
                }
              })
            }
          }).catch((e) => {
            setExtractDrawingInfoStatus("Error extracting")
            setShowExtractDrawingInfoButton(true)
            clearInterval(genDrawingSummariesInterval)
          })
        }, 2000)
      }
    })
  }

  const addComponent = async (numeral, number, sheet) => {
    setComponentLoading(true)
    try {
      const addDrawingComponentResponse = await addDrawingComponent(docNumber, type, numeral, number, sheet)
      if (addDrawingComponentResponse && addDrawingComponentResponse.job_id) {
        const addDrawingComponentInterval = setInterval(() => {
          getJobStatus(addDrawingComponentResponse.job_id).then((addDrawingComponentJobResponse) => {
            if (addDrawingComponentJobResponse && addDrawingComponentJobResponse.status && addDrawingComponentJobResponse.status.toLowerCase().startsWith("complete")) {
              clearInterval(addDrawingComponentInterval)
              setComponentLoading(false)
              getDrawingSummaries(docNumber, type).then((getDrawingSummariesResponse) => {
                if (getDrawingSummariesResponse && getDrawingSummariesResponse.drawing_summaries) {
                  setDrawingInfo(getDrawingSummariesResponse.drawing_summaries)
                  setExtractDrawingInfoStatus("")
                }
              })
            }
          }).catch((e) => {
            clearInterval(addDrawingComponentInterval)
            setExtractDrawingInfoStatus("Error extracting")
            setComponentLoading(false)
          })
        }, 2000)
      }
    } catch (error) {
      // Handle error if needed
    } finally {
      setIsLoading(false)
    }
  }

  // Function to handle section selection from the nav bar
  const onSelectSection = (sectionId) => {
    const element = document.getElementById(`section-${sectionId}`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const sectionTitles = {
    background: "Background",
    detailed_desc: "Detailed Description",
    drawings_desc: "Drawings Description",
    summary: "Summary",
    resource: "Resource"
  }

  const sectionHeaders = {}
  if (specInfo?.sections) {
    Object.keys(specInfo.sections).forEach(key => {
      const paraNum = specInfo.sections[key]
      sectionHeaders[paraNum] = { "id": key, "text": sectionTitles[key] || key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ') }
    })
  }

  if (!docNumber || !type) {
    return (
      <div className={`flex items-center justify-center h-screen w-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
        <div className="text-center bg-red-600 text-white p-6 rounded-lg shadow-lg">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-4xl mb-4" />
          <h1 className="text-2xl font-bold">No Document Selected</h1>
          <h1 className="text-lg font-bold my-2">Select a Document from the Context</h1>
          <p>{children}</p>
        </div>
      </div>
    )
  }

  if (docNotLoaded) {
    return (
      <div className={`flex items-center justify-center h-screen w-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
        <div className={`text-center ${isLoading ? "bg-secondary" : "bg-red-600"} text-white p-6 rounded-lg shadow-lg`}>
          {isLoading && (<FontAwesomeIcon icon={faSpinner} className="text-4xl mb-4 animate-spin" />)}
          {!isLoading && (<FontAwesomeIcon icon={faExclamationTriangle} className="text-4xl mb-4" />)}
          {!isLoading && (<h1 className="text-2xl font-bold">Document Not Found</h1>)}
          <p>{type} {docNumber}</p>
          {!isLoading && (type === "patent" || type === "publication") && docNumber && (<>
            <button onClick={handleLoadDocument} className={`mt-4 px-4 py-2 rounded ${theme === 'dark' ? 'bg-secondary-light text-black hover:bg-secondary hover:text-gray-100' : 'bg-gray-200 text-black hover:bg-gray-300'}`}>
              Load Document
            </button>
            <br />
            <button onClick={() => {setDocNumber(""); setType("")}} className={`mt-4 px-4 py-2 rounded ${theme === 'dark' ? 'bg-primary-light-3 text-primary hover:bg-primary hover:text-gray-100' : 'bg-gray-200 text-black hover:bg-gray-300'}`}>
              Select Another Document
            </button>
          </>
          )}
        </div>
      </div>
    )
  }
  return (
    <>
      <DocumentHeader
        theme={theme}
        type={type}
        docName={uploadName !== "" ? uploadName : (docName ? docName : docNumber)}
        handleRefreshDocument={handleRefreshDocument}
        showRefreshButton={showRefreshButton}
        isDrawings={false}
      />
      {loading ? (
        <LoadingIndicator theme={theme} message="Loading document..." />
      ) : (
        <>
          {!showSelectDocument && (<div onClick={() => setShowSelectDocument(true)} className={`${buttonClass} fixed top-1 right-2 ${theme === 'dark' ? 'text-white bg-gray-900' : 'bg-white'}`}>
            Select New Document
          </div>)}
          {showSelectDocument && (<div className={`flex z-10 flex-row max-w-[calc(100vw-200px)] min-w-[300px] fixed top-1 right-2 ${theme === 'dark' ? 'text-white' : ''}`}>
            {children}
            <div onClick={() => setShowSelectDocument(false)} className={`${buttonClass} max-h-10 top-1`} >
              Back
            </div>
          </div>)}
          {(type === "patent" || type === "publication") && specInfo && specInfo.sections && (
            <div className="flex fixed ml-[84px] left-0 top-0">
              <VerticalDocNavBar
                sections={specInfo.sections}
                onSelect={onSelectSection}
                sectionTitles={sectionTitles}
                scrollToTop={scrollToTopDoc}
                scrollToBottom={scrollToBottomDoc}
                setShowSummary={setShowSummary}
                theme={theme}
              />
            </div>
          )}
          <div className={`flex flex-col ${((type === "patent" || type === "publication") && specInfo && specInfo.sections) ? "ml-[72px]" : ""} ${theme === 'dark' ? 'text-white bg-gray-900' : 'bg-white'}`}>
            <div className='overflow-x-hidden h-screen max-w-[calc(100vw-72px)] flex-grow overflow-y-auto max-h-[calc(100vh-45px)] px-4 py-2 flex flex-wrap'>
              <div className="flex flex-row w-full">
                {!showViewSpecInfo && (
                  <button onClick={handleFetchSpecInfo} className={buttonClass}>
                    <FontAwesomeIcon icon={faEye} className="mr-1" />
                    View Spec Info
                  </button>
                )}
                {showViewSpecInfo && (
                  <button onClick={() => setShowViewSpecInfo(false)} className={buttonClass}>
                    <FontAwesomeIcon icon={faEyeSlash} className="mr-1" />
                    Hide Spec Info
                  </button>
                )}
                {!showCustomQuestionHistory && (
                  <button
                    onClick={() => {
                      setShowCustomQuestionHistory(true);
                      handleDocumentQuestionHistory();
                    }}
                    className={buttonClass}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                    View Question History
                  </button>
                )}
                {showCustomQuestionHistory && (
                  <button
                    onClick={() => setShowCustomQuestionHistory(false)}
                    className={buttonClass}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                    Hide Question History
                  </button>
                )}
                {!showDrawings && (
                  <button
                    onClick={() => setSelectedSection("drawings")}
                    className={buttonClass}
                  >
                    <FontAwesomeIcon icon={faEye} className="mr-2" />
                    Show Drawings
                  </button>
                )}
                {!showSummary && summaryText !== '' && summaryText !== 'unknown' && (
                  <div className="ml-1 mr-2" id="start-of-ai-summary">
                    <button onClick={() => setShowSummary(true)} className={buttonClass}>
                      <FontAwesomeIcon icon={faFileAlt} className="mr-1" />
                      View Summary
                    </button>
                  </div>
                )}
                {!showSummary && summaryText === '' && (
                  <div className="ml-1 mr-2" id="start-of-ai-summary">
                    <button onClick={() => setShowSummary(true)} className={buttonClass}>
                      <FontAwesomeIcon icon={faFileAlt} className="mr-1" />
                      View Summary
                    </button>
                  </div>
                )}
                <div className="ml-1 mr-2" id="start-of-delete-document">
                  <button onClick={() => setShowDeleteDocument(true)} className={buttonClass}>
                    <FontAwesomeIcon icon={faTrashAlt} className="mr-1" />
                    Delete
                  </button>
                </div>
              </div>
              <SpecInfoSection
                theme={theme}
                showViewSpecInfo={showViewSpecInfo}
                setShowViewSpecInfo={setShowViewSpecInfo}
                handleFetchSpecInfo={() => handleFetchSpecInfo(true)}
                summaryText={summaryText}
                summaryFigure={summaryFigure}
                showSummaryFigure={showSummaryFigure}
                setSummaryText={setSummaryText}
                setSummaryFigure={setSummaryFigure}
                specInfo={specInfo}
                setSpecInfo={setSpecInfo}
                setShowSummaryFigure={setShowSummaryFigure}
                cites={cites}
                tables={tables}
                equations={equations}
                setCites={setCites}
                setTables={setTables}
                setEquations={setEquations}
                setIsCitesVisible={setIsCitesVisible}
                setShowCites={setShowCites}
                setShowTables={setShowTables}
                setShowEquations={setShowEquations}
                scrollToParagraph={scrollToParagraph}
              />
              <CustomQuestionSection
                theme={theme}
                showCustomQuestionHistory={showCustomQuestionHistory}
                setShowCustomQuestionHistory={setShowCustomQuestionHistory}
                customQuestions={customQuestions}
                setCustomQuestions={setCustomQuestions}
                showCustomQuestionMenu={showCustomQuestionMenu}
                setShowCustomQuestionMenu={setShowCustomQuestionMenu}
                customQuestionText={customQuestionText}
                setCustomQuestionText={setCustomQuestionText}
                customQuestionLoading={customQuestionLoading}
                setCustomQuestionLoading={setCustomQuestionLoading}
                handleCustomQuestionTextChange={handleCustomQuestionTextChange}
                handleCustomQuestionSubmit={handleCustomQuestionSubmit}
                scrollToBottom={scrollToBottom}
                scrollDivRef={scrollDivRef}
                handleScroll={handleScroll}
                checkScrollNeed={checkScrollNeed}
                showTopButton={showTopButton}
                showBottomButton={showBottomButton}
                scrollToTop={scrollToTop}
                handleDocumentQuestionHistory={handleDocumentQuestionHistory}
              />
              <TermListSection
                theme={theme}
                isTermListLoading={isTermListLoading}
                showTermListError={showTermListError}
                termListErrorMessage={termListErrorMessage}
                handleGetTermListAndDefinitions={handleGetTermListAndDefinitions}
                handleRefreshTermListAndDefinitions={handleRefreshTermListAndDefinitions}
                termListData={termListData}
                hideTerms={hideTerms}
                setHideTerms={setHideTerms}
                addCustTerms={addCustTerms}
                handleDeleteTerm={handleDeleteTerm}
                handleDownloadTerms={handleDownloadTerms}
                isCustTermListLoading={isCustTermListLoading}
              />
              <CitesSection
                theme={theme}
                showCites={showCites}
                isCitesVisible={isCitesVisible}
                setIsCitesVisible={setIsCitesVisible}
                cites={cites}
                handleLookForCites={handleLookForCites}
                citesStatus={citesStatus}
                showLookForMoreCitesButton={showLookForMoreCitesButton}
                scrollToParagraph={scrollToParagraph}
              />
              <SummarySection
                theme={theme}
                summaryText={summaryText}
                handleSummarize={handleSummarize}
                handleGenSummary={handleGenSummary}
                isNotLoaded={isNotLoaded}
                handleLoadDocument={handleLoadDocument}
                setSummaryText={setSummaryText}
                setShowSummary={setShowSummary}
                showSummary={showSummary}
              />
              <ContentSection
                theme={theme}
                paragraphs={paragraphs}
                claims={claims}
                showClaims={showClaims}
                type={type}
                specInfo={specInfo}
                sectionHeaders={sectionHeaders}
                pdfData={pdfData}
                isImageContent={isImageContent}
                imageContent={imageContent}
                documentContent={documentContent}
                addTextToMessageInput={addTextToMessageInput}
              />
            </div>
          </div>
          {showDeleteDocument && (
            <DeleteDocumentConfirmation
              onConfirmDelete={handleConfirmDelete}
              onCancelDelete={() => setShowDeleteDocument(false)}
            />
          )}
        </>
      )}
      {showDocumentDeleteError && (
        <div className="fixed bottom-4 right-4 bg-red-600 text-white p-4 rounded shadow-lg">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            <span>Failed to delete the document. Please try again.</span>
          </div>
          <button
            onClick={() => setShowDocumentDeleteError(false)}
            className="ml-auto bg-red-800 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
          >
            Close
          </button>
        </div>
      )}
    </>

  )
}

export default DocumentViewer
