import React, { useState } from 'react'
import QuickStartGuide from '../../info/QuickStartGuide'

const ClaimsDoc = ({ claims, theme }) => {
  if (typeof claims === 'string') {
    return claims
  }
  return (
    <div className="p-4 space-y-4">
      {Object.entries(claims).map(([claimNumber, claimDetails]) => (
        <div
          key={claimNumber}
          id={`claim-${claimNumber}`}
          className={`p-4 rounded-lg shadow ${
            theme === 'dark' ? 'bg-gray-800 text-gray-200' : 'bg-gray-100 text-gray-700'
          }`}
        >
          <h3 className={`font-bold text-lg ${theme === 'dark' ? 'text-secondary-light' : 'text-primary'}`}>
            Claim {claimNumber}
          </h3>
          <p className={`whitespace-pre-wrap ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
            {claimDetails.content}
          </p>
          {claimDetails.claim_refs && claimDetails.claim_refs.length > 0 && (
            <div className="mt-2">
              <span className={`font-bold ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                Dependent on:
              </span>
              {' ' + claimDetails.claim_refs.join(', ')}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default ClaimsDoc
