import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  FaFolder,
  FaPlus,
  FaComments,
  FaUserPlus,
  FaTrash,
  FaFolderOpen
} from 'react-icons/fa'
import {
  fetchChats,
  deleteExistingChat,
  grantUserAccessToChat,
  removeAccessFromChat,
  fetchFolders,
  createFolder,
  deleteFolder,
  addChatToFolder,
  moveChatToFolder
} from '../api'
import ThemeContext from '../context/ThemeContext'
import { convertUtcToEasternTime } from '../util/gen_util'
import RedX from '../component/RedX'

const SavedChats = ({ username, setSelectedSection, clearChatMessages, setCurrentChat, folders, setFolders, loadFolders }) => {
  const { theme } = useContext(ThemeContext)
  const [folderName, setFolderName] = useState('')
  const [chats, setChats] = useState([])
  const [showWarning, setShowWarning] = useState(false)
  const [showDeleteWarning, setShowDeleteWarning] = useState(false)
  const [selectedChat, setSelectedChat] = useState("")
  const [chatToDelete, setChatToDelete] = useState("")
  const [viewedChatId, setViewedChatId] = useState(null)
  const [displayGrantUserAccessToChat, setDisplayGrantUserAccessToChat] = useState(false)
  const [permissionLevel, setPermissionLevel] = useState("read")
  const [targetUsername, setTargetUsername] = useState("")
  const [chatToGrantAccess, setChatToGrantAccess] = useState(null)
  const [grantAccessStatusMessage, setGrantAccessStatusMessage] = useState("")
  const [showAddToFolderModal, setShowAddToFolderModal] = useState(false)
  const [chatToAdd, setChatToAdd] = useState(null)
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [showDeleteFolderWarning, setShowDeleteFolderWarning] = useState(false)
  const [folderToDelete, setFolderToDelete] = useState(null)
  const [reallocationFolder, setReallocationFolder] = useState('')
  const [deleteChats, setDeleteChats] = useState(false)
  const [chatToMove, setChatToMove] = useState(null)
  const [showMoveToFolderModal, setShowMoveToFolderModal] = useState(false)

  const chatListRef = useRef()

  const capitalizeFirstLetter = (input_string) => {
    if (!input_string) {
      return ""
    }
    return input_string.charAt(0).toUpperCase() + input_string.slice(1)
  }

  async function handleGrantUserAccessToChat(chat, user_to_grant_access, permissions) {
    const grantUserAccessToChatResponse = await grantUserAccessToChat(chat.chat_id, chat.username, user_to_grant_access, permissions)
    try {
      if (grantUserAccessToChatResponse.message && grantUserAccessToChatResponse.message === "success") {
        console.log("Successfully granted user access to chat!")
        loadChats()
        setGrantAccessStatusMessage("Successfully granted user '" + user_to_grant_access + "' '" + permissionLevel + "' access to chat '" + chatToGrantAccess.chat_name + "'.")
        resetGrantAccessForm()
        chat["user_access"] = grantUserAccessToChatResponse["user_access"]
        setChatToGrantAccess(chat)
      }
    }
    catch (e) {
      console.log("Error granting user access to chat.")
    }
  }

  async function handleRemoveAccess(chat, user_to_remove_access) {
    const removeAccessFromChatResponse = await removeAccessFromChat(chat.chat_id, chat.username, user_to_remove_access)
    try {
      if (removeAccessFromChatResponse.message && removeAccessFromChatResponse.message === "success") {
        console.log("Successfully removed user access to chat!")
        loadChats()
        setGrantAccessStatusMessage("Successfully removed access by " + targetUsername + " to chat '" + chatToGrantAccess.chat_name + "' for user '" + user_to_remove_access + "'.")
        resetGrantAccessForm()
        chat["user_access"] = removeAccessFromChatResponse["user_access"]
        setChatToGrantAccess(chat)
      }
    }
    catch (e) {
      console.log("Error granting user access to chat.")
    }
  }

  const handlePermissionChange = (event, username) => {
    const newPermissions = event.target.value
    setChatToGrantAccess(prevState => {
      return {
        ...prevState,
        user_access: prevState.user_access.map(user => {
          if (user.username === username) {
            return { ...user, permissions: newPermissions }
          }
          return user
        })
      }
    })
  }

  const resetGrantAccessForm = () => {
    setPermissionLevel("read")
    setTargetUsername("")
  }

  const cancelAddGrantUserAccessToChat = () => {
    resetGrantAccessForm()
    setGrantAccessStatusMessage("")
    setDisplayGrantUserAccessToChat(false)
  }

  async function loadChats() {
    try {
      const fetchedChats = await fetchChats()
      setChats(fetchedChats.chats)
    } catch (error) {
      console.error("Failed to load chats:", error)
    }
  }

  useEffect(() => {
    loadChats()
    loadFolders()
  }, [])

  function handleChatClick(chat) {
    const storedIsSaved = JSON.parse(localStorage.getItem('storedIsSaved'))
    if (typeof storedIsSaved === 'boolean') {
      if (storedIsSaved) {
        setShowWarning(false)
        setCurrentChat(chat)
        setSelectedSection("chat")
        return
      }
    }
    setSelectedChat(chat)
    setShowWarning(true)
  }

  function handleContinue() {
    setShowWarning(false)
    setCurrentChat(selectedChat)
    setSelectedSection("chat")
  }

  function formatTimestamp(timestamp) {
    const chatDate = new Date(timestamp)

    const datePart = `${chatDate.getFullYear()}-${String(chatDate.getMonth() + 1).padStart(2, '0')}-${String(chatDate.getDate()).padStart(2, '0')}`
    const timePart = `${String(chatDate.getHours()).padStart(2, '0')}:${String(chatDate.getMinutes()).padStart(2, '0')}:${String(chatDate.getSeconds()).padStart(2, '0')}`

    return convertUtcToEasternTime(`${datePart} ${timePart}`)
  }

  function warnDeleteChat(chat) {
    setShowDeleteWarning(true)
    setChatToDelete(chat.chat_id)
  }

  async function deleteChat() {
    const result = await deleteExistingChat(chatToDelete)
    if (result === "success") {
      loadChats()
    }
    setChatToDelete("")
    setShowDeleteWarning(false)
  }

  function cancelDeleteChat() {
    setShowDeleteWarning(false)
    setChatToDelete("")
  }

  function truncateWithEllipses(str, length) {
    if (str.length <= length) {
      return str
    }

    const startLength = Math.floor(length / 2)
    const endLength = length - startLength - 1

    return str.slice(0, startLength) + "..." + str.slice(-endLength)
  }

  const handleDetailsToggle = (chatId) => {
    if (viewedChatId === chatId) {
      setViewedChatId(null) // If clicked on the currently viewed chat, hide details
    } else {
      setViewedChatId(chatId) // Otherwise, show details for the clicked chat
    }
  }

  function findDocumentName(lastContextType, lastContextNumber, resourceIds, resourceNames) {
    if (lastContextType === "upload") {
      const index = resourceIds.indexOf(lastContextNumber)
      if (index !== -1) {
        return resourceNames[index]
      } else {
        console.error("Resource ID not found.")
      }
    } else {
      console.error("Last context type is not 'resource'.")
      return lastContextNumber
    }
    return ""
  }

  const getContextNumber = (chat_context) => {
    return findDocumentName(chat_context.last_context_type, chat_context.last_context_number, chat_context.resource_ids, chat_context.resource_names)
  }

  const handleShowGrantUserAccessModal = (chat) => {
    setChatToGrantAccess(chat)
    setDisplayGrantUserAccessToChat(true)
  }

  const handleCreateFolder = () => {
    if (folderName) {
      setFolderName('')
      createFolder(folderName)
    } else {
      // setFolderStatus("enter folder name")
    }
  }

  const handleAddToFolder = (chat) => {
    setChatToAdd(chat.chat_id)
    setChatToMove(chat.chat_id)
    loadFolders()
    setShowAddToFolderModal(true)
  }

  const handleMoveToFolder = (chat_id) => {
    setChatToMove(chat_id)
    setShowMoveToFolderModal(true)
  }

  const moveToFolder = async (old_folder_id, new_folder_id) => {
    try {
      const moveChatToFolderResponse = await moveChatToFolder(old_folder_id, new_folder_id, chatToMove)
      loadFolders()
      loadChats()
      setShowMoveToFolderModal(false)
      setShowAddToFolderModal(false)
    } catch (error) {
      console.error("Failed to move chat to different folder:", error)
    }
  }

  const handleSelectFolder = async (folderId) => {
    try {
      setSelectedFolder(folderId)
      setShowAddToFolderModal(false)
      setChatToAdd(null)
    } catch (error) {
      console.error("Failed to add chat to folder:", error)
    }
  }

  const handleDeleteFolder = (folder) => {
    setFolderToDelete(folder)
    setShowDeleteFolderWarning(true)
  }

  const confirmDeleteFolder = async () => {
    try {
      await deleteFolder(folderToDelete.folder_id, deleteChats, reallocationFolder)
      loadFolders()
      loadChats()
      setShowDeleteFolderWarning(false)
      setFolderToDelete(null)
      setReallocationFolder('')
      setDeleteChats(false)
    } catch (error) {
      console.error("Failed to delete folder:", error)
    }
  }

  const handleFolderClick = (folderId) => {
    setSelectedFolder(folderId)
  }

  const handleBackToFolders = () => {
    setSelectedFolder(null)
  }

  const styles = `
    .custom-scroll {
      scrollbar-width: thin;
      scrollbar-color: var(--thumbBG) var(--trackBG);
    }

    .custom-scroll::-webkit-scrollbar {
      width: 12px;
    }

    .custom-scroll::-webkit-scrollbar-track {
      background: var(--trackBG);
    }

    .custom-scroll::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG);
      border-radius: 20px;
      border: 3px solid var(--trackBG);
    }

    :root {
      --trackBG: ${theme === 'dark' ? '#1a202c' : '#12455c'};
      --thumbBG: ${theme === 'dark' ? '#4a5568' : '#32657c'};
    }
  `;

  useEffect(() => {

    const styleSheet = document.createElement("style")
    styleSheet.type = "text/css"
    styleSheet.innerText = styles
    document.head.appendChild(styleSheet)

    return () => {
      document.head.removeChild(styleSheet)
    };
  }, [theme])

  return (
    <div className={`flex flex-col justify-center items-center min-h-screen ${theme === 'dark' ? '' : 'bg-primary'}`}>
      <div className={`grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-6 gap-y-6 p-6 max-h-screen max-w-[calc(100vw-72px)] overflow-y-auto custom-scroll ${theme === 'dark' ? '' : 'bg-primary'}`}>
      {selectedFolder ? (
        <>
          <button onClick={handleBackToFolders} className={`bg-blue-500 p-2 h-48 w-48 rounded-lg shadow-md ${theme === 'dark' ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' : 'bg-gray-200 text-primary hover:bg-gray-300'}`}>
            Back to Folders
          </button>
          {chats.filter(chat => chat.folder_id === selectedFolder).map(chat => (
            <div
              key={chat.chat_id}
              className={`flex flex-col items-center justify-center p-4 h-48 w-48 rounded-lg shadow-lg cursor-pointer ${theme === 'dark' ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' : 'bg-gray-200 hover:bg-gray-300'}`}
            >
              <span className="text-lg font-semibold">{chat.chat_name}</span>
              <p className={`text-sm ${theme === 'dark' ? 'text-gray-300' : 'text-gray-500'}`}>{formatTimestamp(chat.last_modified)}</p>
              <div className="grid grid-cols-2 gap-2 mt-2 w-full">
                <button onClick={() => handleChatClick(chat)} type="button"
                  className={`cursor-pointer inline-flex justify-center items-center w-full rounded-md px-2 py-1 text-sm focus:outline-none transition ease-in-out duration-150 ${theme === 'dark' ? 'bg-blue-900 text-gray-300 hover:bg-blue-700' : 'bg-blue-100 text-gray-700 hover:bg-blue-500'}`}
                  title="Load Chat"
                >
                  <FaComments className="mr-1" />
                  Load
                </button>
                <button onClick={() => handleMoveToFolder(chat.chat_id)} type="button"
                  className={`cursor-pointer inline-flex justify-center items-center w-full rounded-md px-2 py-1 text-sm focus:outline-none transition ease-in-out duration-150 ${theme === 'dark' ? 'bg-yellow-900 text-gray-300 hover:bg-yellow-700' : 'bg-yellow-100 text-gray-700 hover:bg-yellow-500'}`}
                  title="Move Chat to Different Folder"
                >
                  <FaFolder className="mr-1" />
                  Move
                </button>
                {(chat.username === username || chat.permissions === "owner") && (
                  <>
                    <button onClick={() => handleShowGrantUserAccessModal(chat)} type="button"
                      className={`cursor-pointer inline-flex justify-center items-center w-full rounded-md px-2 py-1 text-sm focus:outline-none transition ease-in-out duration-150 ${theme === 'dark' ? 'bg-green-900 text-gray-300 hover:bg-green-700' : 'bg-green-100 text-gray-700 hover:bg-green-500'}`}
                      title="Grant Access"
                    >
                      <FaUserPlus className="mr-1" />
                      Grant
                    </button>
                    <button onClick={() => warnDeleteChat(chat)} type="button"
                      className={`cursor-pointer inline-flex justify-center items-center w-full rounded-md px-2 py-1 text-sm focus:outline-none transition ease-in-out duration-150 ${theme === 'dark' ? 'bg-red-900 text-gray-300 hover:bg-red-700' : 'bg-red-100 text-gray-700 hover:bg-red-500'}`}
                      title="Delete Chat"
                    >
                      <FaTrash className="mr-1" />
                      Delete
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <div
            className={`flex flex-col items-center justify-center p-4 h-48 w-48 rounded-lg shadow-lg cursor-pointer ${theme === 'dark' ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' : 'bg-gray-200 hover:bg-gray-300'}`}
            onClick={handleCreateFolder}
          >
            <FaPlus className="text-4xl text-primary mb-2" />
            <span className="text-lg font-semibold mb-2">Create Folder</span>
            <input
              type="text"
              placeholder="Folder Name"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              className={`mt-2 p-2 w-full rounded text-center ${theme === 'dark' ? 'bg-gray-700 text-gray-300 placeholder-gray-500' : 'bg-gray-100 text-gray-700 placeholder-gray-400'}`}
            />
          </div>
          {folders.map(folder => (
            <div
              key={folder.id}
              className={`flex flex-col items-center justify-center p-4 h-48 w-48 rounded-lg shadow-lg cursor-pointer ${theme === 'dark' ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' : 'bg-gray-200 hover:bg-gray-300'}`}
              onClick={() => handleFolderClick(folder.folder_id)}
            >
              <FaFolder className="text-4xl text-primary mb-2" />
              <span className="text-lg font-semibold">{folder.folder_name}</span>
              <button
                onClick={(e) => { e.stopPropagation(); handleDeleteFolder(folder) }}
                className={`cursor-pointer mt-2 inline-flex justify-center items-center w-full rounded-md px-2 py-1 text-sm focus:outline-none transition ease-in-out duration-150 ${theme === 'dark' ? 'bg-red-900 text-gray-300 hover:bg-red-700' : 'bg-red-100 text-gray-700 hover:bg-red-500'}`}
                title="Delete Folder"
              >
                <FaTrash className="mr-1" />
                Delete
              </button>
            </div>
          ))}
          {chats.length > 0 && chats.filter(chat => !chat.folder_id).map(chat => (
            <div
              key={chat.chat_id}
              className={`flex flex-col items-center justify-center p-4 h-48 w-48 rounded-lg shadow-lg cursor-pointer ${theme === 'dark' ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' : 'bg-gray-200 hover:bg-gray-300'}`}
            >
              <span className="text-lg font-semibold">{chat.chat_name}</span>
              <p className={`text-sm ${theme === 'dark' ? 'text-gray-300' : 'text-gray-500'}`}>{formatTimestamp(chat.last_modified)}</p>
              <div className="grid grid-cols-2 gap-2 mt-2 w-full">
                <button onClick={() => handleChatClick(chat)} type="button"
                  className={`cursor-pointer inline-flex justify-center items-center w-full rounded-md px-2 py-1 text-sm focus:outline-none transition ease-in-out duration-150 ${theme === 'dark' ? 'bg-blue-900 text-gray-300 hover:bg-blue-700' : 'bg-blue-100 text-gray-700 hover:bg-blue-500'}`}
                  title="Load Chat"
                >
                  <FaComments className="mr-1" />
                  Load
                </button>
                <button onClick={() => {
                  if (chat.folder_id) {
                    handleMoveToFolder(chat.chat_id)
                  } else {
                    handleAddToFolder(chat)
                  }
                }} type="button"
                  className={`cursor-pointer inline-flex justify-center items-center w-full rounded-md px-2 py-1 text-sm focus:outline-none transition ease-in-out duration-150 ${theme === 'dark' ? 'bg-yellow-900 text-gray-300 hover:bg-yellow-700' : 'bg-yellow-100 text-gray-700 hover:bg-yellow-500'}`}
                  title={chat.folder_id ? "Move Chat to Different Folder" : "Add to Folder"}
                >
                  <FaFolderOpen className="mr-1" />
                  {chat.folder_id ? "Move" : "Add"}
                </button>
                {(chat.username === username || chat.permissions === "owner") && (
                  <>
                    <button onClick={() => handleShowGrantUserAccessModal(chat)} type="button"
                      className={`cursor-pointer inline-flex justify-center items-center w-full rounded-md px-2 py-1 text-sm focus:outline-none transition ease-in-out duration-150 ${theme === 'dark' ? 'bg-green-900 text-gray-300 hover:bg-green-700' : 'bg-green-100 text-gray-700 hover:bg-green-500'}`}
                      title="Grant Access"
                    >
                      <FaUserPlus className="mr-1" />
                      Grant
                    </button>
                    <button onClick={() => warnDeleteChat(chat)} type="button"
                      className={`cursor-pointer inline-flex justify-center items-center w-full rounded-md px-2 py-1 text-sm focus:outline-none transition ease-in-out duration-150 ${theme === 'dark' ? 'bg-red-900 text-gray-300 hover:bg-red-700' : 'bg-red-100 text-gray-700 hover:bg-red-500'}`}
                      title="Delete Chat"
                    >
                      <FaTrash className="mr-1" />
                      Delete
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
        </>
      )}
      {showDeleteFolderWarning && (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex items-center justify-center">
          <div className={`p-6 rounded-lg shadow-lg relative ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
            <button
              onClick={() => setShowDeleteFolderWarning(false)}
              className="absolute top-2 right-2 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
            >
              <RedX />
            </button>
            <h2 className="text-lg font-semibold mb-4">Delete Folder</h2>
            <p>Are you sure you want to delete this folder? This action cannot be undone.</p>
            <div className="flex flex-col mt-4">
              <label className="mb-2">
                <input
                  type="radio"
                  name="deleteOption"
                  value="deleteChats"
                  checked={deleteChats}
                  onChange={() => setDeleteChats(true)}
                  className="mr-2"
                />
                Delete all chats in the folder
              </label>
              <label>
                <input
                  type="radio"
                  name="deleteOption"
                  value="reallocateChats"
                  checked={!deleteChats}
                  onChange={() => setDeleteChats(false)}
                  className="mr-2"
                />
                Move chats to another folder
              </label>
              {!deleteChats && (
                <select
                  value={reallocationFolder}
                  onChange={(e) => setReallocationFolder(e.target.value)}
                  className={`mt-2 p-2 rounded ${theme === 'dark' ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'}`}
                >
                  <option value="" disabled>Select a folder</option>
                  {folders.filter(f => f.folder_id !== folderToDelete.folder_id).map(folder => (
                    <option key={folder.folder_id} value={folder.folder_id}>{folder.folder_name}</option>
                  ))}
                </select>
              )}
            </div>
            <div className="flex space-x-4 mt-4">
              <button
                onClick={confirmDeleteFolder}
                className={`p-2 rounded ${theme === 'dark' ? 'bg-red-700 text-white hover:bg-red-500' : 'bg-red-500 text-white hover:bg-red-700'}`}
              >
                {deleteChats ? "Confirm - This cannot be undone" : "Confirm"}
              </button>
              <button
                onClick={() => setShowDeleteFolderWarning(false)}
                className={`p-2 rounded ${theme === 'dark' ? 'bg-blue-700 text-white hover:bg-blue-500' : 'bg-blue-500 text-white hover:bg-blue-700'}`}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {showAddToFolderModal && (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex items-center justify-center">
          <div className={`p-6 rounded-lg shadow-lg relative ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
            <button
              onClick={() => setShowAddToFolderModal(false)}
              className="absolute top-2 right-2 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
            >
              <RedX />
            </button>
            <h2 className="text-lg font-semibold mb-4">Select Folder</h2>
            <div className="grid grid-cols-1 gap-4">
              {folders.map(folder => (
                <button
                  key={folder.folder_id}
                  onClick={() => moveToFolder("", folder.folder_id)}
                  className={`cursor-pointer flex justify-center items-center w-full rounded-md px-4 py-2 text-sm focus:outline-none transition ease-in-out duration-150 ${theme === 'dark' ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'}`}
                >
                  {folder.folder_name}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      {showMoveToFolderModal && (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex items-center justify-center">
          <div className={`p-6 rounded-lg shadow-lg relative ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
            <button
              onClick={() => setShowMoveToFolderModal(false)}
              className="absolute top-2 right-2 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
            >
              <RedX />
            </button>
            <h2 className="text-lg font-semibold mb-4">Select New Folder for Chat</h2>
            <div className="grid grid-cols-1 gap-4">
              {folders.map(folder => (
                <button
                  key={folder.folder_id}
                  onClick={() => moveToFolder(selectedFolder, folder.folder_id, chatToMove)}
                  className={`cursor-pointer flex justify-center items-center w-full rounded-md px-4 py-2 text-sm focus:outline-none transition ease-in-out duration-150 ${theme === 'dark' ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'}`}
                >
                  {folder.folder_name}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      {showDeleteWarning && (
        <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className={`p-4 rounded-lg shadow-md ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
            <p className="mb-4">Are you sure you want to delete this chat?</p>
            <div className="flex space-x-4 items-center">
              <button
                onClick={deleteChat}
                className="bg-red-500 text-white p-2 rounded"
              >
                Delete
              </button>
              <button
                onClick={cancelDeleteChat}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
       )}
      {displayGrantUserAccessToChat && chatToGrantAccess && chatToGrantAccess.chat_name && (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex items-start justify-center overflow-y-auto p-4">
          <div className={`w-full max-w-lg rounded-lg shadow-lg my-8 ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
            <button
              onClick={() => setDisplayGrantUserAccessToChat(false)}
              className={`absolute top-2 right-2 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}
            >
              <RedX />
            </button>
            <div className="p-4">
              <div className="text-lg font-medium mb-4">Permissions for {chatToGrantAccess.chat_name}</div>
              {chatToGrantAccess.user_access && chatToGrantAccess.user_access.map(user => (
                <div key={user.username} className={`shadow-md rounded-lg p-4 mb-3 ${theme === 'dark' ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'}`}>
                  <div className="font-semibold">{user.username}</div>
                  <select
                    className={`mt-2 p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500 ${theme === 'dark' ? 'bg-gray-600 border-gray-300 text-gray-200' : 'border-gray-300 text-gray-900'}`}
                    onChange={(e) => handlePermissionChange(e, user.username)}
                    value={user.permissions}
                  >
                    <option value="read">Read</option>
                    <option value="write">Write</option>
                    <option value="owner">Owner</option>
                  </select>
                  <div className="flex space-x-2 mt-3">
                    <button
                      className={`flex-1 p-2 text-sm rounded-md transition duration-150 ease-in-out ${theme === 'dark' ? 'bg-blue-600 hover:bg-blue-500 text-white' : 'bg-blue-500 hover:bg-blue-600 text-white'}`}
                      onClick={() => handleGrantUserAccessToChat(chatToGrantAccess, user.username, user.permissions)}
                    >
                      Update Permissions
                    </button>
                    <button
                      className={`p-2 text-sm rounded-md transition duration-150 ease-in-out ${theme === 'dark' ? 'bg-red-600 hover:bg-red-500 text-white' : 'bg-red-500 hover:bg-red-600 text-white'}`}
                      onClick={() => handleRemoveAccess(chatToGrantAccess, user.username)}
                    >
                      Remove Permissions
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="px-4 pt-5 pb-4 text-center overflow-hidden shadow-xl transform transition-all sm:p-6">
              <div>
                <h3 className={`text-lg font-medium mb-4 ${theme === 'dark' ? 'text-gray-100' : 'text-gray-900'}`}>Grant User Access</h3>
                <input
                  type="text"
                  value={targetUsername}
                  onChange={(e) => setTargetUsername(e.target.value)}
                  className={`block p-2.5 w-full text-sm rounded-lg border ${theme === 'dark' ? 'bg-gray-600 border-gray-300 text-gray-200 focus:ring-gray-500 focus:border-gray-500' : 'border-gray-300 text-gray-900 bg-gray-50 focus:ring-primary focus:border-primary'}`}
                  placeholder="Enter username"
                />
                <select
                  className={`mt-4 block w-full p-2.5 text-sm rounded-lg ${theme === 'dark' ? 'bg-gray-600 border-gray-300 text-gray-200 focus:ring-gray-500' : 'bg-gray-50 border-gray-300 text-gray-900 focus:ring-primary'}`}
                  onChange={(e) => setPermissionLevel(e.target.value)}
                >
                  <option value="read">Read</option>
                  <option value="write">Write</option>
                  <option value="owner">Owner</option>
                </select>
                {grantAccessStatusMessage !== "" && (<div className='text-blue-500 pt-2 font-bold'>{grantAccessStatusMessage}</div>)}
                <div className="mt-2 sm:mt-4">
                  <button onClick={() => handleGrantUserAccessToChat(chatToGrantAccess, targetUsername, permissionLevel)} type="button" className={`inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 ${theme === 'dark' ? 'bg-blue-600 hover:bg-blue-500' : 'bg-blue-500 hover:bg-blue-600'} text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5`}>
                    Confirm
                  </button>
                  <button onClick={cancelAddGrantUserAccessToChat} type="button" className={`inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 mt-2 ${theme === 'dark' ? 'bg-pink-600 hover:bg-red-500' : 'bg-red-500 hover:bg-red-600'} text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5`}>
                    Close Window
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showWarning && (
        <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className={`p-4 rounded-lg shadow-md ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
            <p className="mb-4">Current chat will be lost. Make sure to save first.</p>
            <div className="flex space-x-4">
              <button
                onClick={handleContinue}
                className="bg-red-500 text-white p-2 rounded"
              >
                Continue Without Saving
              </button>
              <button
                onClick={() => { setSelectedChat(null); setShowWarning(false) }}
                className="bg-primary text-white p-2 rounded"
              >
                Go Back to Save
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  )
}

export default SavedChats
