import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import RedX from '../../component/RedX'

const SpecInfoSection = ({
  theme,
  showViewSpecInfo,
  setShowViewSpecInfo,
  summaryText,
  summaryFigure,
  showSummaryFigure,
  setSummaryText,
  setSummaryFigure,
  specInfo,
  setSpecInfo,
  setShowSummaryFigure,
  setCites,
  setTables,
  setEquations,
  setIsCitesVisible,
  setShowCites,
  setShowTables,
  setShowEquations,
  scrollToParagraph
}) => {

  const buttonClass = `flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-300 ease-in-out ml-1 ${
    theme === 'dark' ? 'bg-gray-800 text-gray-200 hover:bg-gray-600' : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
  }`

  return (
    <div className="mr-2">
      {showViewSpecInfo && (
        <div className={`p-4 rounded-lg ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
          {summaryFigure && showSummaryFigure && (
            <div className="mb-4">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">Summary Figure</h2>
                <button
                  onClick={() => setShowSummaryFigure(false)}
                  className="hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out"
                >
                  <RedX />
                </button>
              </div>
              <img src={summaryFigure} alt="Summary Figure" className="rounded-lg" />
            </div>
          )}
          {specInfo.cites && specInfo.cites.length > 0 && (
            <div className="mb-4">
              <h2 className="text-lg font-semibold">Citations</h2>
              <ul className="list-disc pl-5">
                {specInfo.cites.map((cite, index) => (
                  <li key={index}
                  onClick={() => scrollToParagraph(cite.para_id)}
                    className={`cursor-pointer ${theme === 'dark' ? 'text-white hover:bg-secondary hover:text-gray-100' : 'text-black hover:bg-gray-300'}`}>
                  {cite.cite_text}</li>
                ))}
              </ul>
            </div>
          )}
          {specInfo.tables && specInfo.tables.length > 0 && (
            <div className="mb-4">
              <h2 className="text-lg font-semibold">Tables</h2>
              <ul className="list-disc pl-5">
                {specInfo.tables.map((table, index) => (
                  <li key={index} onClick={() => scrollToParagraph(table.para_id)}
                    className={`cursor-pointer ${theme === 'dark' ? 'text-black hover:bg-secondary hover:text-gray-100' : 'text-black hover:bg-gray-300'}`}>
                  Table {table.table_id}: {table.para_id}</li>
                ))}
              </ul>
            </div>
          )}
          {specInfo.equations && specInfo.equations.length > 0 && (
            <div className="mb-4">
              <h2 className="text-lg font-semibold">Equations</h2>
              <ul className="list-disc pl-5">
                {specInfo.equations.map((equation, index) => (
                  <li key={index} onClick={() => scrollToParagraph(equation.para_id)}
                    className={`cursor-pointer ${theme === 'dark' ? 'text-black hover:bg-secondary hover:text-gray-100' : 'text-black hover:bg-gray-300'}`}>
                    Equation {equation.eq_id}: {equation.para_id}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default SpecInfoSection
