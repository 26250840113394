import React from 'react'

const PartsList = ({ parts }) => {
  const renderCitations = (citations) => {
    return citations && citations.length > 0 ? citations.map((citation, index) => (
      <span key={index} className="text-gray-500 text-sm">
        {citation}
        {index < citations.length - 1 && ', '}
      </span>
    )) : null
  }

  const renderReferenceNumerals = (referenceNumerals) => {
    return referenceNumerals && referenceNumerals.length > 0 ? referenceNumerals.join(', ') : null
  }

  const alphanumericSort = (a, b) => {
    const getNumericValue = (str) => {
      if (!str) return NaN
      return parseInt(str.replace(/\D/g, ''), 10)
    }

    const numA = getNumericValue(a)
    const numB = getNumericValue(b)

    if (isNaN(numA) || isNaN(numB)) {
      return (a || '').localeCompare(b || '')
    }
    return numA - numB
  }

  const sortedKeys = parts ? Object.keys(parts).sort((a, b) => {
    const refA = parts[a].reference_numerals ? parts[a].reference_numerals[0] : ''
    const refB = parts[b].reference_numerals ? parts[b].reference_numerals[0] : ''
    return alphanumericSort(refA, refB)
  }) : []

  const renderParts = () => {
    return sortedKeys.map((key) => (
      <div key={key} className="mt-4 p-4 bg-white rounded shadow-md">
        <h3 className="font-bold text-xl">
          {key.replace(/_/g, ' ')} {parts[key].reference_numerals && parts[key].reference_numerals.length > 0 && `(${renderReferenceNumerals(parts[key].reference_numerals)})`}
        </h3>
        {parts[key].description && (
          <p className="text-gray-700 mt-2">
            <span className="font-semibold">Description:</span> {parts[key].description}
          </p>
        )}
        {parts[key].details && (
          <p className="text-gray-700 mt-2">
            <span className="font-semibold">Details:</span> {parts[key].details}
          </p>
        )}
        {parts[key].citations && (
          <p className="text-gray-700 mt-2">
            <span className="font-semibold">Citations:</span> {renderCitations(parts[key].citations)}
          </p>
        )}
      </div>
    ))
  }

  return (
    <div className="p-6 bg-gray-100 rounded-lg">
      {renderParts()}
    </div>
  )
}

export default PartsList
