import React, { useState, useContext } from 'react'
import { getParaSummary } from '../api'
import ThemeContext from '../context/ThemeContext'

function DocParagraph({ header, text, showHeader, type, paraId, paraNum, specInfo, addParagraphTextToInput }) {
    const [showMenu, setShowMenu] = useState(false)
    const [summaryText, setSummaryText] = useState("")
    const [isHovered, setIsHovered] = useState(false)
    const [summaryIsLoading, setSummaryIsLoading] = useState(false)
    const { theme, setTheme } = useContext(ThemeContext)

    if (!text || (text && text.trim().length === 0)){
      return null
    }
    const handleParagraphClick = () => {
        setShowMenu(!showMenu)
    }

    const handleSummarizeClick = async () => {
        setSummaryIsLoading(true)
        const response = await getParaSummary(text, type)
        if (response && response.summary){
          setSummaryText(response.summary)
        }
        setSummaryIsLoading(false)
    }

    const handleAddCommentClick = () => {
        console.log("Add Comment Clicked!")
    }

    const handleAddToInputClick = () => {
        addParagraphTextToInput(text)
    }

    const handleAddSummaryToInputClick = () => {
        addParagraphTextToInput(summaryText)
    }

    const toggleMenu = () => {
      setShowMenu(!showMenu)
    }

    return (
        <div id={`paragraph-${paraNum}`} className={`rounded ${(showMenu ? (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-200') : '')}`} onClick={handleParagraphClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false || showMenu)}>
            {showHeader && <h2 className={`text-lg font-semibold rounded uppercase mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>{header}</h2>}
            {type == "upload" && (<p className={`whitespace-pre-wrap ${theme === 'dark' ? 'text-gray-400 hover:text-white' : 'text-gray-700 hover:text-black'}`}>(¶ {paraId.toString()}) {text}</p>)}
            {type != "upload" && type != "mpep" && (<p className={`whitespace-pre-wrap font-semibold ${theme === 'dark' ? 'text-gray-400 hover:text-white' : 'text-gray-700 hover:text-black'}`}><b>{paraId.toString().padStart(4, '0')}</b> {text}</p>)}
            {type == "mpep" && (<><p className={`whitespace-pre-wrap ${theme === 'dark' ? 'text-gray-400 hover:text-white' : 'text-gray-700 hover:text-black'}`}><b>¶ {paraId.toString()}</b> {text}</p></>)}
            {summaryIsLoading && (<p className={`text-white rounded p-1 ${theme === 'dark' ? 'bg-blue-600' : 'bg-blue-400'}`}>Generating summary . . .</p>)}
            {summaryText != "" && (<div className={`mt-1 mb-1 rounded p-1 ${theme === 'dark' ? 'text-white bg-blue-800' : 'text-black bg-blue-200'}`}>
              <div className={`text-md font-semibold uppercase ${theme === 'dark' ? 'text-gray-300 ' : 'text-gray-700'}`}>Generated Summary for {paraId.toString().padStart(4, '0')}</div>
              <div>{summaryText}</div>
              <button className={`text-sm text-gray-700 p-1 rounded ${theme === 'dark' ? 'text-gray-300 hover:bg-gray-300 hover:text-black ' : 'text-gray-700 hover:bg-gray-300'}`} onClick={handleAddSummaryToInputClick} >
                  Add to Input
              </button>
            </div>)}
            {isHovered && (
            <>
                <button onClick={toggleMenu} className={`bg-transparent text-sm rounded-full px-6 py-1 transition-colors duration-300 ease-in-out w-full md:w-auto ${theme === 'dark' ? 'text-gray-700 ' : 'text-gray-400'}`}>
                  <div className={`px-4 py-1 text-xxs rounded inset-0 hover:opacity-70 ${theme === 'dark' ? 'text-gray-300 hover:bg-blue-300 hover:text-white' : 'hover:bg-blue-700'}`}>- menu -</div>
                </button>
                {showMenu && (
                    <div className={`rounded-lg shadow-lg ${theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-gray-200'}`}>
                        <button
                            className={`text-sm p-1 rounded ${theme === 'dark' ? 'text-gray-400 hover:bg-gray-700 hover:text-gray-200' : 'text-gray-700 hover:bg-gray-300 '}`}
                            onClick={handleSummarizeClick}
                        >
                            Summarize
                        </button>
                        <button
                            className={`text-sm p-1 rounded ${theme === 'dark' ? 'text-gray-400 hover:bg-gray-700 hover:text-gray-200' : 'text-gray-700 hover:bg-gray-300 '}`}
                            onClick={handleAddToInputClick}
                        >
                            Add to Input
                        </button>
                    </div>
                )}
               </>
              )}
        </div>
    )
}

export default DocParagraph
