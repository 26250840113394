import { useContext } from 'react'
import ThemeContext from '../context/ThemeContext'
const DisclaimerModal = ({ onClose }) => {
  const { theme, setTheme } = useContext(ThemeContext)
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className={`relative top-20 mx-auto p-5 border min-w-[calc(400px)] max-w-[calc(700px)] shadow-lg rounded-md ${theme === 'dark' ? 'text-white bg-gray-900' : 'bg-white'}`}>
        <div className="mt-3 text-center">
          <h3 className={`text-lg leading-6 font-medium  ${theme === 'dark' ? 'text-gray-200' : 'text-gray-900'}`}>Disclaimer</h3>
          <div className={`mt-2 px-7 py-3 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
            <p className="text-sm">
              Please read this disclaimer carefully before using our application.
            </p>
            <p className="text-sm mt-4">
              The information provided by our application, <strong>"Patents.chat"</strong>, is for general informational purposes only. All information in the application is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the application.
            </p>
            <p className="text-sm mt-4">
              Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the application or reliance on any information provided on the application. Your use of the application and your reliance on any information on the application is solely at your own risk.
            </p>
            <p className="text-sm mt-4">
              This application does not provide legal advice. The information is provided for general informational and educational purposes only and is not a substitute for professional legal advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate legal professionals or licensing authorities.
            </p>
            <p className="text-sm mt-4">
              The application may contain links to external websites that are not provided or maintained by or in any way affiliated with us. Please note that we do not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.
            </p>
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="okButton"
              className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={onClose}
            >
              I Understand & Agree to Use Wisely at My Own Risk
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DisclaimerModal