import React, { useState, useContext } from 'react'
import { FaFileAlt, FaParagraph, FaClipboardList, FaInfoCircle, FaPlus, FaTrash, FaUpload } from 'react-icons/fa'
import ThemeContext from '../context/ThemeContext'

const ResourceSelector = ({ onSelect, onClose, contextData, addToResources }) => {
  const { theme } = useContext(ThemeContext)
  const [selectedResourceType, setSelectedResourceType] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([])
  const [resourceId, setResourceId] = useState('')

  const resourceTypes = [
    { id: 'patent', label: 'Patent', icon: <FaFileAlt /> },
    { id: 'publication', label: 'Publication', icon: <FaFileAlt /> },
    { id: 'upload', label: 'Upload', icon: <FaUpload /> },
    { id: 'mpep', label: 'MPEP', icon: <FaClipboardList /> },
    { id: 'usc', label: 'USC', icon: <FaInfoCircle /> },
    { id: 'cfr', label: 'CFR', icon: <FaInfoCircle /> },
  ]

  const optionsByResourceType = {
    patent: [
      'Entire Document', 'Specific Paragraphs', 'Specific Drawings', 'Specific Claims', 'All Claims',
      'All Description (excl. claims and drawings)', 'Patent Application Bibliographic Info',
      'Patent Application File History',
    ],
    publication: [
      'Entire Document', 'Specific Paragraphs', 'Specific Drawings', 'Specific Claims', 'All Claims',
      'All Description (excl. claims and drawings)', 'Patent Application Bibliographic Info',
      'Patent Application File History',
    ],
    upload: [
      'Entire Document', 'Specific Paragraphs', 'Relevant Sections',
    ],
    resource: [
      'Entire Document', 'Specific Paragraphs', 'Relevant Sections',
    ],
    mpep: [
      'Specific Sections', 'Relevant Sections',
    ],
    usc: [
      'Specific Sections', 'Relevant Sections',
    ],
    cfr: [
      'Specific Sections', 'Relevant Sections',
    ],
  }

  const availableResources = {
    patent: contextData.current_patents || [],
    publication: contextData.current_publications || [],
    upload: contextData.resource_ids.map((id, index) => ({
      id,
      name: contextData.resource_names[index] || id,
    })) || [],
    mpep: contextData.mpep_sections || [],
    usc: contextData.usc_sections || [],
    cfr: contextData.cfr_sections || [],
  }

  const handleResourceTypeChange = (resourceType) => {
    setSelectedResourceType(resourceType)
    setSelectedOptions([])
  }

  const handleOptionChange = (option) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.includes(option) ? prevOptions.filter((o) => o !== option) : [...prevOptions, option]
    )
  }

  const handleSubmit = () => {
    if (resourceId) {
      addToResources([{ type: selectedResourceType, id: resourceId }])
    }
    onSelect(selectedResourceType, resourceId, selectedOptions)
    onClose()
  }

  return (
    <div className={`p-6 ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} rounded-lg shadow-lg`} >
      <div className={`flex flex-row`}>
        <div className="m-4 flex flex-col">
          <h2 className="text-lg font-semibold mb-4">Select Resource and Options</h2>
          <label className="block text-sm font-medium mb-2">Resource Type</label>
          <div className="grid grid-cols-2 gap-4">
            {resourceTypes.map((resource) => (
              <button
                key={resource.id}
                onClick={() => handleResourceTypeChange(resource.id)}
                className={`flex items-center justify-center p-2 rounded ${theme === 'dark' ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'} ${
                  selectedResourceType === resource.id ? 'ring-2 ring-primary' : ''
                }`}
              >
                {resource.icon}
                <span className="ml-2">{resource.label}</span>
              </button>
            ))}
          </div>
        </div>
        {selectedResourceType && (
          <div className="mb-4 max-w-[calc(50vw)] w-fit max-w-[600px]">
            <label className="block text-sm font-medium mb-2">Available Resources</label>
            <div className="grid grid-cols-1 gap-2 overflow-auto" style={{ maxHeight: '100px' }}>
              {availableResources[selectedResourceType].map((resource) => (
                <button
                  key={resource.id || resource}
                  onClick={() => setResourceId(resource.id || resource)}
                  className={`flex items-center justify-center p-2 rounded ${theme === 'dark' ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'} ${
                    resourceId === (resource.id || resource) ? 'ring-2 ring-primary' : ''
                  }`}
                >
                  {resource.name || resource}
                </button>
              ))}
            </div>
            <label className="block text-sm font-medium mb-2">Enter Resource ID</label>
            <input
              type="text"
              value={resourceId}
              onChange={(e) => setResourceId(e.target.value)}
              className={`w-full p-2 mb-4 rounded ${theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-900'}`}
              placeholder="Enter Resource ID"
            />
            <label className="block text-sm font-medium mb-2">Options</label>
              <div className="flex flex-wrap gap-2">
                {optionsByResourceType[selectedResourceType].map((option) => (
                  <button
                    key={option}
                    onClick={() => handleOptionChange(option)}
                    className={`flex items-center justify-center p-2 rounded ${theme === 'dark' ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'} ${
                      selectedOptions.includes(option) ? 'ring-2 ring-primary' : ''
                    }`}
                  >
                    <FaParagraph className="mr-2" />
                    {option}
                  </button>
                ))}
            </div>
          </div>
        )}
        {selectedResourceType && (
          <div className="mb-4">

          </div>
        )}
      </div>
      <button
        onClick={handleSubmit}
        className={`mt-4 p-2 rounded ${theme === 'dark' ? 'bg-blue-600 hover:bg-blue-500' : 'bg-blue-500 hover:bg-blue-600'} text-white w-full`}
      >
        Confirm Selection
      </button>
    </div>
  )
}

const PromptBuilder = ({ contextData, addToResources }) => {
  const { theme } = useContext(ThemeContext)
  const [isStarted, setIsStarted] = useState(false)
  const [inputText, setInputText] = useState('')
  const [resources, setResources] = useState([])
  const [isAddingResource, setIsAddingResource] = useState(false)

  const handleStart = () => {
    setIsStarted(true)
  }

  const handleAddResource = (resourceType, resourceId, options) => {
    setResources([...resources, { resourceType, resourceId, options }])
    setIsAddingResource(false)
  }

  const handleRemoveResource = (index) => {
    setResources(resources.filter((_, i) => i !== index))
  }

  const handleSubmit = () => {
    // Handle the submission logic here
    console.log('Input Text:', inputText)
    console.log('Resources:', resources)
  }

  return (
    <div className={`p-6 ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} min-w-[450px] rounded-lg shadow-lg`}>
      {!isStarted ? (
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">Welcome to the Prompt Builder</h1>
          <p className="mb-4">This tool allows you to build a prompt by adding contextual resources and input text</p>
          <button
            onClick={handleStart}
            className={`p-2 rounded ${theme === 'dark' ? 'bg-blue-600 hover:bg-blue-500' : 'bg-blue-500 hover:bg-blue-600'} text-white`}
          >
            Start
          </button>
        </div>
      ) : (
        <div>
          <h2 className="text-lg font-semibold mb-4">Instruction</h2>
          <textarea
            className={`w-full p-2 mb-4 rounded ${theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-900'}`}
            rows="4"
            placeholder="Enter your question or instruction here..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          ></textarea>
          <h2 className="text-lg font-semibold mb-4">Context</h2>
          {resources.map((resource, index) => (
            <div key={index} className="flex items-center justify-between p-2 mb-2 rounded border">
              <div>
                <p className="font-medium">{resource.resourceType} {resource.resourceId}</p>
                <ul className="list-disc list-inside">
                  {resource.options.map((option, i) => (
                    <li key={i}>{option}</li>
                  ))}
                </ul>
              </div>
              <button
                onClick={() => handleRemoveResource(index)}
                className={`p-1 rounded ${theme === 'dark' ? 'bg-red-700 hover:bg-red-500' : 'bg-red-500 hover:bg-red-700'} text-white`}
              >
                <FaTrash />
              </button>
            </div>
          ))}
          {isAddingResource ? (
            <ResourceSelector onSelect={handleAddResource} onClose={() => setIsAddingResource(false)} contextData={contextData} addToResources={addToResources} />
          ) : (
            <button
              onClick={() => setIsAddingResource(true)}
              className={`flex items-center justify-center p-2 mt-4 rounded ${theme === 'dark' ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'}`}
            >
              <FaPlus className="mr-2" />
              Add Resource
            </button>
          )}
          <button
            onClick={handleSubmit}
            className={`mt-4 p-2 rounded ${theme === 'dark' ? 'bg-green-600 hover:bg-green-500' : 'bg-green-500 hover:bg-green-600'} text-white w-full`}
          >
            Submit Prompt
          </button>
        </div>
      )}
    </div>
  )
}

export default PromptBuilder
