import React, { useState } from 'react'
import { confirmSignup, resendCode } from '../api'

function ConfirmSignUp({ onConfirm, closeConfirmSignUpWindow }) {
  const [username, setUsername] = useState('')
  const [confirmationCode, setConfirmationCode] = useState('')
  const [message, setMessage] = useState('')

  const handleConfirmation = async () => {
    try {
      if (username == "" || confirmationCode == "") {
         setMessage("Please enter username and confirmation code.")
         return
      }
      confirmSignup({ username, confirmation_code: confirmationCode })
      onConfirm()
    } catch (error) {
      setMessage(error.response.data.error)
    }
  }

  const handleResend = async () => {
    try {
      if (username == "") {
         setMessage("Please enter username to resend code.")
         return
      }
      resendCode(username).then(() => {
          setMessage("Code has been reset")
      })
      .catch(() => {
          setMessage("Error. Check username.  If problems persist contact info@forgen.ai")
      })
    } catch (error) {
      setMessage(error.response.data.error)
    }
  }

  const closeConfirmSignUp = () => {
    closeConfirmSignUpWindow()
  }

  return (
    <div className='flex items-center justify-center w-64 my-1 h-full'>
      <div className='p-4 my-2 border rounded shadow md:h-[350px] w-64 bg-blue-100'>
        <div className='text-2xl text-center font-bold mb-2'>
            Confirm account
        </div>
        <p className='mb-4 text-sm'>Please check your email for the confirmation code.</p>
        <form className='mt-4 space-y-4'>
          <div className='rounded-md shadow-sm -space-y-px'>
            <div className='mb-2'>
              <label htmlFor='username' className='sr-only'>Username</label>
              <input
                id='username'
                name='username'
                type='text'
                required
                className='appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                placeholder='Username'
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </div>
            <div className='mt-2'>
              <label htmlFor='confirmation-code' className='sr-only'>Confirmation Code</label>
              <input
                id='confirmation-code'
                name='confirmation-code'
                type='text'
                required
                className='appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                placeholder='Confirmation Code'
                value={confirmationCode}
                onChange={e => setConfirmationCode(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type='button'
              className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={handleConfirmation}
            >
              Confirm
            </button>
            <button
              type='button'
              className='group relative w-full flex justify-center my-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-200 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              onClick={handleResend}
            >
              Resend Code
            </button>
            <button
              type='button'
              className='group relative w-full flex justify-center my-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-200 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              onClick={closeConfirmSignUp}
            >
              Go Back
            </button>
          </div>
          {message && (
            <p className='mt-2 text-center text-sm text-red-600'>
              {message}
            </p>
          )}
        </form>
      </div>
    </div>
  )
}

export default ConfirmSignUp
