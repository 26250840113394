import React, { useState, useContext } from 'react'
import Philosophy from '../info/Philosophy'
import ThemeContext from '../context/ThemeContext'

const PricingModelDesc = () => {
  const { theme } = useContext(ThemeContext)
  const [showPhilosophy, setShowPhilosophy] = useState(false)
  return (<><h3 className="font-semibold mt-2">Pricing:</h3>
          <div className={`text-sm italic ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
            Our philosophy is that advanced tools should be available to everyone, not just large firms or companies with the ability to afford expensive SOTA IP tools.
            {!showPhilosophy && (<> <span onClick={() => {setShowPhilosophy(true)}} className={`ml-1 px-1 rounded cursor-pointer text-sm italic border ${theme === 'dark' ? 'border-gray-500 hover:bg-gray-900 hover:border-gray-200 text-gray-400 hover:text-gray-200 hover:bg-gray-900' : 'text-gray-600 border-gray-300 hover:border-gray-800 hover:text-gray-800 hover:bg-gray-100'}`}>
              Click here to learn more.
            </span></>)}
          </div>
          {showPhilosophy && (<>
            <Philosophy />
            <span onClick={() => {setShowPhilosophy(false)}} className={`px-1 rounded cursor-pointer text-sm text-pink-200 border-pink-200 italic border hover:border-red-500 ${theme === 'dark' ? 'hover:bg-gray-900 hover:text-red-200 hover:border-gray-200 text-gray-400 hover:bg-gray-900' : 'hover:text-red-500 text-gray-600 border-gray-300 hover:border-gray-800 hover:bg-gray-100'}`}>
             Close About Us
            </span>
          </>)}
          {!showPhilosophy && (<><ul className={`list-disc pl-5 ${theme === 'dark' ? 'text-gray-200' : 'text-gray-600'}`}>
            <li><span className="font-semibold">$5 per month</span> for the base subscription, which is charged each month starting when you first sign up and pay. This grants you access to our platform.</li>
            <li><span className="font-semibold">Usage charges</span> apply for accessing the AI model, known as the "Brain", and are billed based on the number of times you use it:</li>
            <ul className="pl-4">
              <li><span className="font-semibold">GPT-4 Calls:</span> $0.50 per call.</li>
              <li><span className="font-semibold">GPT-4 Turbo Calls:</span> $1 per call.</li>
            </ul>
            <li>Currently, the model used is automatically chosen and tailored to the user input. In general, the GPT-4 model is used unless a larger input to the model is needed in which case the GPT-4-Turbo model is used.</li>
            <li>To help reduce costs, you can reduce the "Max Iteration Loops" value in the settings. This value sets a maximum number of model calls per input.</li>
            <li>Generally, straightforward user requests will use one GPT-4 model call and potentially one additional call to either GPT-4 or GPT-4-Turbo.</li>
            {/*<li>User messages under 10 words are not counted toward's a user's usage</li>*/}
            <li>The usage fees are invoiced and charged at the end of each month.</li>
            <li>The usage fees cover the costs for using the state of the art (SOTA) large language models.</li>
            <li>Package deals available. For information, contact sales@forgen.ai.</li>
          </ul></>)}
       </>)
}

export default PricingModelDesc
