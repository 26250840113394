import React from 'react'
import Issue from '../op/Issue'

const IssueList = ({ appNo, issues }) => {
  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Issues</h2>
      {issues.map((issue, index) => (
        <Issue key={index} app_no={appNo} issue={issue} issue_id={index} />
      ))}
    </div>
  )
}

export default IssueList
