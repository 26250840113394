function removeCountryAndKindCodes(str) {
  if (!str) return str

  // Remove country code if it starts with two uppercase letters
  if (/^[A-Z]{2}/.test(str)) {
    str = str.substring(2)
  }
  // Remove kind code if it ends with an alphabetic character followed by an optional alphanumeric character
  const kindCodeMatch = /[A-Z][A-Z0-9]?$/.exec(str)
  if (kindCodeMatch) {
    str = str.substring(0, str.length - kindCodeMatch[0].length)
  }

  return str
}

const getParagraphNumber = (paragraphNumber) => {
  const matches = paragraphNumber.match(/\d+/)
  return matches ? parseInt(matches[0], 10) : null
}

function formatApplicationNumber(number) {
  // Convert the number to a string
  let numStr = number.toString()

  // Ensure the number is exactly 8 digits
  if (numStr.length !== 8) {
    console.error('The number must be exactly 8 digits long.')
    return null // Or handle this case as you see fit
  }

  // Insert slash after the second digit and comma after the fifth digit
  return `${numStr.substring(0, 2)}/${numStr.substring(2, 5)},${numStr.substring(5)}`
}

function formatPubNumber(pubNumber) {
  // Assuming the year is always represented by 4 digits after the initial country code (US)
  const countryCodeLength = 2
  const yearLength = 4
  const kindCodeLength = 2
  const formattedNumber = `${pubNumber.substring(0, countryCodeLength)} ${pubNumber.substring(countryCodeLength, countryCodeLength + yearLength)}/${pubNumber.substring(countryCodeLength + yearLength, pubNumber.length - kindCodeLength)} ${pubNumber.substring(pubNumber.length - kindCodeLength)}`
  return formattedNumber
}

function formatNumberWithCommas(number) {
  const numStr = number.toString()
  let result = ''

  for (let i = numStr.length; i > 0; i -= 3) {
    if (i - 3 > 0) {
      result = ',' + numStr.substring(i - 3, i) + result
    } else {
      result = numStr.substring(0, i) + result
    }
  }

  return result
}

export {
  removeCountryAndKindCodes,
  formatApplicationNumber,
  formatPubNumber,
  formatNumberWithCommas,
  getParagraphNumber
}
