import React, { useState, useEffect } from 'react'
import { fetchDocument } from '../api'

const MpepContent = ({ resourceNumber }) => {
  const [documentData, setDocumentData] = useState(null)
  const [theme, setTheme] = useState('dark')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDocument("mpep", resourceNumber)
        if (response && response.description) {
          setDocumentData(response)
        }
        setLoading(false)  // Ensure to set loading to false after fetching data
      } catch (error) {
        console.error('Error fetching document:', error)
        setLoading(false)  // Ensure to handle loading state in case of an error
      }
    }

    fetchData()
  }, [resourceNumber])  // Dependency array ensures this effect runs when resourceNumber changes

  if (loading) {
    return <p>Loading...</p>
  }

  if (!documentData || !documentData.description) {
    return (
      <div className={`flex items-center justify-center h-full ${theme === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-primary text-black'}`}>
        <p className="text-lg font-semibold">No data available.</p>
      </div>
    )
  }

  return (
    <div className={`flex flex-col max-h-[calc(100vh-74px)] overflow-y-auto p-6 ${theme === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-primary text-black'}`}>
      {documentData.description.map((item, index) => (
        <div
          key={index}
          className={`flex flex-col items-start justify-center p-6 mb-4 rounded-lg shadow-lg cursor-pointer ${theme === 'dark' ? 'hover:bg-gray-700' : 'bg-gray-200 hover:bg-gray-300'}`}
          onClick={() => console.log(`Selected: ${index}`)}
        >
          <h3 className="text-lg font-semibold mb-2">{item.header || '¶ ' + (index + 1)}</h3>
          <p>{item.content}</p>
        </div>
      ))}
    </div>
  )
}

export default MpepContent
