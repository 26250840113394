import React, { useState } from 'react'
import { FaSearchPlus, FaSearchMinus, FaRedo } from 'react-icons/fa' // FontAwesome icons for buttons

const ScrollableViewer = ({ drawingUrls }) => {
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)

  const handleRotate = () => {
    setRotation((prevRotation) => prevRotation + 90)
  }

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)) // Limit max zoom level to 3x
  }

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5)) // Limit min zoom level to 0.5x
  }

  return (
    <div className="w-full mx-auto mt-10">
      <div className="flex justify-center mb-4">
        <div onClick={handleRotate} className="p-2 mx-1 bg-gray-400 rounded hover:bg-gray-500 cursor-pointer">
          <FaRedo />
        </div>
        <div onClick={handleZoomIn} className="p-2 mx-1 bg-gray-400 rounded hover:bg-gray-500 cursor-pointer">
          <FaSearchPlus />
        </div>
        <div onClick={handleZoomOut} className="p-2 mx-1 bg-gray-400 rounded hover:bg-gray-500 cursor-pointer">
          <FaSearchMinus />
        </div>
      </div>
      <div className="overflow-y-auto max-h-screen p-4 bg-gray-100 shadow-lg rounded-lg w-full">
        {drawingUrls.map((url, index) => (
          <div key={index} className="mb-2">
            <img
              src={url}
              id={`drawing-${index + 1}`}
              alt={`Drawing ${index + 1}`}
              className="w-full h-auto border border-gray-300 rounded transform"
              style={{
                transform: `rotate(${rotation}deg) scale(${zoom})`,
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ScrollableViewer
