import React, { useEffect } from 'react'
import { FaComments, FaFileAlt, FaImages, FaFolderOpen, FaBook, FaHistory, FaWrench, FaQuestionCircle, FaChartBar, FaCog, FaUser } from 'react-icons/fa'
import { getScrollStyles } from '../component/custom_scroll'

const VerticalNavigationBar = ({ onSelect, selectedSection, theme }) => {
  const navItems = [
    { id: 'chat', icon: <FaComments />, label: 'Chat' },
    { id: 'document', icon: <FaFileAlt />, label: 'Document' },
    { id: 'drawings', icon: <FaImages />, label: 'Drawings' },
    { id: 'applications', icon: <FaFolderOpen />, label: 'Applications' },
    { id: 'saved_chats', icon: <FaHistory />, label: 'History' },
    { id: 'builder', icon: <FaWrench />, label: 'Prompt' },
    { id: 'resources', icon: <FaBook />, label: 'Resources' },
    { id: 'faq', icon: <FaQuestionCircle />, label: 'FAQ' },
    { id: 'usage', icon: <FaChartBar />, label: 'Usage' },
    { id: 'settings', icon: <FaCog />, label: 'Settings' },
    { id: 'account', icon: <FaUser />, label: 'Account'}
  ]

  useEffect(() => {
    const styles = getScrollStyles(theme)
    const styleSheet = document.createElement("style")
    styleSheet.type = "text/css"
    styleSheet.innerText = styles
    document.head.appendChild(styleSheet)
    return () => {
      document.head.removeChild(styleSheet)
    }
  }, [theme])

  return (
    <div className={`w-20 h-screen flex flex-col items-center pt-5 ${theme === 'dark' ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <div
        className={`w-20 top-0 fixed py-4 text-center cursor-pointer flex flex-col items-center justify-center ${theme === 'dark' ? 'bg-gray-900' : 'bg-gray-100'}`}
        onClick={() => onSelect('chat')}
      >
        <img src="Logo-1-small.png" className="py-1 h-[60px]" alt="Logo" />
        <span className={`text-xs font-semibold mt-1 block ${theme === 'dark' ? 'text-gray-200' : 'text-gray-800'}`}>Patents.chat</span>
      </div>
      <div
        className={`w-full mt-16 py-4 text-center cursor-pointer flex flex-col items-center justify-center`}
      ></div>
      {navItems.map(item => (
        <div
          key={item.id}
          className={`w-full py-4 text-center cursor-pointer flex flex-col items-center justify-center ${
            selectedSection === item.id ? 'bg-primary-300 text-primary-1-quarter bg-primary-half' : `${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`
          }`}
          onClick={() => onSelect(item.id)}
        >
          <div className={`text-2xl ${selectedSection === item.id ? `${theme === 'dark' ? 'text-secondary' : 'text-white'}` : `${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}`}>
            {item.icon}
          </div>
          <span className={`text-xs mt-1 ${selectedSection === item.id ? `${theme === 'dark' ? 'text-secondary' : 'text-white font-bold'}` : `${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}`}>{item.label}</span>
        </div>
      ))}
    </div>
  )
}

export default VerticalNavigationBar
