import React, { useState } from 'react'
import { runCustomAnalysis, getCustomAnalysisResult } from '../api'
import ResourceProcessingStatus from '../component/ResourceProcessingStatus'


function CustomAnalysis({ onClose, patentNumber, pubNumber }) {
  const [text, setText] = useState('')
  const [selectedNumber, setSelectedNumber] = useState('')
  const [resourceType, setResourceType] = useState('')
  const [error, setError] = useState('')
  const [jobId, setJobId] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  const [notLoaded, setNotLoaded] = useState(true)
  const [analysisResult, setAnalysisResult] = useState("")

  const handleClose = () => {
     setShowPopup(false)
     onClose()
  }

  const handleSelectChange = (e) => {
    const value = e.target.value
    if (value === patentNumber) {
      setResourceType('patent')
    } else if (value === pubNumber) {
      setResourceType('publication')
    }
    setSelectedNumber(value)
  }

  const handleSubmit = async() => {
    if (selectedNumber === '') {
      setError('Please select a number.')
      return
    }
    setError('')
    const response = await runCustomAnalysis(selectedNumber, resourceType, text)
    const newJobId = response["job_id"]
    setJobId(newJobId)

//    setTimeout(() => setJobId('job1234'), 1000)
//    onClose()
  }

  const getAndShowCustomAnalysisResult = async (jobId) => {
      const response = await getCustomAnalysisResult(jobId)
      setAnalysisResult(response["analysis"])
      setNotLoaded(false)
      setShowPopup(true)
    }

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      {!showPopup && (<div className="bg-white p-4 rounded text-center w-[450px]">
          <div className="text-xl p-1">Custom Analysis</div>
          <div className="text p-4">select document and enter question/instruction</div>
          <select onChange={handleSelectChange} className="mb-2 p-2 border rounded">
            <option value="">--Please select a number--</option>
            <option value={patentNumber}>Patent Number: {patentNumber}</option>
            <option value={pubNumber}>Publication Number: {pubNumber}</option>
          </select>
          {error && <div className='text-red-500 p-2'>{error}</div>}
          <textarea
            value={text}
            onChange={e => setText(e.target.value)}
            className="w-full h-32 p-2 border rounded"
          />
          <button onClick={handleSubmit} className="bg-blue-500 text-white p-2 rounded mt-2">
            Submit
          </button>
          <button onClick={onClose} className="bg-red-500 text-white p-2 rounded mt-2 ml-2">
            Close
          </button>
          {jobId && notLoaded && (
            <ResourceProcessingStatus
              jobId={jobId}
              resourceName="Custom Analysis"
              completeCallback={() => { getAndShowCustomAnalysisResult(jobId) }}
              hideName={false}
            />
          )}
        </div>
      )}
      {showPopup && (
        <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 relative">
          <button
            onClick={handleClose}
            className="absolute top-0 right-0 bg-white p-2 rounded-full hover:shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            aria-label="Close"
          >
            <svg
              className="w-4 h-4 text-red-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          <pre className="text-left whitespace-pre-wrap p-2">Input: {text}</pre>
          <pre className="text-left whitespace-pre-wrap p-2">Output: {analysisResult}</pre>
        </div>
      )}
    </div>
  )
}

export default CustomAnalysis
