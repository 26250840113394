import React from 'react'

function HighlightTerm({ text, term }) {
    // Create a regex that matches the term in a case-insensitive way and captures entire term occurrences
    const parts = text.split(new RegExp(`(${term})`, 'gi'))

    return (
        <div>
            {parts.map((part, index) => (
                // Check if the part is exactly the term using a case-insensitive comparison
                part.toLowerCase() === term.toLowerCase() ?
                <strong key={index}>{part}</strong> : // Highlight the term
                <span key={index}>{part}</span> // Render non-term parts normally
            ))}
        </div>
    )
}

export default HighlightTerm
