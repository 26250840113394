import React from 'react'
import IssueResponse from '../op/IssueResponse'

const IssueResponseList = ({ issues }) => {
  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Issues</h2>
      {issues.map((issue, index) => (
        <IssueResponse key={index} issue={issue} />
      ))}
    </div>
  )
}

export default IssueResponseList
