import React, { useState, useEffect, useRef } from 'react'
import LoginForm from './landing/LoginForm'
import SignupForm from './landing/SignUpForm'
import UserInfo from './chat_room/UserInfo'
import ChatRoom from './chat_room/ChatRoom'
import ConfirmSignUp from './landing/ConfirmSignUp'
import FileInput from './component/FileInput'
import ContactPopup from './info/ContactPopup'
import Footer from './general/Footer'
import OpenAIIcon from './general/OpenAIIcon'
import ForGenAI from './general/ForGenAI'
import About from './info/About'
import UsageWindow from './chat_room/UsageWindow'
import AccountWindow from './chat_room/AccountWindow'
import ThemeContext from './context/ThemeContext'
import { checkLogin, logout, refreshTokens, goToPaymentPage, setPaymentSuccessful, delete_the_storage, fetchSettings, updateSettings } from './api'
import styles from './ColorCycle.module.css'
import { FaUser } from 'react-icons/fa'

function getInitialTheme() {
  if (typeof window !== 'undefined') {
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme && savedTheme !== 'system') {
      return savedTheme
    }
    else
    {
      const systemMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
      return (systemMediaQuery.matches ? 'dark' : 'light')
    }
  }
  return 'light'
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isSignedUp, setIsSignedUp] = useState(false)
  const [userSettings, setUserSettings] = useState({})
  const [theme, setTheme] = useState(() => {
    return getInitialTheme()
  })
  const [isSaved, setIsSaved] = useState(true)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [showSaveWarning, setShowSaveWarning] = useState(false)
  const [userInfo, setUserInfo] = useState(null)
  const [lastActivity, setLastActivity] = useState(Date.now())
  const refreshInterval = useRef(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false)
  const [paymentPopupText, setPaymentPopupText] = useState("")
  const [username, setUsername] = useState("")
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [showUsageWindow, setShowUsageWindow] = useState(false)
  const [showAccountWindow, setShowAccountWindow] = useState(false)
  const [hideDragBars, setHideDragBars] = useState(false)

  useEffect(() => {
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)
    if (queryParams.has('p')) {
      const pValue = queryParams.get('p')
      console.log(`p = ${pValue}`)
      if (isPaymentPopupOpen) {
        return
      }
      if (pValue === '0') {
        setIsPaymentPopupOpen(true)
        setPaymentPopupText("Payment unsuccessful. Please contact info@forgen.ai for assistance.")
      } else {
        setPaymentSuccessful(pValue)
        setIsPaymentPopupOpen(true)
        setPaymentPopupText("Payment successful. Please login below to get started.")
      }
      queryParams.delete('p')
      window.history.replaceState(null, null, '?' + queryParams.toString())
    } else {
      console.log('payment key "p" is not present in the query parameters.')
    }

  }, [])

  useEffect(() => {
      // Global click event listener
      const updateLastActivity = () => {
          setLastActivity(Date.now())
      }
      window.addEventListener('click', updateLastActivity)
      // Start the refresh interval
      refreshInterval.current = setInterval(() => {
          if (Date.now() - lastActivity > 5 * 60 * 1000) { // 5 minutes inactivity
              clearInterval(refreshInterval.current)
          } else {
              refreshTokens()
          }
      }, 60 * 1000)
      return () => {
          window.removeEventListener('click', updateLastActivity)
          if (refreshInterval.current) {
              clearInterval(refreshInterval.current)
          }
      }
    }, [lastActivity])

  useEffect(() => {
    localStorage.setItem('storedIsSaved', JSON.stringify(isSaved))
  }, [isSaved])

  useEffect(() => {
    setInterval(() => {
      refreshTokens()
    }, 60 * 1000)
  }, [])

   useEffect(() => {

    const handleSystemThemeChange = (e) => {
       fetchUserSettings((systemMediaQuery.matches ? 'dark' : 'light'))
    }
    const systemMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    systemMediaQuery.addEventListener('change', handleSystemThemeChange)

    return () => systemMediaQuery.removeEventListener('change', handleSystemThemeChange)
  }, [])

  const openPopup = () => {
    setIsPopupOpen(true)
  }

  const closePopup = () => {
    setIsPopupOpen(false)
  }

/**
 * @param {string} [systemThemeSettingValue] An optional parameter
 */
   const fetchUserSettings = async (systemThemeSettingValue) => {
      console.log("fetchUserSettings called");
       const settingsResponse = await fetchSettings()
      if (settingsResponse && settingsResponse["user_settings"] === "unknown"){
        const settings = { maxIterationLoops: 5, theme: 'system' }
        setUserSettings(settings)
        updateSettings(settings)
        return
      }
      if (settingsResponse && settingsResponse["user_settings"]){
        const settings = settingsResponse["user_settings"]
        setUserSettings(settings)
        if (settings["theme"]){
          let userTheme = settings["theme"]
          if (settings["theme"] === "system") {
            userTheme = systemThemeSettingValue
          }

          if(userTheme)
            handleThemeChange(userTheme)
        }
      }
    }
  const handleLogin = (userInfo,user_name) => {
//      delete_the_storage()
    setUsername(user_name)
    setUserInfo(userInfo)
    setIsLoggedIn(true)
    fetchUserSettings()
  }

  const doLogout = () => {
      setIsLoggedIn(false)
      setUserInfo(null)
      logout(true)
  }

  const handleLogout = () => {
    setShowUserMenu(false)
    if (isSaved) {
      doLogout()
    } else {
      setShowSaveWarning(true)
    }
  }

  const goToPaymentWithUserName = (user_name) => {
    goToPaymentPage(user_name)
  }

  const goToPayment = () => {
    goToPaymentPage(username)
  }

  useEffect(() => {
    checkLogin().then(response => {
      setUsername(response["username"])
      setUserInfo(response)
      setIsLoggedIn(true)
      fetchUserSettings()
    })
    .catch(error => console.error('Error: ', error))
  }, [])

  function openContactPopup(){

  }

  const signUpSuccess = (user_name) => {
    setIsSignedUp(true)
    setUsername(user_name)
  }

//                <span className={`${styles.textColorCycleReverse} font-bold`}>patents</span>
//                <span className={`${styles.textColorCycle} font-normal`}>.chat</span>

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme)
  }

  return (
  <ThemeContext.Provider value={{theme, setTheme}}>
    <div className={`${theme === 'dark' ? 'bg-gray-700' : 'bg-primary-1-quarter'} flex-col flex max-h-screen md:h-screen`}>
      <div className={`${theme === 'dark' ? 'bg-gray-700' : ''} h-screen overflow-y-auto md:flex flex-col top-0 fixed items-center justify-center w-full m-auto`}>
        {!isLoggedIn && (
          <div className="flex items-center justify-center">
            <div className="m-2 md:w-[528px] max-w-full min-w-[200px] rounded flex flex-col items-center justify-center">
              <img src="Logo-1-no-bg.png" className="w-1/2"/>
            </div>
          </div>
        )}
        {isLoggedIn && false && (
          <div className="absolute bottom-2 sm:right-1 sm:top-0 sm:bottom-auto">
            <div className="relative z-10">
              <div className="w-full text-right mr-1">
                <button onClick={() => {if (showUserMenu) {setHideDragBars(false)} else {setHideDragBars(true)}; setShowUserMenu(!showUserMenu)}} className='right-1 bg-blue-300 hover:bg-blue-500 text-xs text-white font-bold py-1 px-2 rounded'>
                  <FaUser />
                </button>
              </div>
              {showUserMenu && (
                <div className="">
                  <button onClick={() => {setShowUsageWindow(true); setShowUserMenu(false)}} className='bg-green-300 hover:bg-green-500 cursor-pointer text-xs text-white font-bold py-1 px-2 rounded mr-1'>
                    Usage
                  </button>
                  <button onClick={() => {setShowAccountWindow(true); setShowUserMenu(false)}} className='bg-blue-300 hover:bg-blue-500 cursor-pointer text-xs text-white font-bold py-1 px-2 rounded mr-1'>
                    Account
                  </button>
                  <button onClick={handleLogout} className='bg-red-300 hover:bg-red-500 cursor-pointer text-xs text-white font-bold py-1 px-2 rounded'>
                    Logout
                  </button>
                </div>
              )}
             </div>
          </div>
        )}
        {isLoggedIn ? (
          <div>
            <ChatRoom user={userInfo} userSettings={userSettings} hideDragBars={hideDragBars} isSaved={isSaved} setIsSaved={setIsSaved} username={username} onThemeChange={handleThemeChange} theme={theme} setShowUsageWindow={setShowUsageWindow} handleLogout={handleLogout} />
          </div>
        ) : (
          <div className='flex max-h-[calc(100vh-200px)] md:flex-row md:flex-col md:items-center justify-center'>
            <div className='items-center md:flex justify-center'>
              <div className="flex flex-col w-full md:w-1/2">
                <div className="flex-1">
                  <LoginForm onLogin={handleLogin} goToPaymentSite={goToPaymentWithUserName} />
                </div>
                <div className="flex-1">
                  <About />
                </div>
              </div>
              {!isSignedUp && !isConfirmed && (
                <div className="flex flex-col w-full md:w-1/2 md:px-2">
                  <div className="flex-1">
                    <SignupForm onSignupSuccess={signUpSuccess} />
                  </div>
                </div>
              )}
              {isSignedUp && !isConfirmed && (
                <div className="w-full md:w-1/2 md:px-2">
                  <ConfirmSignUp onConfirm={() => setIsConfirmed(true)} closeConfirmSignUpWindow={() => setIsSignedUp(false)} />
                </div>
              )}
              {isConfirmed && (
                <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                  <div className="p-4 bg-white rounded-lg shadow-md">
                    <p className="mb-4">Success! Please complete payment to get started.</p>
                    <div className="flex items-center justify-center">
                      <button
                        onClick={goToPayment}
                        className="bg-blue-500 text-white p-2 rounded"
                      >
                        Pay with Stripe
                      </button>
                    </div>
                  </div>
                </div>
              )}
              </div>
          </div>
        )}
      </div>
      {showUsageWindow && (
        <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <UsageWindow closeUsageWindow={() => setShowUsageWindow(false)} username={username} />
        </div>
      )}
      {!isLoggedIn && (<div className="hidden md:block">
        <OpenAIIcon />
        <ForGenAI />
      </div>)}
      <ContactPopup isOpen={isPopupOpen} close={closePopup} />
      {showSaveWarning && (
        <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-4 bg-white rounded-lg shadow-md">
            <p className="mb-4">Current chat will be lost if unsaved. Make sure to save first.</p>
            <div className="flex space-x-4">
              <button
                onClick={doLogout}
                className="bg-red-500 text-white p-2 rounded"
              >
                Logout Without Saving
              </button>
              <button
                onClick={() => setShowSaveWarning(false)}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Go Back to Save
              </button>
            </div>
          </div>
        </div>
      )}
      {isPaymentPopupOpen && (
        <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-4 bg-white rounded-lg shadow-md">
            <p className="mb-4">{paymentPopupText}</p>
            <div className="flex space-x-4 items-center justify-center">
              <button
                onClick={() => setIsPaymentPopupOpen(false)}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  </ThemeContext.Provider>
  )
}

export default App
