import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import RedX from '../component/RedX'
import Spinner from '../component/Spinner'
import classNames from 'classnames'
import { getJobStatus, getMessage } from '../api'

function ResourceStatusLoader({ resourceType, resourcePubNo, jobId, resourceName, completeCallback, hideName, isMessage }) {
  const JOB_REFRESH_INTERVAL = 1500
  const JOB_DESC_REFRESH_INTERVAL = 1500
  const [status, setStatus] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isVisible, setIsVisible] = useState(true)

  const statusClassName = classNames('text-base', {
    'text-green-500 font-bold': status.startsWith('complete'),
    'text-blue-500': status === 'processing',
    'text-red-500': status === 'error',
  })

  const handleClose = () => {
    setIsVisible(false)
  }

  useEffect(() => {
    if (status && !status.toLowerCase().startsWith('processing')) {
      setIsLoading(false)

      // Hide the icon after 5 seconds if the status is complete or error
      if (status.toLowerCase().startsWith('complete') || status.toLowerCase().startsWith('error')) {
        const timeoutId = setTimeout(() => {
          handleClose()
        }, 5000)
        return () => clearTimeout(timeoutId)
      }
    }
  }, [status])

  useEffect(() => {
    const storedJobStatus = localStorage.getItem(`jobStatus_${jobId}`)
    if (storedJobStatus) {
      setStatus(storedJobStatus)
      setIsLoading(false)
      return
    }

    if (jobId && jobId.toLowerCase().startsWith('complete')) {
      setStatus('complete (already loaded)')
      setIsLoading(false)
      localStorage.setItem(`jobStatus_${jobId}`, 'complete')
      return
    }

    if (!status.toLowerCase().startsWith('complete') && !status.toLowerCase().startsWith('error') && !status.toLowerCase().startsWith('unknown')) {
      var intervalId = setInterval(async () => {
        try {
          if (isMessage) {
            getMessage(jobId, false).then((getMessageResponse) => {
              if (getMessageResponse && getMessageResponse['message']) {
                setStatus('complete')
                localStorage.setItem(`jobStatus_${jobId}`, 'complete')
                clearInterval(intervalId)
                completeCallback(getMessageResponse['message'])
              }
            })
            return
          }
          const res = await getJobStatus(jobId)
          if (res.status && res.status.toLowerCase().startsWith('processing-')) {
            const status_parts = res.status.toLowerCase().split("-")
            if (status_parts.length > 2) {
              setStatus("processing - " + status_parts[1] + " / " + status_parts[2] + " drawing sheets loaded.")
            }
          } else if (res.status && res.status === 'error') {
            setStatus(res.status.toLowerCase())
            clearInterval(intervalId)
            setIsLoading(false)
          } else if (res.status && res.status.startsWith('complete-')) {
            setStatus('complete')
            localStorage.setItem(`jobStatus_${jobId}`, 'complete')
            clearInterval(intervalId)
            setIsLoading(false)
            if (resourceName.includes("-info-")) {
              completeCallback(res.status.split("-")[1])
            }
          } else {
            setStatus(res.status.toLowerCase())
            if (res.status && res.status === 'complete') {
              clearInterval(intervalId)
              localStorage.setItem(`jobStatus_${jobId}`, 'complete')
              setIsLoading(false)
              if (resourceName.includes("-info-")) {
                completeCallback(res.status.split("-")[1])
              } else {
                completeCallback(res.status.split("-")[1], resourceType, resourcePubNo)
              }
            }
          }
        } catch (error) {
          console.error(error)
        }
      }, resourceName && resourceName.includes("-desc-") ? JOB_DESC_REFRESH_INTERVAL : JOB_REFRESH_INTERVAL)
    }
    return () => clearInterval(intervalId)
  }, [])

  if (!isVisible || jobId === "") {
    return null
  }

  return (
    <div className="flex items-center">
      {!hideName && resourceName + ":"}
      <span className={statusClassName}>
        {(isLoading || status.startsWith('processing')) && <Spinner />}
        {status.startsWith('complete') && (
          <img src='green-checkmark.png' alt='' className='inline-flex h-4 pb-1 pl-1 pr-1' />
        )}
        {status.startsWith('error') && (
          <img src='red-x.png' alt='' className='inline-flex h-4 pb-1 pl-1 pr-1' />
        )}
      </span>
    </div>
  )
}

ResourceStatusLoader.propTypes = {
  jobId: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  completeCallback: PropTypes.func.isRequired,
  hideName: PropTypes.bool.isRequired,
}

export default ResourceStatusLoader
