import React, { useState, useEffect, useContext, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faEye, faEyeSlash, faArrowDown, faArrowUp, faTimes, faQuestionCircle, faCommentDots, faBars, faList, faTimesCircle, faFileAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { fetchDocument, getSummary, fetchSpecInfo, getDrawingLinks, getCustomQuestionHistory, customQuestion, addDrawingComponent } from '../api'
import { getMessage, fetchTermMap, summarizeAndCharacterize, fetchAndLoadDocument, refreshDoc, getTermListAndDefinitions } from '../api'
import { getCustTermListAndDefinitions, deleteTermFromList, lookForCites, getCites, genDrawingSummaries, getDrawingSummaries, getJobStatus } from '../api'
import { addJobToLocalStorage, removeJobFromLocalStorage } from '../util/gen_util'
import { removeCountryAndKindCodes } from '../util/patent_util'
import ResourceSelector from '../chat_room/ResourceSelector'
import ContextSummary from '../chat_room/ContextSummary'
import DocParagraph from '../chat_room/DocParagraph'
import TermListAndDefinitions from '../op/TermListAndDefinitions'
import PDFTextViewer from '../chat_room/PDFTextViewer'
import CitesList from '../chat_room/doc_viewer/CitesList'
import ScrollableViewer from '../chat_room/doc_viewer/ScrollableViewer'
import DrawingSummary from '../chat_room/doc_viewer/DrawingSummary'
import RedX from '../component/RedX'
import Spinner from '../component/Spinner'
import ThemeContext from '../context/ThemeContext'

import DocumentHeader from '../chat_room/doc_viewer/DocumentHeader'
import LoadingIndicator from '../chat_room/doc_viewer/LoadingIndicator'
import SummarySection from '../chat_room/doc_viewer/SummarySection'
import SpecInfoSection from '../chat_room/doc_viewer/SpecInfoSection'
import DrawingsSection from '../chat_room/doc_viewer/DrawingsSection'
import CustomQuestionSection from '../chat_room/doc_viewer/CustomQuestionSection'
import TermListSection from '../chat_room/doc_viewer/TermListSection'
import CitesSection from '../chat_room/doc_viewer/CitesSection'
import ContentSection from '../chat_room/doc_viewer/ContentSection'

const XLSX = require('xlsx')

const startJobInterval = (jobId, docNumber, type, setExtractDrawingInfoStatus, setDrawingInfo, setShowExtractDrawingInfoButton) => {
  const interval = setInterval(() => {
    getJobStatus(jobId).then((drawingSummariesJobResponse) => {
      if (drawingSummariesJobResponse && drawingSummariesJobResponse.status && drawingSummariesJobResponse.status.toLowerCase().startsWith("processing")) {
        const splitJobStatus = drawingSummariesJobResponse.status.split("-")
        if (splitJobStatus.length > 2) {
          setExtractDrawingInfoStatus("Loading...sheet " + splitJobStatus[1] + " out of " + splitJobStatus[2] + " total sheets")
        }
      } else if (drawingSummariesJobResponse && drawingSummariesJobResponse.status && drawingSummariesJobResponse.status.toLowerCase().startsWith("complete")) {
        clearInterval(interval)
        removeJobFromLocalStorage("drawing-summary-extract-jobs", jobId)
        getDrawingSummaries(docNumber, type).then((getDrawingSummariesResponse) => {
          if (getDrawingSummariesResponse && getDrawingSummariesResponse.drawing_summaries) {
            setDrawingInfo((prevDrawingInfo) => ({
              ...prevDrawingInfo,
              drawing_summaries: getDrawingSummariesResponse.drawing_summaries,
            }))
            setExtractDrawingInfoStatus("")
          }
        })
      }
    }).catch((e) => {
      setExtractDrawingInfoStatus("Error extracting")
      setShowExtractDrawingInfoButton(true)
    })
  }, 2000)
}

const genSummaries = (docNumber, type, setExtractDrawingInfoStatus, setDrawingInfo, setShowExtractDrawingInfoButton) => {
  setShowExtractDrawingInfoButton(false)
  setExtractDrawingInfoStatus("Loading... extracting info from drawing sheets")

  genDrawingSummaries(docNumber, type).then((genDrawingSummariesResponse) => {
    if (genDrawingSummariesResponse && genDrawingSummariesResponse.job_id) {
      addJobToLocalStorage("drawing-summary-extract-jobs", genDrawingSummariesResponse.job_id)
      startJobInterval(genDrawingSummariesResponse.job_id, docNumber, type, setExtractDrawingInfoStatus, setDrawingInfo, setShowExtractDrawingInfoButton)
    }
  })
}

const checkLocalStorageForJobs = (docNumber, type, setExtractDrawingInfoStatus, setDrawingInfo, setShowExtractDrawingInfoButton) => {
  const jobId = localStorage.getItem("drawing-summary-extract-jobs")
  if (jobId) {
    startJobInterval(jobId, docNumber, type, setExtractDrawingInfoStatus, setDrawingInfo, setShowExtractDrawingInfoButton)
  }
}

const DrawingsViewer = ({ type, docNumber, docName, addTextToMessageInput, scrollToParagraphFn, paragraphNumberToScrollTo, scrollToClaimFn, claimNumberToScrollTo, showFiguresInDocWindow, handleScrollToDrawing, setIsSuppContextVisible, contextData }) => {
  const [documentContent, setDocumentContent] = useState('')
  const [summaryText, setSummaryText] = useState("")
  const [summaryFigure, setSummaryFigure] = useState(null)
  const [specInfo, setSpecInfo] = useState({})
  const [specInfoLoaded, setSpecInfoLoaded] = useState(false)
  const [showExtractDrawingInfoButton, setShowExtractDrawingInfoButton] = useState(true)
  const [extractDrawingInfoStatus, setExtractDrawingInfoStatus] = useState("")
  const [componentLoading, setComponentLoading] = useState(false)
  const [showDrawings, setShowDrawings] = useState(false)
  const [drawingUrls, setDrawingUrls] = useState([])
  const [showDrawingInfo, setShowDrawingInfo] = useState(false)
  const [drawingInfo, setDrawingInfo] = useState([])
  const [showViewSpecInfo, setShowViewSpecInfo] = useState(false)
  const [cites, setCites] = useState([])
  const [isCitesVisible, setIsCitesVisible] = useState(false)
  const [tables, setTables] = useState([])
  const [equations, setEquations] = useState([])
  const [showSummaryFigure, setShowSummaryFigure] = useState(false)
  const [showCites, setShowCites] = useState(false)
  const [showTables, setShowTables] = useState(false)
  const [showEquations, setShowEquations] = useState(false)
  const [summaryJobId, setSummaryJobId] = useState('')
  const [pdfData, setPdfData] = useState('')
  const [claims, setClaims] = useState([])
  const [imageContent, setImageContent] = useState('')
  const [isImageContent, setIsImageContent] = useState(false)
  const [showClaims, setShowClaims] = useState(false)
  const [paragraphs, setParagraphs] = useState([])
  const [loading, setLoading] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [showCustomQuestionMenu, setShowCustomQuestionMenu] = useState(false)
  const [customQuestions, setCustomQuestions] = useState([])
  const [showCustomQuestionHistory, setShowCustomQuestionHistory] = useState(false)
  const [customQuestionText, setCustomQuestionText] = useState("")
  const [customQuestionResponse, setCustomQuestionResponse] = useState("")
  const [customQuestionLoading, setCustomQuestionLoading] = useState(false)
  const [isNotLoaded, setIsNotLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isTermListLoading, setIsTermListLoading] = useState(false)
  const [termListData, setTermListData] = useState(null)
  const [showRefreshButton, setShowRefreshButton] = useState(false)
  const [showGetTermListAndDefinitions, setShowGetTermListAndDefinitions] = useState(true)
  const [hideTerms, setHideTerms] = useState(false)
  const [termListErrorMessage, setTermListErrorMessage] = useState('')
  const [showTermListError, setShowTermListError] = useState(false)
  const [isCustTermListLoading, setIsCustTermListLoading] = useState(false)
  const { theme, setTheme } = useContext(ThemeContext)
  const [uploadName, setUploadName] = useState('')

  // custom question history scroller
  const [showTopButton, setShowTopButton] = useState(false)
  const [showBottomButton, setShowBottomButton] = useState(false)
  const scrollDivRef = useRef(null)
  const checkScrollNeed = () => {
    if (scrollDivRef.current) {
      const { scrollHeight, clientHeight } = scrollDivRef.current
      if (scrollHeight >= 2 * clientHeight) {
        setShowTopButton(false)
        setShowBottomButton(true)
      } else {
        setShowTopButton(false)
        setShowBottomButton(false)
      }
    }
  }

  const handleScroll = () => {
    if (scrollDivRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollDivRef.current
      setShowBottomButton(!(scrollTop + clientHeight >= scrollHeight - 10))
      setShowTopButton(!(scrollTop === 0))
    }
  }

  const scrollToParagraph = (paragraphNumber) => {
    const element = document.getElementById(`paragraph-${paragraphNumber}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }


  useEffect(() => {
    const resourceIndex = contextData.resource_ids.indexOf(docNumber)
    if (resourceIndex !== -1) {
      const resourceName = contextData.resource_names[resourceIndex]
      setUploadName(resourceName)
    }
    setShowDrawings(true)
    const div = scrollDivRef.current
    if (scrollDivRef.current) {
      checkScrollNeed()
      div.addEventListener('custom-question-history-scroll', handleScroll)
      return () => div.removeEventListener('custom-question-history-scroll', handleScroll)
    }
  }, [])

  const scrollToBottom = () => {
    const { scrollHeight } = scrollDivRef.current
    scrollDivRef.current.scrollTo({
      top: scrollHeight,
      behavior: 'smooth'
    })
  }

  const scrollToTop = () => {
    scrollDivRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const loadDocument = async () => {
    setParagraphs([])
    setIsImageContent(false)
    setImageContent("")
    setShowCustomQuestionHistory(false)
    setLoading(true)
    setShowGetTermListAndDefinitions(false)
    setShowClaims(false)
    try {
      const response = await fetchDocument(type, removeCountryAndKindCodes(docNumber))
      if (response && response.content){
//        let the_paras = parseTextToParagraphs(response.content, false)
        if (response.content.length > 0 && response.content[0]["paragraph"] && response.content[0]["paragraph"].startsWith("[{\"page\":")){
          setPdfData(JSON.parse(response.content[0]["paragraph"]))
          return
        }

        const newParas = arrayToJsonObject(response.content)
        setParagraphs(newParas)
      } else if (response && response.base64image) {
        setIsImageContent(true)
        setImageContent(response.base64image)
      } else if (response && response.description && type !== "mpep") {
        if (response.description === "unknown") {
          setIsNotLoaded(true)
          return
        }
        if (!termListData) {
          setShowGetTermListAndDefinitions(true)
        }
        setDocumentContent(response.description + "\n" + response.claims)
//          let the_paras = parseTextToParagraphs(response.description, true)
//          let headersWithParagraphNumbers = extractHeadersFromParagraphs(the_paras)
//          the_paras = updateParagraphHeaders(the_paras, headersWithParagraphNumbers)
        setParagraphs(response.description)
        if (response.claims) {
          setClaims(response.claims)
          setShowClaims(true)
        }
        if (response.drawing_urls) {
          setDrawingUrls(response.drawing_urls)
        }
        if (response.drawing_summaries) {
          setDrawingInfo(response.drawing_summaries)
        }
       } else if (response && response.description) {
         setParagraphs(response.description)
       }
       if (paragraphNumberToScrollTo) {
          setTimeout(() => {scrollToParagraphFn(paragraphNumberToScrollTo)}, 400)
       }
       if (claimNumberToScrollTo) {
          setTimeout(() => {scrollToClaimFn(claimNumberToScrollTo)}, 400)
       }
       if (showFiguresInDocWindow) {
          setShowDrawings(true)
       }
    } catch (error) {
      console.error('Error fetching document:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (type && docNumber) {
      setSpecInfoLoaded(false)
      setSummaryText("")
      setCites([])
      setTables([])
      setEquations([])
      setSummaryFigure("")
      loadDocument()
    }
    if (type === "patent" || type === "publication" || type === "upload"){
      setShowGetTermListAndDefinitions(true)
    }
  }, [type, docNumber])

  useEffect(() => {
    localStorage.setItem("term_dict", termListData)
  }, [termListData])

  function extractHeadersFromParagraphs(paras) {
    const headersWithParagraphNumbers = []
    let currentHeader = ''
    const exceptions = ["summary", "abstract", "technical field"]

    paras.forEach((paragraph, index) => {
        const lastPeriodIndex = paragraph.text.lastIndexOf('.')
        let potentialHeader = paragraph.text.substring(lastPeriodIndex + 1).trim()

        // Check if potentialHeader is all-uppercase or matches any exception
        const isUpperCaseHeader = potentialHeader === potentialHeader.toUpperCase() && potentialHeader.match(/^[A-Z\s]+$/)
        const isExceptionHeader = exceptions.some(exception => potentialHeader.toLowerCase().includes(exception))

        if (isUpperCaseHeader || isExceptionHeader) {
            currentHeader = potentialHeader
            paragraph.text = paragraph.text.substring(0, lastPeriodIndex + 1).trim()
        }

        if (currentHeader) {
            headersWithParagraphNumbers.push({ header: currentHeader, paragraphNumber: paragraph.number + 1 })
        }
    })

    return headersWithParagraphNumbers
  }

  function updateParagraphHeaders(paras, headersWithParagraphNumbers) {
      let currentHeader = ''
    let nextHeaderIndex = 0

    paras.forEach((paragraph, index) => {
        // Check if we need to update the current header
        if (nextHeaderIndex < headersWithParagraphNumbers.length &&
            paragraph.number === headersWithParagraphNumbers[nextHeaderIndex].paragraphNumber) {
            currentHeader = headersWithParagraphNumbers[nextHeaderIndex].header
            nextHeaderIndex++
        }

        // Assign the current header to the paragraph
        paragraph.header = currentHeader
    })
    return paras
  }

  function pad(num, size) {
      num = num.toString()
      while (num.length < size) num = "0" + num
      return num
  }

  function arrayToJsonObject(arr) {
    const jsonObject = {}
    arr.forEach(item => {
      const key = "[" + pad(item.para_id, 4) + "]"
      jsonObject[key] = { content: item.paragraph }
    })
    return jsonObject
  }

  function parseTextToParagraphs(text, isPatent) {
    //    const splitRegex = /(?:\r\n|\r|\n)\((\d+)\)/;
    let splitRegex = /(?:\r\n|\r|\n)/
    if (isPatent) splitRegex = /(?:\r\n|\r|\n)[([](0*\d+)[)\]]/
    const parts = text.split(splitRegex)
    let paras = []
    let currentHeader = parts[0] && !parts[0].includes('.') ? parts[0].trim() : ''

    let paragraphNumber = 0
    for (let i = 1; i < parts.length; i += 2) {
        const paragraphText = parts[i + 1]?.trim()
        if (!paragraphText || paragraphText.trim() === "") {
            // If there's no paragraph text, skip to the next iteration
            continue
        }
        if (isPatent) {
            paragraphNumber = parseInt(parts[i], 10)
        } else {
            paragraphNumber = paragraphNumber + 1
        }
        const exceptions = ["summary", "abstract", "technical field"]
        let potentialHeader = ''

        // Check for potential header at the end of the paragraph
        const lastPeriodIndex = paragraphText.lastIndexOf('.')
        potentialHeader = paragraphText.substring(lastPeriodIndex + 1).trim()

        const isUpperCaseHeader = potentialHeader === potentialHeader.toUpperCase() && potentialHeader.match(/^[A-Z\s]+$/)
        const isExceptionHeader = exceptions.some(exception => potentialHeader.toLowerCase().includes(exception))

        let the_text = paragraphText
        if (isUpperCaseHeader || isExceptionHeader) {
            the_text = paragraphText.substring(0, lastPeriodIndex + 1).trim()
        }
        if (!isNaN(paragraphNumber) && paragraphText && the_text.trim() !== "") {
            paras.push({ number: paragraphNumber, header: currentHeader, text: the_text })
        } else {
            paragraphNumber = paragraphNumber - 1
        }
        if (isUpperCaseHeader || isExceptionHeader) {
            currentHeader = potentialHeader
        }
    }

    return paras
  }

  function capitalizeFirstChar(str) {
      if (str === "mpep") return "MPEP §"
      if (!str) return str
      return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const handleFetchSpecInfo = () => {
    if (specInfoLoaded) {
      return
    }
    getCites(docNumber, type).then((getCitesResponse) => {
      if (getCitesResponse['cites'] && getCitesResponse['cites'] !== undefined && getCitesResponse['cites'] !== null && getCitesResponse['cites'] !== "null") {
        setCites(getCitesResponse["cites"])
        setShowCites(true)
      }
    }).catch(() => {
    })
    fetchSpecInfo(docNumber, type).then((fetchSpecInfoResponse) => {
      setSpecInfo(fetchSpecInfoResponse)
      if (fetchSpecInfoResponse.hasOwnProperty("summary")){
        if (fetchSpecInfoResponse["summary"].hasOwnProperty("text")){
          setSummaryText(fetchSpecInfoResponse["summary"]["text"])
        } else {
            setSummaryText("There appears to be an error obtaining the summary.")
        }
        if (fetchSpecInfoResponse["summary"].hasOwnProperty("figure")){
          setSummaryFigure(fetchSpecInfoResponse["summary"]["figure"])
        }
        setSpecInfoLoaded(true)
      }
      if (fetchSpecInfoResponse.hasOwnProperty("cites") && Array.isArray(fetchSpecInfoResponse["cites"]) && fetchSpecInfoResponse["cites"].length > 0 && cites == []){
        setCites(fetchSpecInfoResponse["cites"])
        setShowCites(true)
        setSpecInfoLoaded(true)
      }
      if (fetchSpecInfoResponse.hasOwnProperty("tables") && Array.isArray(fetchSpecInfoResponse["tables"]) && fetchSpecInfoResponse["tables"].length > 0){
        setTables(fetchSpecInfoResponse["tables"])
        setShowTables(true)
        setSpecInfoLoaded(true)
      }
      if (fetchSpecInfoResponse.hasOwnProperty("equations") && Array.isArray(fetchSpecInfoResponse["equations"]) && fetchSpecInfoResponse["equations"].length > 0){
        setEquations(fetchSpecInfoResponse["equations"])
        setShowEquations(true)
        setSpecInfoLoaded(true)
      }
      setShowViewSpecInfo(true)
    }).catch((e) => {
      console.error("An error occurred fetching spec info:", e)
    })

  }

  const handleSummarize = () => {
    getSummary(docNumber, type).then((getSummaryResponse) => {
      if (getSummaryResponse && (typeof getSummaryResponse === 'string' || getSummaryResponse instanceof String)) {
        setSummaryText(getSummaryResponse)
      } else if (getSummaryResponse.hasOwnProperty("summary") && getSummaryResponse["summary"].hasOwnProperty("text")){
        setSummaryText(getSummaryResponse["summary"]["text"])
      } else {
        setSummaryText("There appears to be an error obtaining the summary.")
      }
    })
  }

  const handleDocumentQuestionHistory = () => {
    getCustomQuestionHistory(docNumber, type).then((getCustomQuestionHistoryResponse) => {
      setCustomQuestions(getCustomQuestionHistoryResponse)
      setShowCustomQuestionHistory(true)
    })
  }

  const handleGenSummary = async() => {
    setSummaryText("Submitting job")
    setCustomQuestionLoading(true)
    summarizeAndCharacterize(docNumber, docName, type).then((getSummaryResponse) => {
      setCustomQuestionLoading(false)
      if (getSummaryResponse && getSummaryResponse["job_id"]){
        setSummaryJobId(getSummaryResponse["job_id"])
        setSummaryText("Generating")
      }
    })
  }

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const handleCustomQuestionClick = () => {
    setShowCustomQuestionMenu(true)
  }

  const handleCustomQuestionTextChange = (event) => {
    setCustomQuestionText(event.target.value)
  }

  const handleCustomQuestionSubmit = async() => {
      setCustomQuestionLoading(true)
      const newQuestion = {
        input_text: customQuestionText,
        response: '...', // Indicate that the response is being generated
        timestamp: new Date().toISOString(),
      }

      setCustomQuestions([...customQuestions, newQuestion])
      scrollToBottom()

      customQuestion(customQuestionText, type, docNumber).then((customQuestionResponse) => {
          const message_id = customQuestionResponse["message_id"]
          const interval = setInterval(() => {
              getMessage(message_id, false).then((getMessageResponse) => {
                  if (getMessageResponse['message'] && getMessageResponse['message'] !== undefined && getMessageResponse['message'] !== null && getMessageResponse['message'] !== "null") {
                      getCustomQuestionHistory(docNumber, type).then((getCustomQuestionHistoryResponse) => {
                        setCustomQuestions(getCustomQuestionHistoryResponse)
                        setShowCustomQuestionHistory(true)
                      })
                      setCustomQuestionLoading(false)
                      setCustomQuestionText("")
                      scrollToBottom()
                      clearInterval(interval)
                  }
              }).catch(() => {
                  setCustomQuestionLoading(false)
                  setCustomQuestionResponse("Error generating response")
                  clearInterval(interval)
              })
          }, 7500)
        }).catch(() => {
//                setLoading(false)
        })
  }

  const handleAddCommentClick = () =>{

  }

  const handleRefreshDocument = () => {
    refreshDoc(docNumber, type)
  }

  const handleGenTermListAndDefinitionsResponse = (getTermListAndDefinitionsResponse) => {
    if (getTermListAndDefinitionsResponse && getTermListAndDefinitionsResponse["message_id"]){
      const message_id = getTermListAndDefinitionsResponse["message_id"]
      const fetchAndLoadTermListInterval = setInterval(() => {
        fetchTermMap(message_id).then((getMessageResponse) => {
            if (getMessageResponse['error'] && getMessageResponse['error'] !== undefined && getMessageResponse['error'] !== null && getMessageResponse['message'] !== "null" && getMessageResponse['error'] !== "") {
                setIsTermListLoading(false)
                setIsCustTermListLoading(false)
                setTermListErrorMessage(getMessageResponse['error'])
                setShowTermListError(true)
            }
            if (getMessageResponse['message'] && getMessageResponse['message'] !== undefined && getMessageResponse['message'] !== null && getMessageResponse['message'] !== "null" && getMessageResponse['message'] !== "") {
                setIsTermListLoading(false)
                setIsCustTermListLoading(false)
                setTermListData(getMessageResponse['message'])
                setShowGetTermListAndDefinitions(false)
                clearInterval(fetchAndLoadTermListInterval)
            }
        }).catch(() => {
            setIsTermListLoading(false)
            setIsCustTermListLoading(false)
            setTermListData("Error fetching term list")
            clearInterval(fetchAndLoadTermListInterval)
        })
      }, 7500)
    } else if (getTermListAndDefinitionsResponse && getTermListAndDefinitionsResponse["term_dict"]){
      setIsTermListLoading(false)
      setIsCustTermListLoading(false)
      setShowGetTermListAndDefinitions(false)
      setTermListData(getTermListAndDefinitionsResponse['term_dict'])
    }
  }

  const handleGetTermListAndDefinitions = () => {
    setIsTermListLoading(true)
    setTermListErrorMessage("")
    setShowTermListError(false)
    setIsCustTermListLoading(false)
    getTermListAndDefinitions(docNumber, type, false).then(handleGenTermListAndDefinitionsResponse).catch(() => {
      setIsTermListLoading(false)
    })
  }

  const handleRefreshTermListAndDefinitions = () => {
    setTermListData(null)
    setTermListErrorMessage("")
    setShowTermListError(false)
    setIsCustTermListLoading(false)
    setShowGetTermListAndDefinitions(false)
    setIsTermListLoading(true)
    getTermListAndDefinitions(docNumber, type, true).then(handleGenTermListAndDefinitionsResponse).catch(() => {
      setIsTermListLoading(false)
    })
  }

  const addCustTerms = (terms) => {
    setTermListErrorMessage("")
    setShowTermListError(false)
    setIsCustTermListLoading(true)
    getCustTermListAndDefinitions(docNumber, type, terms).then(handleGenTermListAndDefinitionsResponse).catch(() => {
      setIsTermListLoading(false)
      setIsCustTermListLoading(false)
    })
  }

  const handleDeleteTerm = (term_index) => {
    deleteTermFromList(docNumber, type, term_index).then(handleGenTermListAndDefinitionsResponse).catch(() => {
      setIsTermListLoading(false)
      setIsCustTermListLoading(false)
    })
  }

  const [citesStatus, setCitesStatus] = useState("")
  const [showLookForMoreCitesButton, setShowLookForMoreCitesButton] = useState(true)

  const handleLookForCites = () => {
    setShowLookForMoreCitesButton(false)
    setCitesStatus("Searching for more cites")
    lookForCites(docNumber, type, cites).then((lookForCitesResponse) => {
      const job_id = lookForCitesResponse["job_id"]
      const fetchAndLoadCitesInterval = setInterval(() => {
        getCites(docNumber, type).then((getCitesResponse) => {
          if (getCitesResponse['cites'] && getCitesResponse['cites'] !== undefined && getCitesResponse['cites'] !== null && getCitesResponse['cites'] !== "null") {
            setCites(getCitesResponse["cites"])
            setCitesStatus("")
            clearInterval(fetchAndLoadCitesInterval)
          }
        }).catch(() => {
          setCitesStatus("Error searching for citations.")
          setShowLookForMoreCitesButton(true)
          clearInterval(fetchAndLoadCitesInterval)
        })
      }, 3500)
    }).catch(() => {
      setShowLookForMoreCitesButton(false)
      setCitesStatus("There appears to have been an error.")
    })
  }

  function writeToExcel(data) {
    const workbook = XLSX.utils.book_new()
    const worksheet = {}
    worksheet["A1"] = { t: "s", v: "Numeral" }
    worksheet["B1"] = { t: "s", v: "Name" }
    worksheet["C1"] = { t: "s", v: "Definition" }
    worksheet["D1"] = { t: "s", v: "Paragraphs" }
    let rowIndex = 2
    data.forEach(item => {
      const rowPrefix = "A" + rowIndex + ":D" + rowIndex
      worksheet["A" + rowIndex] = { t: "s", v: item.numeral }
      worksheet["B" + rowIndex] = { t: "s", v: item.name }
      worksheet["C" + rowIndex] = { t: "s", v: item.definition }
      worksheet["D" + rowIndex] = { t: "s", v: item.paragraphs.join(", ") }
      rowIndex++
    })
    const range = { s: { c: 0, r: 0 }, e: { c: 3, r: rowIndex - 1 } }
    worksheet['!ref'] = XLSX.utils.encode_range(range)
    XLSX.utils.book_append_sheet(workbook, worksheet, type + "-" + docNumber + " Terms")
    const fileName = type + "-" + docNumber + "terms.xlsx"
    XLSX.writeFile(workbook, fileName)
  }

  const handleDownloadTerms = () => {
    writeToExcel(termListData)
  }

  const genSummaries = () => {
    setShowExtractDrawingInfoButton(false)
    setExtractDrawingInfoStatus("Loading... extracting info from drawing sheets")
    genDrawingSummaries(docNumber, type).then((genDrawingSummariesResponse) => {
      if (genDrawingSummariesResponse && genDrawingSummariesResponse.job_id) {
        const genDrawingSummariesInterval = setInterval(() => {
          getJobStatus(genDrawingSummariesResponse.job_id).then((drawingSummariesJobResponse) => {
            if (drawingSummariesJobResponse && drawingSummariesJobResponse.status && drawingSummariesJobResponse.status.toLowerCase().startsWith("processing")) {
              const splitJobStatus = drawingSummariesJobResponse.status.split("-")
              if (splitJobStatus.length > 2) {
                setExtractDrawingInfoStatus("Loading...sheet " + splitJobStatus[1] + " out of " + splitJobStatus[2] + " total sheets")
              }
            } else if (drawingSummariesJobResponse && drawingSummariesJobResponse.status && drawingSummariesJobResponse.status.toLowerCase().startsWith("complete")) {
              clearInterval(genDrawingSummariesInterval)
              getDrawingSummaries(docNumber, type).then((getDrawingSummariesResponse) => {
                if (getDrawingSummariesResponse && getDrawingSummariesResponse.drawing_summaries) {
                  setDrawingInfo((prevDrawingInfo) => ({
                    ...prevDrawingInfo,
                    drawing_summaries: getDrawingSummariesResponse.drawing_summaries,
                  }))
                  setExtractDrawingInfoStatus("")
                }
              })
            }
          }).catch((e) => {
            setExtractDrawingInfoStatus("Error extracting")
            setShowExtractDrawingInfoButton(true)
            clearInterval(genDrawingSummariesInterval)
          })
        }, 2000)
      }
    })
  }

  const addComponent = async (numeral, number, sheet) => {
    setComponentLoading(true)
    try {
      const addDrawingComponentResponse = await addDrawingComponent(docNumber, type, numeral, number, sheet)
      if (addDrawingComponentResponse && addDrawingComponentResponse.job_id) {
        const addDrawingComponentInterval = setInterval(() => {
          getJobStatus(addDrawingComponentResponse.job_id).then((addDrawingComponentJobResponse) => {
            if (addDrawingComponentJobResponse && addDrawingComponentJobResponse.status && addDrawingComponentJobResponse.status.toLowerCase().startsWith("complete")) {
              clearInterval(addDrawingComponentInterval)
              setComponentLoading(false)
              getDrawingSummaries(docNumber, type).then((getDrawingSummariesResponse) => {
                if (getDrawingSummariesResponse && getDrawingSummariesResponse.drawing_summaries) {
                  setDrawingInfo(getDrawingSummariesResponse.drawing_summaries)
                  setExtractDrawingInfoStatus("")
                }
              })
            }
          }).catch((e) => {
            clearInterval(addDrawingComponentInterval)
            setExtractDrawingInfoStatus("Error extracting")
            setComponentLoading(false)
          })
        }, 2000)
      }
    } catch (error) {
      // Handle error if needed
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <DocumentHeader
        theme={theme}
        type={type}
        docName={uploadName != "" ? uploadName : (docName ? docName : docNumber)}
        handleRefreshDocument={handleRefreshDocument}
        showRefreshButton={showRefreshButton}
        isDrawings={true}
      />
      {loading ? (
        <LoadingIndicator theme={theme} message="Loading document..." />
      ) : (
        <div className={`flex flex-col ${theme === 'dark' ? 'text-white bg-gray-900' : 'bg-white'}`}>
          <div className='overflow-x-hidden h-screen max-w-[calc(100vw-72px)] flex-grow overflow-y-auto max-h-[calc(100vh-45px)] px-4 py-2 flex flex-wrap'>
            <DrawingsSection
              theme={theme}
              showDrawings={true}
              setShowDrawings={setShowDrawings}
              drawingUrls={drawingUrls}
              showDrawingInfo={showDrawingInfo}
              setShowDrawingInfo={setShowDrawingInfo}
              drawingInfo={drawingInfo}
              setDrawingInfo={setDrawingInfo}
              handleScrollToDrawing={handleScrollToDrawing}
              genSummaries={genSummaries}
              showExtractDrawingInfoButton={showExtractDrawingInfoButton}
              extractDrawingInfoStatus={extractDrawingInfoStatus}
              addComponent={addComponent}
              componentLoading={componentLoading}
              docNumber={docNumber}
              type={type}
            />
            </div>
        </div>
      )}
    </>
  )
}

export default DrawingsViewer
