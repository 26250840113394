import React from 'react'
import CitesList from '../../chat_room/doc_viewer/CitesList'

const CitesSection = ({ theme, showCites, isCitesVisible, setIsCitesVisible, cites, handleLookForCites, citesStatus, showLookForMoreCitesButton, scrollToParagraph }) => (
  <div>
    {showCites && (
      <div>
        {isCitesVisible && (
          <div className="bg-white border border-gray-300 rounded-lg shadow-sm">
            <div>
              {cites.length > 0 ? (
                <CitesList cites={cites} handleParagraphClick={scrollToParagraph} />
              ) : (
                <p className="text-gray-500">No cites found.</p>
              )}
            </div>
            {showLookForMoreCitesButton && (
              <div className="m-2 rounded bg-primary-1-quarter border-primary cursor-pointer p-2 text-primary text-center hover:bg-primary hover:text-white hover:border-primary-1-quarter" onClick={handleLookForCites}>
                Look for more cites
              </div>
            )}
            {citesStatus && citesStatus !== "" && (
              <div className="m-2 rounded cursor-pointer p-2 text-primary text-center">{citesStatus}</div>
            )}
          </div>
        )}
      </div>
    )}
  </div>
)

export default CitesSection
